// staging
// const IS_TESTING = true;
//live
const IS_TESTING = false;

const API_URL_V1 =
  process.env.NODE_ENV !== 'development' && !IS_TESTING
    ? 'https://cbu-microservices-prod-unify-cert-api-prod.azurewebsites.net/api/v1'
    : 'https://cbu-microservices-staging-unify-cert-api-staging.azurewebsites.net/api/v1'

const tokenKey = 'unify-admin-token'
const refreshTokenKey = 'unify-admin-refreshToken'
const lastUpdateKey = 'unify-admin-updatedAt'

const durationInHrs = 2

const FETCH_STATUS = {
  IDLE: 'IDLE',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
}

export {
  API_URL_V1,
  tokenKey,
  refreshTokenKey,
  lastUpdateKey,
  durationInHrs,
  FETCH_STATUS,
}
