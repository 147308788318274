import * as yup from "yup";

const createAdminSchema = yup
  .object()
  .shape({
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name"),
    email: yup.string().required("Please enter your email"),
    password: yup
      .string()
      .required("Password is mandatory")
      .min(8, "Password must be at 3 char long"),
    roleName: yup.string().required("Select a role"),
    notifyAdmin: yup.boolean().default(true),
  })
  .required();

const createAdminInit = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  roleName: "",
  notifyAdmin: true,
};

const updateAdminSchema = yup
  .object()
  .shape({
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name"),
    email: yup.string().required("Please enter your email"),
    role: yup.string().required("Select a role"),
  })
  .required();

const reqPwdResetSchema = yup
  .object()
  .shape({
    email: yup.string().required("Please enter your email"),
  })
  .required();

const reqPwdResetInit = {
  email: "",
};

const conPwdResetSchema = yup
  .object()
  .shape({
    newPassword: yup
      .string()
      .required("Password is mandatory")
      .min(8, "Password must be at least 8 char long"),
    conNewPassword: yup
      .string()
      .required("Password is mandatory")
      .oneOf([yup.ref("newPassword")], "Passwords do not match"),
  })
  .required();

const conPwdResetInit = {
  newPassword: "",
  conNewPassword: "",
};

const setPricingSchema = yup
  .object()
  .shape({
    coursePrice: yup
      .array()
      // .min(1, "Select at least one package")
      .of(
        yup.object().shape({
          price: yup.number().required(""),
          name: yup.string().required(""),
          subscriptionType: yup.string().required(""),
          offers: yup.array().min(1),
          selected: yup.boolean(),
        })
      ),
  })
  .required();

const setPricingInit = {
  coursePrice: [
    {
      id: "",
      name: "",
      price: "",
      offers: [],
      subscriptionType: "",
    },
  ],
};

const editPricingSchema = yup
  .object()
  .shape({
    id: yup.string(""),
    price: yup.string(""),
    subscriptionType: yup.string(""),
  })
  .required();

const generateDiscountSchema = yup.object().shape({
  courseIds: yup.array().min(1, "Select at least one course"),
  code: yup.string().required("enter discount code"),
  discountType: yup.string().required("enter discount type"),
  value: yup.number().required("input a value"),
  priceCap: yup.number().required("enter price cap"),
  expiryDate: yup.string().required("enter expiry date"),
  discountValidity: yup.string().required("enter discount validity"),
  limitCount: yup.number().required("enter number of time to be used"),
  isCourseSpecific: yup.boolean().default(false),
});

const generateDiscountInit = {
  code: "",
  discountType: "",
  value: 0,
  expiryDate: "",
  priceCap: 0,
  discountValidity: "",
  limitCount: 0,
  isCourseSpecific: false,
  courseIds: [],
};

export {
  editPricingSchema,
  createAdminSchema,
  createAdminInit,
  updateAdminSchema,
  reqPwdResetInit,
  reqPwdResetSchema,
  conPwdResetSchema,
  conPwdResetInit,
  setPricingSchema,
  setPricingInit,
  generateDiscountSchema,
  generateDiscountInit,
};
