import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import differenceInHours from "date-fns/differenceInHours";
import useForm from "./useForm";
import defaultLogo from "../../assets/images/unifyLogo.png";
import {EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import validate from "./validateInfo";
import { useRequest } from "../../hooks/useRequest";
import { login, setTokens } from "../../redux/slices/authSlice";
import { setTokenService } from "../../utils/tokenService";
import { FormError, ScrollToTop, Spinner } from "../../components";
import {
  lastUpdateKey,
  refreshTokenKey,
  tokenKey,
  durationInHrs,
} from "../../utils";


function Login() {
  const { handleChange, values, handleSubmit, errors } = useForm(validate);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setPending, setRejected, err, status } = useRequest();

  useEffect(() => {
    const token = localStorage.getItem(tokenKey);
    const refreshToken = localStorage.getItem(refreshTokenKey);

    const lastUpdated = +localStorage.getItem(lastUpdateKey);
    const now = new Date();

    if (token && refreshToken && lastUpdated) {
      const hasExpired =
        differenceInHours(now, new Date(lastUpdated)) > 0.85 * durationInHrs;

      if (hasExpired) {
        setLoading(false);
      } else {
        navigate("/dashboard");
      }
    } else {
      setLoading(false);
    }
  }, [navigate]);

  const onSubmit = async (e) => {
    const syncErrs = handleSubmit(e);

    if (isEmpty(syncErrs)) {
      setPending();
      const body = {
        userName: values.email,
        password: values.password,
      };

      dispatch(login(body))
        .unwrap()
        .then((resp) => {
          const { token, refreshToken, userId } = resp.data;

          const path = location?.search
            ? location?.search.replace("?redirectTo=", "")
            : "/dashboard";

          setTokenService(refreshToken, token);
          dispatch(setTokens({ refreshToken, token, userId }));


          navigate(path, {
            replace: true,
          });
        })
        .catch((err) => {
          let errMsgs = "Unable to login";

          if (err?.errors?.length) {
            errMsgs = err?.errors[0]?.errorMessages.join(" ");
          }

          setRejected(errMsgs);
        });
    }
  };


  //handle toggle
  const toggle = () => {
    setOpen(!open);
  };

  if (loading) {
    return <></>;
  }


  return (
    <ScrollToTop>
      <div className="w-full h-screen overflow-hidden bg-black">
      <img src={defaultLogo} alt="Logo" className="ml-3 md:ml-[50px] mt-4" />
        <div className="Login">
          <div className="flex flex-wrap items-center justify-center min-h-screen">
            <div className="flex-wrap items-center justify-center mt-[-40px] w-full max-w-sm">
              <form onSubmit={onSubmit}
                className="px-8 pt-8 pb-8 mx-2 mb-4 bg-white rounded shadow-md"
              >
                <FormError err={err} />
                <div>
                  <p className="mb-1 text-lg font-bold">Welcome back, Admin</p>
                  <p className="mb-4 text-xs text-gray-400 font-medium">Enter in Your Login details to proceed</p>
                </div>

                <div className="mb-5 form-inputs">
                  <label
                    htmlFor=""
                    className="block mb-2 text-sm text-gray-400 font-medium"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    placeholder="e.g admin@mail.com"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                <p className="text-xs text-pink-500">{errors.email}</p>
              )}
                </div>

                <div className="mb-6 form-inputs">
                  <label
                    htmlFor=""
                    className="block mb-2 text-sm text-gray-700 font-medium"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <div>
                      <input
                        id="password"
                        name="password"
                        type={open === false ? "password" : "text"}
                        className="w-full px-3 py-2 leading-tight text-gray-700 border  rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                      />
                     {errors.password && (
                <p className="text-xs text-rose-500">{errors.password}</p>
              )} 
                    </div>
                    <div className="absolute top-1 right-5">
                      {open === false ? (
                        <EyeOutlined style={{color:"#7d7d7d"}} onClick={toggle} />
                      ) : (
                        <EyeInvisibleOutlined style={{color:"#7d7d7d"}} onClick={toggle} />
                      )}
                    </div>
                  </div>
                </div>
               
                <div className="mt-5">
                  <button
                    type="submit"
                    className="relative flex items-center justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md group hover:bg-[#88062f] bg-[#b61046] focus:outline-none focus:ring-2 focus:ring-offset-2 "
                  >
                    Log in
                  <Spinner
                  className="!w-3  !h-3 mx-1 text-white fill-pink-400"
                  status={status}
                />
                  </button>
                </div> 
                  <div className="text-sm  ">
                    <Link to="/forgot-password">
                      <p className="font-medium  text-[#c74a73]">
                        Forgot your password?
                      </p>
                    </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ScrollToTop>
  );
}

export { Login };
