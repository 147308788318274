import axios from 'axios'
import { appCache } from './cache'
import { setTokenService } from './tokenService'
import { API_URL_V1 } from './constants'

// Create an axios instance
const axiosApiInstance = axios.create()

// AxiosInstance Object to handle setup with store
const AxiosInstance = {
  setup: (store) => {
    // Function to refresh the access token
    const refreshAccessToken = async () => {
      let resp = {
        refreshToken: '',
        token: '',
        id: '',
      }

      const currentToken = await store?.getState()?.auth?.token
      const currentRefreshToken = await store?.getState()?.auth?.refreshToken

      try {
        const response = await axiosApiInstance.post(
          `${API_URL_V1}/Auth/Generate-Refresh-Token`,
          {
            currentJWT: currentToken,
            refreshToken: currentRefreshToken,
          }
        )

        resp = { ...resp, ...response?.data }
      } catch (error) {
        console.error(error)
      }

      return resp
    }

    // Action to set tokens in Redux store
    const setTokens = (refreshToken, token, id) => {
      return {
        type: 'auth/setTokens',
        payload: {
          refreshToken,
          token,
          id,
        },
      }
    }

    // Intercept requests to add the Authorization header
    axiosApiInstance.interceptors.request.use(
      async (config) => {
        const token = await store?.getState()?.auth?.token
        config.headers = {
          Authorization: `Bearer ${token}`,
        }

        return config
      },
      (error) => Promise.reject(error)
    )

    // Intercept responses to handle token refresh and cache GET requests
    axiosApiInstance.interceptors.response.use(
      (response) => {
        if (response.config.method.toLowerCase() === 'get') {
          appCache.addItem(response.config.url, response.data)
        }

        return response
      },
      async (error) => {
        const originalRequest = error.config
        if (
          (error.response.status === 403 || error.response.status === 401) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true
          const { token, refreshToken, id } = await refreshAccessToken()

          setTokenService(refreshToken, token)
          store?.dispatch(setTokens(token, refreshToken, id))

          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          return axiosApiInstance(originalRequest)
        }
        return Promise.reject(error)
      }
    )
  },
}

// Helper function to handle GET requests with caching
const axiosApiInstanceGet = async (url, force) => {
  if (force || !appCache.getItem(url)) {
    return await axiosApiInstance.get(url)
  }

  return { data: appCache.getItem(url) }
}

export { AxiosInstance, axiosApiInstance, axiosApiInstanceGet }
