import classNames from "classnames";

const StatusPill =({value})=>{
  const level = value ? value.toLowerCase() : "unknown";

    return(
        <span
      className={classNames(
        "px-3 py-1 capitalize leading-wide font-bold text-xs  shadow-sm",
        level.startsWith("advanced") ? "bg-[#d9fcad] rounded-full text-black" : null,
        level.startsWith("beginner") ? "bg-[#fce1ad] rounded-full text-black" : null,
        level.startsWith("intermediate") ? "bg-[#fafcad] rounded-full text-black" : null,
        level.startsWith("0") ? "bg-red-400 rounded-full text-black" : null,
        level.startsWith("ongoing") ? "bg-[#cac8c7] text-black text-[8px] " : null,
        level.startsWith("completed") ? "bg-[#d9fcad] text-black text-[8px]" : null
      )}
    >
      {level}
    </span>
    )
}

export {StatusPill};