import { useState, useEffect } from "react";
import {
  NavBar,
  AppFooter,
  Loading,
  Success,
  Error,
  Spinner,
  PrivateRoute,
} from "../../components";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { formatDateTime, axiosApiInstance, API_URL_V1 } from "../../utils";
import { useRequest } from "../../hooks";
import {
  getSingleInstructor,
  getInstructorList,
  selectInstructor,
} from "../../redux/slices";
import { EyeOutlined, LeftOutlined } from "@ant-design/icons";
import defaultImage from "../../assets/images/defaultImage.png";

const ApproveInstructorPage = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const toggleImage = () => {
    setShowImage((s) => !s);
  };

  const toggle1 = () => {
    setShowModal1((s) => !s);
  };

  const toggle2 = () => {
    setShowModal2((s) => !s);
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, setPending, setRejected } = useRequest();

  const { singleInstructor, singleInstructorErr, singleInstructorStatus } =
    useSelector(selectInstructor);

  useEffect(() => {
    dispatch(getSingleInstructor(id));
  }, [dispatch, id]);

  const singleInstructorRetry = () => {
    if (singleInstructorErr) {
      dispatch(getSingleInstructor(id));
    }
  };

  const ApproveInstructor = async () => {
    setPending();

    try {
      await axiosApiInstance.patch(
        `${API_URL_V1}/Instructors/approve-instructor?instructorId=${id}`
      );
      dispatch(getInstructorList(true));
      navigate("/pendingInstructors");
    } catch (err) {
      setRejected(err);
    }
  };

  const DeclineInstructor = async () => {
    setPending();

    try {
      await axiosApiInstance.patch(
        `${API_URL_V1}/Instructors/disapprove-instructor?instructorId=${id}`
      );
      dispatch(getInstructorList(true));
      navigate("/pendingInstructors");
    } catch (err) {
      setRejected(err);
    }
  };

  return (
    <PrivateRoute redirectTo="/pendingInstructors">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <div className="bg-[#eaeaea] h-[90px] w-full relative top-12 overflow-hidden "></div>
        <div className="w-full h-full relative left-10 top-[90px] ">
          <div className="grid grid-rows-2 grid-flow-col gap-4">
            <div className="row-start-1 md:ml-8">
              <p>
                <Link
                  className="text-[#b61046] hover:text-black"
                  to="/pendingInstructors"
                >
                  <LeftOutlined style={{ fontSize: "12px" }} />
                  <span className="m-[12px]">Back</span>
                </Link>
              </p>
              <h2 className="font-semibold text-lg">
                {singleInstructor?.firstName + " " + singleInstructor?.lastName}
              </h2>
              <p className="text-sm text-gray-400">
                Applied{" "}
                {formatDateTime(
                  new Date(singleInstructor?.dateCreated),
                  "LLLL do, yyyy. hh:mm a"
                )}
              </p>
            </div>
            <div className="flex mt-[-20px] md:mt-[-120px]">
              <button
                type="button"
                data-modal-toggle="defaultModal"
                onClick={toggle1}
                className="md:ml-[400px] lg:ml-[775px] text-white mt-[20px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:border hover:border-[#B61046] hover:bg-white hover:text-[#5f0c27]"
              >
                Approve Instructor
              </button>
              <button
                type="button"
                data-modal-toggle="defaultModal1"
                onClick={toggle2}
                className="ml-6 md:ml-3 text-[#5f0c27] border border-[#B61046] mt-[20px] bg-white w-[150px] h-[40px] rounded-md hover:bg-[#B61046] hover:text-white"
              >
                Decline
              </button>
            </div>
          </div>

          {/* Main modal  */}
          {showModal1 ? (
            <div
              id="defaultModal"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] lg:max-w-[30%] m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* Modal header  */}
                  <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <p className="mb-3.5 text-lg font-semibold text-center">
                      Approve Instructor?
                    </p>
                    <button
                      onClick={toggle1}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body  */}
                  <div className="p-6 space-y-6">
                    <p className="mb-3.5 text-sm ">
                      By approving this instructor, this means you have
                      completely vetted and deem canditate viable to create
                      courses on the Certifications by Unify platform. Approve?
                    </p>
                  </div>
                  {/* Modal footer */}
                  <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button
                      onClick={toggle1}
                      data-modal-toggle="defaultModal"
                      type="button"
                      className="text-white md:mt-[-20px] ml-14 md:ml-[270px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
                    >
                      No, Cancel
                    </button>
                    <button
                      onClick={() => ApproveInstructor(id)}
                      data-modal-toggle="defaultModal"
                      type="button"
                      className="ml-1 md:ml-3 text-[#5f0c27] border border-[#B61046] md:mt-[-20px] mt-[2px] bg-white w-[150px] h-[40px] rounded-md hover:bg-[#B61046] hover:text-white"
                    >
                      Yes, Approve
                      <Spinner
                        className="!w-3  !h-3 ml-[120px] mt-[-15px] text-white fill-pink-400"
                        status={status}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Decline modal  */}
          {showModal2 ? (
            <div
              id="defaultModal1"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] lg:max-w-[30%] m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* <Modal header 2 */}
                  <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <p className="mb-3.5 text-lg font-semibold text-center">
                      Decline Instructor ?
                    </p>
                    <button
                      onClick={toggle2}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal1"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body 2 */}
                  <div className="p-6 space-y-6">
                    <p className="mb-3.5 text-sm">
                      Are you sure you want to decline this instructor request?
                      Declining means this canditate won't be able to create
                      courses on the Certifications by Unify platform.
                    </p>
                  </div>
                  {/* Modal footer */}
                  <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button
                      onClick={toggle2}
                      data-modal-toggle="defaultModal1"
                      type="button"
                      className="text-white md:mt-[-20px] ml-14 md:ml-[270px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
                    >
                      No, Cancel
                    </button>
                    <button
                      onClick={() => DeclineInstructor(id)}
                      data-modal-toggle="defaultModal1"
                      type="button"
                      className="ml-1 md:ml-3 text-[#5f0c27] border border-[#B61046] md:mt-[-20px] mt-[2px] bg-white w-[150px] h-[40px] rounded-md hover:bg-[#B61046] hover:text-white"
                    >
                      Yes, Decline
                      <Spinner
                        className="!w-3  !h-3 ml-[120px] mt-[-15px] text-white fill-pink-400"
                        status={status}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Cards Start */}
          <div className="mb-40 md:mb-20">
            <>
              <Loading status={singleInstructorStatus} />
              <Error
                retry={singleInstructorRetry}
                err={singleInstructorErr}
                status={singleInstructorStatus}
              />
              <Success status={singleInstructorStatus}>
                <>
                  {singleInstructor ? (
                    <div className="md:flex relative md:top-[-72px]">
                      <div className="p-10 md:ml-8 mb-8 border rounded-md border-gray-400 h-fit w-[80%]  md:w-1/2">
                        <div className="flex items-center mt-[-30px]">
                          <img
                            src={
                              singleInstructor?.profilePictureUrl ||
                              defaultImage
                            }
                            className=" w-[2rem] h-[2rem] rounded-2xl bg-no-repeat bg-contain bg-center bg-white"
                            alt="Profile Pic"
                          />
                          <span className="ml-2 text-gray-400">
                            Role:{" "}
                            <span className="text-black">
                              {singleInstructor?.roleName}
                            </span>
                          </span>
                        </div>
                        <div className="mt-3 mb-3">
                          <p className="text-gray-400">Profession</p>
                          {singleInstructor?.professions?.map((profession) => {
                            return (
                              <span
                                className="capitalize pr-2"
                                key={profession.id}
                              >
                                {profession.name}
                              </span>
                            );
                          })}
                        </div>

                        <div>
                          <p className="text-gray-400">Full Name</p>
                          <p>
                            {singleInstructor?.firstName +
                              " " +
                              singleInstructor?.lastName}
                          </p>
                        </div>
                        <hr />
                        <div>
                          <p className="mt-1 text-gray-400">
                            Level of Tutoring
                          </p>
                          <p>{singleInstructor.instructorLevel}</p>
                        </div>
                        <hr />
                        <div className="mt-2">
                          <span className="text-gray-400">Email</span>
                          <p className="mt-2">{singleInstructor.email}</p>
                        </div>
                      </div>
                      <div className="p-10 md:ml-12 mb-32 md:mb-0 mt-6 md:mt-0 border rounded-md border-gray-400 h-fit w-[80%] md:w-[30%]">
                        <p className="text-gray-400 ml-[-20px] mt-[-30px]">
                          Means of ID
                        </p>
                        <div className="flex items-center">
                          <img
                            src={
                              singleInstructor?.identificationLink ||
                              defaultImage
                            }
                            className="w-1/2 ml-[-20px]"
                            alt="lisence"
                          />
                          <span className="text-[#b61046] ml-3">
                            <button
                              type="button"
                              data-modal-toggle="defaultModal2"
                              onClick={toggleImage}
                            >
                              <EyeOutlined
                                style={{
                                  color: "#bbb6b6",
                                  marginRight: "5px",
                                }}
                              />
                              View
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p>No Instructor Details Found</p>
                  )}
                </>
              </Success>
            </>
          </div>
          {/* Cards End */}

          {/* Means of Id Image */}
          {showImage ? (
            <div
              id="defaultModal2"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] lg:max-w-[30%] m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* Modal header  */}
                  <div className="text-end">
                    <button
                      onClick={toggleImage}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal2"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body  */}
                  <div>
                    <img
                      className="w-full h-[300px]"
                      src={singleInstructor?.identificationLink || defaultImage}
                      alt="courseImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { ApproveInstructorPage };
