import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import differenceInHours from "date-fns/differenceInHours";

import {
  refreshTokenKey,
  tokenKey,
  lastUpdateKey,
  durationInHrs,
} from "../../utils";
import { ScrollToTop } from "./ScrollToTop";

const PrivateRoute = ({ children, redirectTo }) => {
  const navigate = useNavigate();

  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const path = !redirectTo ? `/login` : `/login?redirectTo=${redirectTo}`;

    const token = localStorage.getItem(tokenKey);
    const refreshToken = localStorage.getItem(refreshTokenKey);


    const lastUpdated = +localStorage.getItem(lastUpdateKey);
    const now = new Date();


    if (token && refreshToken && lastUpdated) {
      const hasExpired =
        differenceInHours(now, new Date(lastUpdated)) > 0.85 * durationInHrs;

      if (hasExpired) {
        navigate(path);
      } else {
        setLoading(false);
        setValid(true);
      }
    } else {
      navigate(path);
    }
  }, [redirectTo, navigate]);

  if (valid && !loading) {
    return <ScrollToTop>{children}</ScrollToTop>;
  }

  return <></>;
};

export { PrivateRoute };
