import React, { useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {NavBar, AppFooter,Loading,Error,Success,Pagination, PrivateRoute, DeactivatedInstructors} from "../../components";
import {selectInstructor,getDeactivatedInstructorsList} from "../../redux/slices";
import clamp from "lodash/clamp";

const DeactivatedInstructorsPage = () => {

    const dispatch = useDispatch();
    const { page,totalPages,deactivatedInstructor, deactivatedInstructorErr, deactivatedInstructorStatus } = useSelector(selectInstructor);
  
    useEffect(() => {
        dispatch(getDeactivatedInstructorsList());
    }, [dispatch]);
  
    const getList = useCallback(
        (force = true, page) => {
          dispatch(getDeactivatedInstructorsList({ force, page }));
        },
        [dispatch]
    );

    return (
        <PrivateRoute redirectTo="/deactivatedInstructors">
        <div className="min-h-fit overflow-hidden">
            <NavBar/>
            <section >
                <Loading status={deactivatedInstructorStatus}/>
                <Error retry={getList} err={deactivatedInstructorErr} status={deactivatedInstructorStatus} />
                <Success status={deactivatedInstructorStatus}>
                <DeactivatedInstructors data={deactivatedInstructor}/> 
                </Success>
                <Success status={deactivatedInstructorStatus}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() =>
                  getList(
                    false,
                    clamp(page - 1, 1, totalPages)
                  )
                }
                next={() =>
                  getList(
                    false,
                    clamp(page + 1, 1, totalPages)
                  )
                }
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
            </section>
            <AppFooter/>
        </div>
        </PrivateRoute>
    )
}

export {DeactivatedInstructorsPage}
