import { useState, useEffect } from 'react'
import { NavBar, AppFooter, PrivateRoute, Spinner } from '../../components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPricing, getPricingById, selectCourse } from '../../redux/slices'
import { CrownOutlined, CheckOutlined } from '@ant-design/icons'
import { axiosApiInstance, API_URL_V1, editPricingSchema } from '../../utils'
import { useRequest } from '../../hooks'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const CoursePricing = () => {
  const [showModal1, setShowModal1] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()

  const toggle1 = () => {
    setShowModal1((s) => !s)
    setShowModal(false)
  }

  const { status, setPending, setRejected, setResolved } = useRequest()
  const { allPricing, pricingById } = useSelector(selectCourse)

  const [priceValue, setPriceValue] = useState(pricingById?.price)
  const toggle = (x) => {
    setShowModal((s) => !s)
    setShowModal1(false)
    dispatch(getPricingById(x))
      .unwrap()
      .then((data) => {
        setPriceValue(data?.price)
      })
  }

  useEffect(() => {
    dispatch(getAllPricing())
  }, [dispatch])

  const {
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitted },
  } = useForm({
    defaultValues: {
      id: pricingById.id || '',
      price: pricingById.price || '',
      subscriptionType: pricingById.subscriptionType || '',
    },
    resolver: yupResolver(editPricingSchema),
  })

  useEffect(() => {
    setValue('price', priceValue)
    setValue('subscriptionType', pricingById?.subscriptionType)
    setValue('id', pricingById?.id)
  }, [priceValue, pricingById])

  const UpdatePricing = async (data) => {
    const body = {
      price: data?.price,
      subscriptionType: data?.subscriptionType,
    }
    setPending()

    try {
      await axiosApiInstance.put(
        `${API_URL_V1}/Pricings/update-pricing?pricingId=${data?.id}`,
        body
      )
      setResolved()
      toggle()
      dispatch(getAllPricing())
    } catch (err) {
      setRejected(err)
    }
  }

  const subscriptionColor = [
    { type: 'Free', colorType: '#f6edf0' },
    { type: 'Lite', colorType: '#a1a3a2' },
    { type: 'Standard', colorType: '#fab281' },
    { type: 'Premium', colorType: '#b61046' },
  ]

  const getSubscriptionColor = (subscriptionType) => {
    const selectedSubscription = subscriptionColor.find((x) => {
      return x.type === subscriptionType
    })
    if (selectedSubscription) {
      return selectedSubscription.colorType
    } else {
      return '#000000'
    }
  }
  return (
    <PrivateRoute redirectTo="/pricing">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <div className="bg-[#eaeaea] h-[90px] w-full relative top-12 overflow-hidden ">
          <div className="flex font-semibold ml-5 mt-[40px]">
            <span className="md:ml-10">
              <Link
                className="text-black hover:text-[#b61046]"
                to="/pendingCourses"
              >
                Awaiting Approvals
              </Link>
            </span>
            <span className="ml-8 md:ml-10">
              <Link className="text-black hover:text-[#b61046]" to="/courses">
                Courses
              </Link>
            </span>
            <span className=" ml-8 md:ml-10">Course Pricing</span>
            <hr className="w-[70px] mt-[45px] h-[2px] ml-[-80px] bg-[#b61046]" />
            <span className="ml-8 md:ml-10">
              <Link
                className="text-black hover:text-[#b61046]"
                to="/course-email"
              >
                Course Email
              </Link>
            </span>
          </div>
        </div>

        <div className="grid grid-rows-2 grid-flow-col gap-4 mt-[70px]">
          <div className="row-start-1 ml-6 md:ml-16">
            <h2 className="font-semibold text-lg">Pricing</h2>
          </div>
          <button
            className="row-start-1 ml-[65px] md:ml-[265px] lg:ml-[665px] text-white mt-[20px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
            type="button"
            data-modal-toggle="pricingModal"
            onClick={toggle1}
          >
            Edit Pricing
          </button>
        </div>

        {/* Edit Modal */}
        {/* Main modal  */}
        {showModal1 ? (
          <div
            id="pricingModal"
            tabIndex="-1"
            aria-hidden="true"
            className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full h-full"
          >
            <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] lg:max-w-[30%] m-auto">
              {/* Modal content */}
              <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  onClick={toggle1}
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  data-modal-toggle="pricingModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                {/* Modal header */}
                <div className="py-4 px-16 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-base font-semibold text-gray-900  dark:text-white">
                    Edit Course Pricing
                  </h3>
                  <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    Select a package to begin editing
                  </p>
                </div>
                {/* Modal body  */}
                <div className="p-6">
                  <div className="my-4 space-y-3">
                    {allPricing?.map((pricing) => {
                      const returnedColor = getSubscriptionColor(
                        pricing?.subscriptionType
                      )
                      return (
                        <>
                          <div>
                            <div
                              className="flex items-center pl-3 pt-2  mt-[-25px] w-full text-sm font-light text-gray-600 bg-gray-50 rounded-lg hover:bg-[#f6edf0] group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                              type="button"
                              data-modal-toggle="liteModal"
                              onClick={() => toggle(pricing.id)}
                            >
                              <CrownOutlined
                                style={{
                                  fontSize: '24px',
                                  marginBottom: '15px',
                                  color: returnedColor,
                                }}
                              />
                              <p className="mt-[-20px] ml-4 w-full">
                                {pricing.subscriptionType}{' '}
                              </p>
                              <p className="mt-[-20px] ml-[5px] font-semibold ">
                                N{pricing.price}
                              </p>
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/*Package Pricing Modal */}
        {showModal ? (
          <div
            id="liteModal"
            tabIndex="-1"
            class="backdrop-blur-none bg-gray-400/50  overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full h-full"
          >
            <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] lg:max-w-4xl m-auto">
              {/* Modal content */}
              <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal header */}
                <div class="flex justify-between items-center p-5 rounded-t border-b dark:border-gray-600">
                  <h3 class="text-base font-medium text-gray-900 dark:text-white">
                    Edit Course Pricing
                  </h3>
                  <button
                    onClick={toggle}
                    type="button"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="liteModal"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                {/* Modal body  */}
                <div class="py-6 px-8 space-y-12">
                  <div className="flex items-center justify-center space-x-4 bg-gray-200 ">
                    <CrownOutlined
                      style={{
                        fontSize: '24px',
                        marginBottom: '10px',
                        color: getSubscriptionColor(
                          pricingById?.subscriptionType
                        ),
                      }}
                    />

                    <p className="py-2 capitalize">
                      {pricingById.subscriptionType}
                    </p>
                  </div>
                  <p class="text-sm leading-relaxed text-gray-500 dark:text-gray-400 ">
                    Amount
                  </p>

                  {pricingById?.offers?.map((offer) => {
                    return (
                      <>
                        <p class="text-sm leading-relaxed text-gray-500 dark:text-gray-400 ">
                          This Package Includes:
                        </p>
                        <div className="flex relative top-[-20px] mb-32">
                          <CheckOutlined style={{ color: '#a1a3a2' }} />
                          <p className="text-gray-400 text-xs ml-2">{offer}</p>
                        </div>
                      </>
                    )
                  })}
                </div>
                {/* Modal footer */}
                <form onSubmit={handleSubmit(UpdatePricing)}>
                  <input
                    className="w-[90%] ml-3 border relative top-[-170px] border-gray-700 rounded-lg p-2 text-base"
                    type="text"
                    id={`price`}
                    value={priceValue}
                    onChange={(e) => {
                      setPriceValue(e.target.value)
                    }}
                  />

                  <div className="mt-[-40px] p-6">
                    <button
                      type="submit"
                      data-modal-toggle="liteModal"
                      className="relative flex items-center justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md group hover:bg-[#88062f] bg-[#b61046] focus:outline-none focus:ring-2 focus:ring-offset-2 "
                    >
                      Update
                      <Spinner
                        className="!w-3  !h-3 mx-1 text-white fill-pink-400"
                        status={status}
                      />
                    </button>
                  </div>

                  {isDirty && isSubmitted && (
                    <p className="text-red-500 text-xs">
                      Type in an Updated Price
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        ) : null}

        {/* Cards */}

        <div className="grid gap-0 lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
          {allPricing?.map((pricing) => {
            const returnedColor = getSubscriptionColor(
              pricing?.subscriptionType
            )
            return (
              <div className="flex items-center border border-gray-700 md:ml-16 m-4  bg-white rounded shadow-sm p-5  md:w-[60%] h-[120px] mb-16">
                <div>
                  <div className="ml-[286px] md:ml-[170px] lg:ml-[120px] mt-[-28px] border rounded-bl-lg text-center bg-[#f7f6f6] w-[50px]">
                    <CrownOutlined
                      style={{
                        fontSize: '24px',
                        marginBottom: '2px',
                        color: returnedColor,
                      }}
                    />
                  </div>
                  <div className="text-sm text-gray-400 mt-[10px] ">
                    {pricing?.subscriptionType} Package
                  </div>
                  <div className="text-2xl font-medium text-gray-600">
                    N{pricing?.price}
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <AppFooter />
      </div>
    </PrivateRoute>
  )
}

export { CoursePricing }
