const formatNumber = ( value ) =>
{
    return value ? ( new Intl.NumberFormat( 'en-NG', {
    } ) ).format( value ) : 0;
};


const formatCurrency = ( value, symbol = "₦" ) =>
{
    return `${ symbol } ${ formatNumber( value ) }`;
};


const truncateAndFormat = ( integer, fraction ) =>
{
    if ( fraction.toString().length )
    {
        return `${ formatNumber( integer ) }.${ fraction }`;
    }

    return formatNumber( integer );

};

const cleanNumberString = ( value ) =>
{

    let normalized = value.toString();

    //remove all chars except number and dot
    normalized = normalized.replace( /[^\d.]/g, "" );

    //replace all dots except first
    let index = 0;
    let result = normalized.replace( /\./g, ( item ) => ( !index++ ? item : "" ) );

    return result;
};

const addDecimal = ( result ) =>
{
    if ( !result )
    {
        return result;
    }
    const val = cleanNumberString( result.toString() );


    if ( val.includes( "." ) )
    {
        const split = val.split( "." );

        if ( split[ 1 ].length )
        {
            const formatted = truncateAndFormat( +split[ 0 ], split[ 1 ] );

            return formatted;
        }

        return `${ formatNumber( +split[ 0 ] ) }.`;
    }

    return formatNumber( +result );

};

export
{
    formatNumber,
    formatCurrency,
    addDecimal,
    cleanNumberString,
    truncateAndFormat
};
