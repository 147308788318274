import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  NavBar,
  StudentTable,
  AppFooter,
  Loading,
  Error,
  Success,
  Pagination,
  PrivateRoute,
} from '../../components'
import { selectStudent, getStudentList } from '../../redux/slices'
import clamp from 'lodash/clamp'

const StudentPage = () => {
  const dispatch = useDispatch()
  const { page, totalPages, studentList, studentListErr, studentListStatus } =
    useSelector(selectStudent)

  useEffect(() => {
    dispatch(getStudentList())
  }, [dispatch])

  const getList = useCallback(
    (force = true, page) => {
      dispatch(getStudentList({ force, page }))
    },
    [dispatch]
  )

  return (
    <PrivateRoute redirectTo="/activeStudents">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <section>
          <Loading status={studentListStatus} />
          <Error
            retry={getList}
            err={studentListErr}
            status={studentListStatus}
          />
          <Success status={studentListStatus}>
            <StudentTable data={studentList} />
          </Success>
          <Success status={studentListStatus}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() => getList(false, clamp(page - 1, 1, totalPages))}
                next={() => getList(false, clamp(page + 1, 1, totalPages))}
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
        </section>
        <AppFooter />
      </div>
    </PrivateRoute>
  )
}

export { StudentPage }
