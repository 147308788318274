import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { API_URL_V1, axiosApiInstance } from "../../utils";

export const login = createAsyncThunk(
  "login",
  async ({ userName, password }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL_V1}/Auth/admin-login`,
        {
          userName,
          password,
        }
      );

      return fulfillWithValue(response?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


export const revokeToken = createAsyncThunk("revokeToken", async (userId) => {
  await axiosApiInstance.post(
    `${API_URL_V1}/Auth/RevokeUserRefreshToken?userId=${userId}`,
    {
      useToken: false,
    }
  );
});

const initialState = {
  token: "",
  refreshToken: "",
  userId: "",
};


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      return initialState;
    },
    setTokens: (state, action) => {
      const newState = {
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        userId: action.payload.userId || state.userId,
      };

      return newState;

    },
  },
});


export const { logout, setTokens } = authSlice.actions;
export const authReducer = authSlice.reducer;
export const selectAuth = (state) => state.auth;
