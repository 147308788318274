import { initializeApp } from "firebase/app";
import "firebase/storage";
import { getStorage } from "firebase/storage";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  // apiKey: "AIzaSyBIxVcDVj6y3hXQ1V6IU5hbuiWtpozX9yw",
  // authDomain: "filmx-ca4c4.firebaseapp.com",
  // projectId: "filmx-ca4c4",
  // storageBucket: "filmx-ca4c4.appspot.com",
  // messagingSenderId: 1141321401,
  // appId: "1:1141321401:web:0557184ddd4fd4415bf568",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const { storage } = getStorage(app);
// export const analytics = getAnalytics(app);
