import { useEffect, useCallback } from "react";
import {
  NavBar,
  CardDiscount,
  AppFooter,
  PrivateRoute,
  Loading,
  Error,
  Success,
} from "../../components";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRequest } from "../../hooks";
import { getSingleDiscount, selectTransaction } from "../../redux/slices";
import { LeftOutlined } from "@ant-design/icons";
import { axiosApiInstance, API_URL_V1, formatDateTime } from "../../utils";

const DiscountDetail = () => {
  const { setPending, setRejected } = useRequest();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { discountId } = useParams();
  const { singleDiscount, singleDiscountStatus, singleDiscountErr } =
    useSelector(selectTransaction);

  useEffect(() => {
    dispatch(getSingleDiscount(discountId));
  }, [dispatch, discountId]);

  const getList = useCallback(
    (discountId, force = true) => {
      dispatch(
        getSingleDiscount({
          discountId,
          force,
        })
      );
    },
    [dispatch]
  );

  const ToggleDiscount = async () => {
    setPending();
    try {
      await axiosApiInstance.patch(
        `${API_URL_V1}/DiscountCodes/toggle?id=${discountId}`
      );
      dispatch(getSingleDiscount(discountId));
    } catch (err) {
      setRejected(err);
    }
  };

  return (
    <PrivateRoute redirectTo="/discount-code">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <div className="bg-[#eaeaea] h-[90px] w-full relative top-12 overflow-hidden ">
          <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
            <span className="ml-10 mt-10 md:mt-0">Discounts</span>
            <hr className="w-[70px] mt-16 md:mt-11 h-[2px] ml-[-64px] bg-[#b61046]" />
          </div>
        </div>
        <div className="w-full  relative left-10 top-[90px] ">
          <div className="grid grid-rows-2 grid-flow-col gap-4">
            <div className="md:row-start-1 md:ml-8">
              <button onClick={() => navigate(-1)}>
                <Link className="text-[#b61046] hover:text-black" to="">
                  <LeftOutlined style={{ fontSize: "12px" }} />
                  <span className="m-[12px]">Back</span>
                </Link>
              </button>
              <h2 className="font-semibold text-lg">
                Discount code {singleDiscount?.code}
              </h2>
              <p className="text-sm text-gray-400">
                Expiry Date
                <span className="px-1">
                  {formatDateTime(
                    new Date(singleDiscount?.expiryDate),
                    "LLL do, yyyy"
                  )}
                </span>
              </p>
            </div>

            <button
              onClick={() => ToggleDiscount(discountId)}
              className="row-start-1 ml-[-70px] md:ml-[265px] lg:ml-[635px] text-white mt-[20px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
              type="button"
            >
              {singleDiscount?.isActive ? "Deactivate" : "Activate"}
            </button>
          </div>
        </div>

        <div>
          <div className="flex items-center px-4 space-x-4 md:px-20 py-6 md:space-x-12">
            <div className="border-[#D3D3D3] border p-4 md:px-8 md:py-4 rounded-md">
              <p className="font-semibold text-2xl ">
                {singleDiscount?.numberOfUses}
              </p>
              <p className="font-medium text-lg text-gray-400">
                Number of Redemptions
              </p>
            </div>
            <div className="border-[#D3D3D3] border p-4 md:px-8 md:py-4 rounded-md">
              <p className="font-semibold text-2xl">
                ₦{singleDiscount?.totalRedemptionRevenue}
              </p>
              <p className="font-medium text-lg text-gray-400">
                Total Redemption Revenue
              </p>
            </div>
          </div>
          <div>
            <Loading status={singleDiscountStatus} />
            <Error
              retry={getList}
              err={singleDiscountErr}
              status={singleDiscountStatus}
            />
            <Success status={singleDiscountStatus}>
              <div className="lg:flex space-x-10 px-4 md:px-6">
                <div className="border-[#D3D3D3] border px-4 md:ml-[60px] mb-24 h-fit py-4 rounded-md w-[90%] lg:w-[60%]">
                  <div className="">
                    <p className="font-bold m-5">
                      Times Used({singleDiscount?.numberOfUses})
                    </p>
                  </div>
                  <CardDiscount data={singleDiscount?.usages} />
                </div>
                <div className="border-[#D3D3D3] border px-4 mb-24 h-fit py-4 rounded-md w-[80%] md:w-[60%] lg:w-[30%]">
                  <div className=" flex items-center justify-between">
                    <p className="font-bold text-lg m-5">Details</p>
                    <p className="rounded bg-gray-400 px-2 py-1">
                      {singleDiscount?.code}
                    </p>
                  </div>
                  <div className="px-4">
                    <p className="text-gray-400 ">Code Type</p>
                    {singleDiscount?.discountType === "DiscountByPercentage" ? (
                      <div className="flex items-center space-x-4">
                        <p className="px-2 py-1 bg-[#fbe4d6] text-[#f86205] rounded">
                          % Percentage
                        </p>
                        <p className="px-2 py-1 bg-[#fbe4d6] text-[#f86205] rounded">
                          {singleDiscount?.value + "% off"}
                        </p>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-4">
                        <p className="px-2 py-1 bg-[#fbcedd] text-[#b61046] rounded">
                          % Percentage
                        </p>
                        <p className="px-2 py-1 bg-[#fbcedd] text-[#b61046] rounded">
                          {"₦" + singleDiscount?.value + "off"}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="px-4">
                    <p className="text-gray-400 ">Limit (Value cap)</p>
                    <p>{"₦" + singleDiscount?.limitCap}</p>
                  </div>
                  <div className="px-4">
                    <p className="text-gray-400 ">Expiry Date</p>
                    <p>
                      {" "}
                      {formatDateTime(
                        new Date(singleDiscount?.expiryDate),
                        "LLLL do, yyyy"
                      )}{" "}
                    </p>
                  </div>
                  <div className="px-4">
                    <p className="text-gray-400 ">Validity</p>
                    <p>{singleDiscount?.usageType}</p>
                  </div>
                </div>
              </div>
            </Success>
          </div>
        </div>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { DiscountDetail };
