import React, { useState } from 'react'
import { useTable, useGlobalFilter } from 'react-table'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { SearchFilter } from '../../components/UI'
import styles from '../../components/UI/ResponsiveTable.module.css'
import { EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, Form, Input, Button } from 'antd'

import { formatDateTime } from '../../utils'
import defaultImage from '../../assets/images/defaultImage.png'

const EmployerTable = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleCreate = (values) => {
    // onEmployerCreate(values)
    setIsModalVisible(false)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Company's Name",
        accessor: 'profile',
        Cell: ({ cell }) => {
          const profileImage = cell?.row?.original.profilePictureUrl
          return (
            <div className="flex items-center">
              <div
                key={profileImage}
                className="ml-[50px] phone-md:ml-[110px] md:ml-96 lg:ml-0 w-[2rem] h-[2rem] rounded-2xl bg-no-repeat bg-contain bg-center bg-white "
                style={{
                  backgroundImage: `url("${profileImage || defaultImage}")`,
                }}
              ></div>
              <p className="ml-2">
                {cell?.row?.original?.firstName +
                  ' ' +
                  cell?.row?.original?.lastName}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Company Email',
        accessor: 'email',
      },
      {
        Header: 'Contact Person',
        accessor: 'person',
      },
      {
        Header: 'Contact Phone',
        accessor: 'phone',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        Cell: (props) => {
          return (
            formatDateTime(new Date(props?.value), 'LLLL do, yyyy. hh:mm a') ||
            'N/A'
          )
        },
      },
      {
        Header: 'Action',
        Cell: ({ cell }) => {
          const studentId = cell?.row?.original?.id
          return (
            <div className="lg:flex items-center">
              <Link
                className="m-1 md:ml-96 lg:m-1 text-black"
                to={`/students/${studentId}`}
              >
                <EyeOutlined />
                <span className=" phone-xs:ml-[180px] phone-md:ml-[200px] md:ml-[500px] lg:m-3 text-[#c43d68]">
                  View
                </span>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter)

  const { globalFilter } = state

  return (
    <div className="relative mt-4 mb-12 shadow-md sm:rounded-sm lg:overflow-hidden ">
      <div className="bg-[#eaeaea] h-[90px] w-full relative top-4 md:top-12 md:overflow-hidden ">
        <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
          <span className="ml-10 mt-10 md:mt-0">
            Employer
            <hr className="w-[70px] mt-2 md:mt-6 h-[2px] bg-[#b61046]" />
          </span>
          <span className="ml-10 mt-10 md:mt-0">
            <Link className="text-black hover:text-[#b61046]" to="/jobs">
              Jobs
            </Link>
          </span>
        </div>
        <div className="bg-[#eaeaea] md:ml-[400px] lg:ml-[720px] md:mt-[-70px] md:w-1/2">
          <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>

      <div className="flex items-center justify-between mb-[50px] gap-4 mt-14 ml-6">
        <div>
          <h2 className="font-semibold text-lg mt-12 md:mt-5 ml-3 md:ml-12">
            Employer
          </h2>
        </div>
        <Button
          type="primary"
          onClick={showModal}
          style={{
            backgroundColor: '#b61046',
            border: 'none',
            borderRadius: '4px',
            color: 'white',
          }}
        >
          Create Employer
        </Button>
      </div>

      <table
        {...getTableProps()}
        className={classNames(
          'w-[90%] lg:w-[1200px] m-auto mt-[-70px] my-1.5 md:mt-[-30px] text-sm text-left text-gray-500',
          styles.table
        )}
      >
        <thead className="text-xs text-gray-400 capitalize bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="px-6 py-3">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className="bg-white rounded-2xl shadow-md border p-4"
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      data-label={cell?.column?.Header}
                      {...cell.getCellProps()}
                      className="px-6 py-4 text-gray-900 text-xs min-w-min"
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <Modal
        title="Create New Employer"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form onFinish={handleCreate}>
          <Form.Item
            name="companyName"
            label="Company's Name"
            rules={[
              { required: true, message: 'Please input the company name!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="companyEmail"
            label="Company Email"
            rules={[
              { required: true, message: 'Please input the company email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactPerson"
            label="Contact Person"
            rules={[
              { required: true, message: 'Please input the contact person!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactPhone"
            label="Contact Phone"
            rules={[
              { required: true, message: 'Please input the contact phone!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="bg-[#b61046] hover:bg-[#d4265c]"
              style={{
                backgroundColor: '#b61046',
                border: 'none',
                borderRadius: '4px',
                color: 'white',
              }}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export { EmployerTable }
