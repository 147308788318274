import React, { useState, useEffect, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  NavBar,
  CardDb,
  TransactionChart,
  RevenueChart,
  UsersChart,
  Card,
  Card2,
  AppFooter,
  Error,
  Spinner,
  PrivateRoute,
  Loading,
  Success,
} from "../components";
import { Row, Col } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createAdminSchema,
  createAdminInit,
  axiosApiInstance,
  API_URL_V1,
} from "../utils";
import { useRequest } from "../hooks";
import {
  getAllRoles,
  getApprovedList,
  getTransactionChart,
  getRevenueChart,
  getUsersChart,
  getApprovedCourseList,
  selectAdmin,
  selectCourse,
  selectInstructor,
} from "../redux/slices";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transactionChartData, setTransactionChartData] = useState([]);
  const [revenueChartData, setRevenueChartData] = useState([]);
  const [userChartData, setUserChartData] = useState([]);

  const { err, status, setPending, setRejected } = useRequest();
  const { roles, transactionEarnings, revenue, regUsers } =
    useSelector(selectAdmin);
  const { approvedList, approvedListErr, approvedListStatus } =
    useSelector(selectInstructor);
  const { approvedCourse, approvedCourseErr, approvedCourseStatus } =
    useSelector(selectCourse);

  const formattedTransactionData = () => {
    const mappedData = transactionEarnings.map((monthlyTransaction) => {
      return {
        month: monthlyTransaction?.month,
        transaction:
          monthlyTransaction?.totalAmount?.totalAmountForTransactions,
        settlement: monthlyTransaction?.totalAmount?.totalAmountForSettlement,
      };
    });
    return mappedData;
  };

  const formattedRevenueData = () => {
    const mappedData = revenue?.map((filteredRevenue) => {
      return {
        name:
          filteredRevenue?.week ||
          filteredRevenue.month ||
          filteredRevenue.year,
        amount: filteredRevenue?.amount,
      };
    });
    return mappedData;
  };

  const formattedUsersData = () => {
    const mappedData = regUsers?.map((filteredUsers) => {
      return {
        name: filteredUsers?.week || filteredUsers.month || filteredUsers.year,
        count: filteredUsers?.count,
      };
    });
    return mappedData;
  };

  useEffect(() => {
    if (transactionEarnings.length > 0) {
      const transactionData = formattedTransactionData();
      setTransactionChartData(transactionData);
    }
    if (revenue.length > 0) {
      const revenueData = formattedRevenueData();
      setRevenueChartData(revenueData);
    }
    if (regUsers.length > 0) {
      const usersData = formattedUsersData();
      setUserChartData(usersData);
    }
  }, [transactionEarnings, revenue, regUsers]);

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getApprovedList());
    dispatch(getApprovedCourseList());
    dispatch(getTransactionChart());
    dispatch(getRevenueChart({ filter: "Week", force: true }));
    dispatch(getUsersChart({ filter: "Week", force: true }));
  }, [dispatch]);

  const getInstructorsSlice = useCallback(
    (force = true, page) => {
      dispatch(getApprovedList({ force, page }));
    },
    [dispatch]
  );
  const getCourseSlice = useCallback(
    (force = true, page) => {
      dispatch(getApprovedCourseList({ force, page }));
    },
    [dispatch]
  );

  const notify = () => {
    toast.success("Verification link has been sent to your mailbox", {
      autoClose: 12000,
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: createAdminInit,
    resolver: yupResolver(createAdminSchema),
  });

  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((s) => !s);
  };

  //handle toggle
  const toggle = () => {
    setOpen(!open);
  };

  const onCreate = async (values) => {
    setPending();

    try {
      await axiosApiInstance.post(
        `${API_URL_V1}/Admins/create-admin-account`,
        values
      );
      reset();
      navigate("/manage");
    } catch (error) {
      setRejected(error);
    }
  };

  return (
    <PrivateRoute redirectTo="/dashboard">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <div className="bg-[#eaeaea] h-[50px] w-full relative top-14 overflow-hidden "></div>
        <div className="w-full h-full relative left-10 top-[90px] ">
          <div className="grid grid-rows-2 grid-flow-col gap-4">
            <div className="ml-[-28px] md:row-start-1 md:ml-8">
              <h2 className="font-semibold md:text-lg">Welcome Admin,</h2>
              <p>Here is an overview of your activity</p>
            </div>
            <button
              onClick={toggleModal}
              className="ml-[-35px] row-start-1 lg:ml-[650px] text-white mt-[20px] bg-[#B61046] w-[100px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
              type="button"
              data-modal-toggle="authentication-modal"
            >
              Create Admin
            </button>
          </div>

          {/* Modal Starts Here */}
          {showModal ? (
            <div
              id="authentication-modal"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full h-full"
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] lg:max-w-[30%] m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  <button
                    onClick={toggleModal}
                    type="button"
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="authentication-modal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="py-6 px-6 lg:px-8">
                    <h3 className="mb-3.5 text-lg font-bold">
                      Create New Admin
                    </h3>
                    <Error err={err} status={status} />

                    <form
                      className="px-6 pb-2 "
                      onSubmit={handleSubmit(onCreate)}
                    >
                      <hr />

                      <Row gutter={[10, 10]}>
                        <Col xs={24} sm={12}>
                          <div className="relative pb-3.5 ">
                            <label
                              htmlFor={`firstName`}
                              className="block mb-2 text-sm font-medium text-gray-400 "
                            >
                              First Name
                            </label>

                            <input
                              className="md:w-[150px] w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                              id={`firstName`}
                              {...register("firstName")}
                              type="text"
                              placeholder=""
                            />
                            <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                              {errors?.firstName?.message}
                            </output>
                          </div>
                        </Col>

                        <Col xs={24} sm={12}>
                          <div className="relative pb-3.5 ">
                            <label
                              htmlFor={`lastName`}
                              className="block mb-2 text-sm font-medium text-gray-400 "
                            >
                              Last Name
                            </label>

                            <input
                              className="md:w-[150px] w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                              id={`lastName`}
                              {...register("lastName")}
                              type="text"
                              placeholder=""
                            />
                            <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                              {errors?.lastName?.message}
                            </output>
                          </div>
                        </Col>

                        <Col xs={24} sm={12}>
                          <div className="relative pb-3.5 ">
                            <label
                              htmlFor={`roleName`}
                              className="block mb-2 text-sm font-medium text-gray-400 "
                            >
                              Role/Level
                            </label>

                            <select
                              id={`roleName`}
                              {...register("roleName")}
                              className="md:w-[310px] w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-800 border  rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            >
                              <option value="">Select Role</option>
                              {roles?.map((singleRole, index) => {
                                return (
                                  <option key={index} value={singleRole}>
                                    {singleRole}
                                  </option>
                                );
                              })}
                            </select>
                            <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                              {errors?.roleName?.message}
                            </output>
                          </div>
                        </Col>
                        <Col xs={24} sm={12}>
                          <div className="relative pb-3.5 md:top-[80px] top-[-10px] md:left-[-155px] ">
                            <label
                              htmlFor={`email`}
                              className="block mb-2 text-sm font-medium text-gray-400 "
                            >
                              Email
                            </label>

                            <input
                              id={`email`}
                              {...register("email")}
                              type="email"
                              className="block md:w-[310px] w-full p-2 leading-tight text-gray-700 border  rounded shadow appearance-none focus:outline-none focus:shadow-outline "
                            />
                            <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                              {errors?.email?.message}
                            </output>
                          </div>
                        </Col>
                        <Col xs={24} sm={12}>
                          <div className="relative pb-3.5 md:top-[60px] top-[-10px] ">
                            <label
                              htmlFor={`password`}
                              className="block mb-2 text-sm font-medium text-gray-400 "
                            >
                              Password
                            </label>

                            <input
                              id={`password`}
                              {...register("password")}
                              type={open === false ? "password" : "text"}
                              className="md:w-[310px] w-full px-3 py-2 leading-tight text-gray-700 border  rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                              placeholder="Password"
                            />
                            <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                              {errors?.password?.message}
                            </output>
                          </div>
                          <div className="absolute md:top-[92px] top-[22px] right-[20px] md:right-[-140px]">
                            {open === false ? (
                              <EyeOutlined
                                style={{ color: "#7d7d7d" }}
                                onClick={toggle}
                              />
                            ) : (
                              <EyeInvisibleOutlined
                                style={{ color: "#7d7d7d" }}
                                onClick={toggle}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>

                      <div className="w-3/4 md:mt-[55px] mt-[-15px] md:flex md:items-center ">
                        <input
                          checked
                          readOnly
                          id={`notifyAdmin`}
                          type="checkbox"
                          {...register("notifyAdmin")}
                          className="w-4 h-4 accent-[#b61046] ring-[#b61046]  focus:ring-red-600"
                        />
                        <label
                          htmlFor={`notifyAdmin`}
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Notify Admin By Email
                        </label>
                        <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                          {errors?.notifyAdmin?.message}
                        </output>
                      </div>
                      <div className="mt-5">
                        <button
                          type="submit"
                          onClick={notify}
                          className="relative flex items-center justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md group hover:bg-[#88062f] bg-[#b61046] focus:outline-none focus:ring-2 focus:ring-offset-2 "
                        >
                          Create Admin
                          <Spinner
                            className="!w-3  !h-3 mx-1 text-white fill-pink-400"
                            status={status}
                          />
                        </button>
                        <ToastContainer />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* Modal Ends Here */}

          <div className="lg:flex justify-between lg:m-10">
            <CardDb />
            <TransactionChart
              barKey1={"transaction"}
              barKey2={"settlement"}
              monthKey={"month"}
              data={transactionChartData}
            />
          </div>
          <div className="grid grid-rows-2 md:grid-cols-2 mb-10 md:-mb-52 lg:mb-20">
            <Loading status={approvedListStatus} />
            <Error
              retry={getInstructorsSlice}
              err={approvedListErr}
              status={approvedListStatus}
            />
            <Success status={approvedListStatus}>
              <Card data={approvedList.slice(0, 3)} />
            </Success>
            <Loading status={approvedCourseStatus} />
            <Error
              retry={getCourseSlice}
              err={approvedCourseErr}
              status={approvedCourseStatus}
            />
            <Success status={approvedCourseStatus}>
              <Card2 data={approvedCourse.slice(0, 3)} />
            </Success>
          </div>
          <div className="relative lg:-top-40 lg:flex justify-between -px-10 lg:px-10 mb-40 lg:mb-24">
            <RevenueChart
              barKey1={"revenue"}
              weekKey={"week"}
              monthKey={"month"}
              yearKey={"year"}
              data={revenueChartData}
            />
            <UsersChart
              barKey1={"registered users"}
              monthKey={"month"}
              data={userChartData}
            />
          </div>
        </div>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { Dashboard };
