import React from "react";
import { useTable } from "react-table";
import classNames from "classnames";
import styles from "../UI/ResponsiveTable.module.css";
import { StatusPill, Spinner } from "../UI";
import { useParams } from "react-router-dom";
import { formatNumber, formatDateTime } from "../../utils";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactionByStudent,
  selectTransaction,
  setEndTransaction,
  setStartTransaction,
} from "../../redux/slices";

const CardStudent = ({ data }) => {
  const dispatch = useDispatch();
  const { studentId } = useParams();

  const { startDate, endDate } = useSelector(selectTransaction);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      getTransactionByStudent({
        studentId,
        force: true,
        start: startDate,
        end: endDate,
      })
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Category",
        accessor: "transactionCategory",
      },
      // {
      //         Header: "Status",
      //         accessor: "transactionStatus",
      // },
      {
        Header: "Package",
        accessor: "subscriptionType",
      },
      {
        Header: "Amount(₦)",
        accessor: "amount",
        Cell: (props) => {
          return formatNumber(props?.value) || "N/A";
        },
      },
      {
        Header: "Type",
        // accessor: "amount",
        Cell: (cell) => {
          const originalPrice = cell?.row?.original?.originalPrice;
          const amount = cell?.row?.original?.amount;
          if (amount < originalPrice) {
            return "Discounted";
          }
          if (amount === originalPrice) {
            return "Paid in Full";
          }
          if (amount === 0) {
            return "Promo Price";
          }
          // return formatNumber(props?.value) || "N/A";
        },
      },
      {
        Header: "Date",
        accessor: "dateCompleted",
        Cell: (props) => {
          return (
            formatDateTime(new Date(props?.value), "LLLL do, yyyy. hh:mm a") ||
            "N/A"
          );
        },
      },
      {
        Header: "Status",
        accessor: "subscriptionStatus",
        Cell: StatusPill,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="flex relative top-[40px] mb-16 lg:mb-[200px] md:left-8">
      {/* First Table */}
      <div className=" mb-[397px] w-full">
        <div className="flex ">
          <p className="font-bold mt-5">Transactions</p>
        </div>
        <div className="md:ml-[300px] -ml-12 md:mt-[-70px] md:w-1/2">
          <form
            onSubmit={onSubmit}
            className="flex flex-wrap items-center pt-6 ml-4 md:-mx-10 md:space-x-2"
          >
            <div className="my-2 border border-red-800 rounded-lg p-2 w-1/4 ">
              <DatePicker
                placeholderText="Start Date"
                selected={new Date(startDate)}
                onChange={(value) =>
                  dispatch(setStartTransaction(value.toISOString()))
                }
                className="w-full outline-none"
              />
            </div>

            <div className="my-2 ml-2 border border-red-800 rounded-lg p-2 w-1/4 ">
              <DatePicker
                placeholderText="End Date"
                selected={new Date(endDate)}
                onChange={(value) =>
                  dispatch(setEndTransaction(value.toISOString()))
                }
                className="w-full outline-none"
              />
            </div>

            <button
              className="px-2 md:px-4 py-2 ml-4 my-2 rounded-lg text-white bg-[#b61046]"
              type="submit"
            >
              Fetch Transactions
              <Spinner />
            </button>
          </form>
        </div>
        <div className="-ml-6 lg:-ml-2 mt-2">
          <table
            {...getTableProps()}
            className={classNames(
              "w-[85%] lg:w-full  text-sm text-left text-gray-500",
              styles.table
            )}
          >
            <thead className="text-xs capitalize text-gray-400 bg-gray-50">
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map((column) => (
                    <th {...column.getHeaderProps()} className="px-6 py-3">
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows?.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="bg-white rounded-2xl shadow-md border p-4"
                  >
                    {row?.cells?.map((cell) => {
                      return (
                        <td
                          data-label={cell?.column?.Header}
                          {...cell.getCellProps()}
                          className="px-6 py-4 text-gray-900 min-w-min "
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data.length < 1 && (
            <p className="text-center">No Transactions Found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export { CardStudent };
