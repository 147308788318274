import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./index.module.css";

const FadeInOut = ( {
    className,
    show,
    children,
    dur = "1s" } ) =>
{
    const [ shouldRender, setRender ] = useState( show );

    useEffect( () =>
    {
        if ( show ) setRender( true );
    }, [ show ] );

    const onAnimationEnd = () =>
    {
        if ( !show ) setRender( false );
    };

    return (
        shouldRender && (
            <div
                className={ classNames( styles.class, className ) }
                style={
                    {
                        animation: `${ show ? styles.fadeIn : styles.fadeOut } ${ dur }`
                    }
                }
                onAnimationEnd={ onAnimationEnd }
            >
                { children }
            </div>
        )
    );
};

export { FadeInOut };
