import { lastUpdateKey, refreshTokenKey, tokenKey } from "./constants";

export const setTokenService = (refreshToken, token) => {
  const at = new Date().getTime();
  localStorage.setItem(refreshTokenKey, refreshToken);
  localStorage.setItem(tokenKey, token);
  localStorage.setItem(lastUpdateKey, at);
};

export const clearTokenService = () => {
  localStorage.removeItem(refreshTokenKey);
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(lastUpdateKey);
};

