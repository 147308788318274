import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
import { getRevenueChart } from "../../redux/slices";
import { useDispatch } from "react-redux";

const RevenueChart = ({ data }) => {
  const dispatch = useDispatch();
  const [filterRevenue, setFilterRevenue] = useState("Week");

  const onChangeHandler = (e) => {
    setFilterRevenue(e.target.value);
    dispatch(getRevenueChart({ filter: e.target.value, force: true }));
  };
  return (
    <ResponsiveContainer width="50%">
      <div className="lg:border md:rounded mt-[30px] ml-[-50px] md:ml-32 lg:mt-0 lg:ml-0 md:mr-10 md:mb-5">
        <div className="flex">
          <p className="font-bold ml-12 md:m-5">Revenue</p>
          <form className="mt-5 ml-[135px]">
            <select
              value={filterRevenue}
              onChange={onChangeHandler}
              name="duration"
              className="p-2 text-[#ef4d41] mt-[-20px] ml-6 md:ml-0 border-[#ef4d41]"
            >
              <option value="select...">Filter By</option>
              <option value="Week">Week</option>
              <option value="Month">Month</option>
              <option value="Year">Year</option>
            </select>
          </form>
        </div>
        <BarChart
          width={550}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 50,
            bottom: 15,
          }}
        >
          <Legend layout="horizontal" align="left" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="amount" stackId="a" fill="#d6064a" />
        </BarChart>
      </div>
    </ResponsiveContainer>
  );
};

export { RevenueChart };
