import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppFooter,NavBar,Loading,Success,Error,PrivateRoute} from "../../components"
import {getSingleAdmin,selectAdmin,selectAuth} from "../../redux/slices";
import {formatDateTime} from "../../utils";
import {Link} from "react-router-dom";
import defaultImage from "../../assets/images/defaultImage.png";

const ProfileCard = () => {

const dispatch = useDispatch();
const {userId} = useSelector(selectAuth);

const {
    singleAdmin,
    singleAdminErr,
    singleAdminStatus,
  } = useSelector(selectAdmin);

  useEffect(() => {
    dispatch(getSingleAdmin(userId));
  }, [dispatch, userId]);

  const singleAdminRetry = () => {
    if (singleAdminErr) {
      dispatch(getSingleAdmin(userId));
    }
  };

    return (
      <PrivateRoute redirectTo="/profile">
        <div className="min-h-fit overflow-hidden">
        <NavBar/>
        
        <div className="md:mt-24 mt-36 md:ml-4 ml-16 mb-24">
        <h2 className="font-bold text-lg text-gray-900 md:ml-10">My Profile</h2>
        <Loading status={singleAdminStatus} />
                <Error
                  retry={singleAdminRetry}
                  err={singleAdminErr}
                  status={singleAdminStatus}
                />
                <Success status={singleAdminStatus}>
                  <>
        {singleAdmin ? (
             <div className="p-10 md:ml-8 mb-8 border rounded-md border-gray-400 h-fit w-[80%]  md:w-1/2">
             <div className="flex items-center mt-[-30px]">
                 <img src= {singleAdmin?.profilePictureUrl || defaultImage}
               className=" w-[2rem] h-[2rem] rounded-2xl bg-no-repeat bg-contain bg-center bg-white" alt="Profile Pic"/>
                 <span className="ml-2 text-gray-400">Alias: <span className="text-black">{singleAdmin?.roleName}</span></span>
             </div>
             <div className="mt-3">
             <p className="text-gray-400">Role/Level</p>
             <p>{singleAdmin?.roleName}</p>
             </div>
             <div>
             <p className="text-gray-400">Full Name</p>
             <p>{singleAdmin?.firstName + " " + singleAdmin?.lastName}</p>
             </div>
             <hr/>
             <div>
                 <p className="mt-1 text-gray-400">Date Created</p>
                 <span>{formatDateTime(new Date(singleAdmin?.dateCreated),
                                "LLLL do, yyyy. hh:mm a"
                              )}</span>
             </div>
             <hr/>
             <div className="mt-2">
                 <p className="text-gray-400">Email</p>
                 <p className="mt-2">{singleAdmin?.email}</p>
             </div>
             <button className="md:ml-40 lg:ml-72 ml-12 text-white  hover:border hover:border-[#B61046] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]" type="submit" >
                 <Link
                className="md:m-1  text-gray-900 hover:text-[#5f0c27]"
                to={`/editProfile`}
              > Edit Profile</Link></button>
           </div>
        ):(
            <p>No Details Found</p>
        )}
         </>
        </Success>
        </div>    
        <AppFooter/>
        </div>
        </PrivateRoute>
    )
}

export  {ProfileCard}
