import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL_V1, axiosApiInstanceGet, FETCH_STATUS } from "../../utils";

const currentYear = new Date().getFullYear();

export const getAllRoles = createAsyncThunk(
  "getAllRoles",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Roles/ViewAll`,
        force
      );

      return fulfillWithValue(response?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAdminList = createAsyncThunk(
  "getAdminList",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Admins/get-all-admin?page=${page}&perPage=${pageSize}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSingleAdmin = createAsyncThunk(
  "getSingleAdmin",
  async (userId, { fulfillWithValue, rejectWithValue }) => {
    try {
      let force = true;
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Admins/get-admin?adminId=${userId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getDashboardStat = createAsyncThunk(
  "getDashboardStat",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Analytics/get-dashboard-stats`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getTransactionChart = createAsyncThunk(
  "getTransactionChart",
  async (force, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Transactions/list-transaction-stats?year=${currentYear}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getRevenueChart = createAsyncThunk(
  "getRevenueChart",
  async ({ force, filter }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Analytics/get-revenue-stats?FilterBy=${filter}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUsersChart = createAsyncThunk(
  "getUsersChart",
  async ({ force, filter }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Analytics/get-registered-users-stats?FilterBy=${filter}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const pageSize = 10;
const initialState = {
  roles: [],
  rolesErr: "",
  rolesStatus: FETCH_STATUS.IDLE,

  page: 0,
  totalPages: 1,
  adminList: [],
  adminListErr: "",
  adminListStatus: FETCH_STATUS.IDLE,
  singleAdmin: {},
  singleAdminErr: "",
  singleAdminStatus: FETCH_STATUS.IDLE,
  dashboard: {},
  dashboardErr: "",
  dashboardStatus: FETCH_STATUS.IDLE,
  transactionEarnings: [],
  transactionEarningsErr: "",
  transactionEarningsStatus: FETCH_STATUS.IDLE,
  revenue: [],
  revenueErr: "",
  revenueStatus: FETCH_STATUS.IDLE,
  regUsers: [],
  regUsersErr: "",
  regUsersStatus: FETCH_STATUS.IDLE,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoles.pending, (state) => {
        state.rolesStatus = FETCH_STATUS.PENDING;
        state.rolesErr = "";
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.rolesStatus = FETCH_STATUS.RESOLVED;
        state.roles = action.payload;
        state.rolesErr = "";
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.rolesStatus = FETCH_STATUS.REJECTED;
        state.rolesErr = action.payload;
      })
      .addCase(getAdminList.pending, (state) => {
        state.adminListStatus = FETCH_STATUS.PENDING;
        state.adminListErr = "";
      })
      .addCase(getAdminList.fulfilled, (state, action) => {
        state.adminListStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.adminList = pagedList;
        state.adminListErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getAdminList.rejected, (state, action) => {
        state.adminListStatus = FETCH_STATUS.REJECTED;
        state.adminListErr = action.payload;
      })
      .addCase(getSingleAdmin.pending, (state) => {
        state.singleAdminStatus = FETCH_STATUS.PENDING;
        state.singleAdminErr = "";
      })
      .addCase(getSingleAdmin.fulfilled, (state, action) => {
        state.singleAdminStatus = FETCH_STATUS.RESOLVED;
        state.singleAdmin = action.payload;
        state.singleAdminErr = "";
      })
      .addCase(getSingleAdmin.rejected, (state, action) => {
        state.singleAdminStatus = FETCH_STATUS.REJECTED;
        state.singleAdminErr = action.payload;
      })
      .addCase(getDashboardStat.pending, (state) => {
        state.dashboardStatus = FETCH_STATUS.PENDING;
        state.dashboardErr = "";
      })
      .addCase(getDashboardStat.fulfilled, (state, action) => {
        state.dashboardStatus = FETCH_STATUS.RESOLVED;
        state.dashboard = action.payload;
        state.dashboardErr = "";
      })
      .addCase(getDashboardStat.rejected, (state, action) => {
        state.dashboardStatus = FETCH_STATUS.REJECTED;
        state.dashboardErr = action.payload;
      })
      .addCase(getTransactionChart.pending, (state) => {
        state.transactionEarningsStatus = FETCH_STATUS.PENDING;
        state.transactionEarningsErr = "";
      })
      .addCase(getTransactionChart.fulfilled, (state, action) => {
        state.transactionEarningsStatus = FETCH_STATUS.RESOLVED;
        state.transactionEarnings = action.payload;
        state.transactionEarningsErr = "";
      })
      .addCase(getTransactionChart.rejected, (state, action) => {
        state.transactionEarningsStatus = FETCH_STATUS.REJECTED;
        state.transactionEarningsErr = action.payload;
      })

      .addCase(getRevenueChart.pending, (state) => {
        state.revenueStatus = FETCH_STATUS.PENDING;
        state.revenueErr = "";
      })
      .addCase(getRevenueChart.fulfilled, (state, action) => {
        state.revenueStatus = FETCH_STATUS.RESOLVED;
        state.revenue = action.payload;
        state.revenueErr = "";
      })
      .addCase(getRevenueChart.rejected, (state, action) => {
        state.revenueStatus = FETCH_STATUS.REJECTED;
        state.revenueErr = action.payload;
      })

      .addCase(getUsersChart.pending, (state) => {
        state.regUsersStatus = FETCH_STATUS.PENDING;
        state.regUsersErr = "";
      })
      .addCase(getUsersChart.fulfilled, (state, action) => {
        state.regUsersStatus = FETCH_STATUS.RESOLVED;
        state.regUsers = action.payload;
        state.regUsersErr = "";
      })
      .addCase(getUsersChart.rejected, (state, action) => {
        state.regUsersStatus = FETCH_STATUS.REJECTED;
        state.regUsersErr = action.payload;
      });
  },
});

export const adminReducer = adminSlice.reducer;
export const selectAdmin = (state) => state.admin;
