import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  createMigrate,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'

import {
  authReducer,
  adminReducer,
  instructorReducer,
  studentReducer,
  courseReducer,
  transactionReducer,
} from './slices'

const migrations = {
  3: (state) => {
    return {
      ...state,
    }
  },
}

const persistConfig = {
  key: 'root',
  storage,
  version: 3,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false }),
}

const syncConfig = {
  blacklist: [
    'flush/FLUSH',
    'rehydrate/REHYDRATE',
    'pause/PAUSE',
    'persist/PERSIST',
    'purge/PURGE',
    'register/REGISTER',
  ],
}

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  instructor: instructorReducer,
  student: studentReducer,
  course: courseReducer,
  transaction: transactionReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(createStateSyncMiddleware(syncConfig)),
})

initMessageListener(store)

export const persistor = persistStore(store)
