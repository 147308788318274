import {useEffect} from "react";
import {Success,Error} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useParams} from "react-router-dom";
import {getSingleCourse,selectCourse} from "../../redux/slices";
import defaultImage from "../../assets/images/defaultImage.png";


const CourseCard2 = () => {

    const { courseId } = useParams();
    const dispatch = useDispatch();
  
    const {
      singleCourse,
      singleCourseErr,
      singleCourseStatus,
    } = useSelector(selectCourse);
  

    useEffect(() => {
      dispatch(getSingleCourse(courseId));
    }, [dispatch, courseId]);
  
    const singleCourseRetry = () => {
      if (singleCourseErr) {
        dispatch(getSingleCourse(courseId));
      }
    };
  

    return (
        <>
                <Error
                  retry={singleCourseRetry}
                  err={singleCourseErr}
                  status={singleCourseStatus}
                />
                <Success status={singleCourseStatus}></Success>
        {singleCourse ? ( <div className="ml-[-40px] md:ml-[90px] lg:ml-[-40px] mt-[70px] lg:mt-[370px] mb-40 md:mb-48 w-[820px] md:w-[1200px]">
         <div className="p-10 ml-8 border rounded-md border-gray-400 h-fit w-[40%]">
         {singleCourse?.instructors?.map((instructor)=>{
             return (
                <div className="flex items-center mt-[-30px]">
                <img src= {instructor?.profileImageUrl || defaultImage}
                  className=" w-[4rem] h-[4rem] rounded-lg bg-no-repeat bg-contain bg-center bg-white" alt="Profile Pic"/>
                    <span className="ml-2 text-gray-400">Course: <span className="text-red-600">{instructor?.name}</span></span>
                </div>
             );
         })}
           <div className="mt-3">
           <p className="text-gray-400">Course Name</p>
           <p>{singleCourse?.name}</p>
           </div>
           <div className="mt-3">
           <p className="text-gray-400">Core Topics</p>
           {singleCourse?.categories?.map((category)=>{
            return <p>{category.name}</p>
           })}
           </div>
           <div>
           <p className="text-gray-400">Course Ratings</p>
           <p>{singleCourse?.ratings}</p>
           </div> 
           <div>
               <p className="mt-1 text-gray-400">About Course</p>
               <p>{singleCourse?.description}</p>
           </div>
           <div>
               <p className="mt-1 text-gray-400">Who is it for ?</p>
               <p>{singleCourse?.intendedUsers}</p>
           </div>
           <div className="mt-3">
           <p className="text-gray-400">Course Duration</p>
           <p>{Math.floor(singleCourse?.totalNumberOfSeconds % 3600/60)} minutes</p>
           </div>
         </div>    
        </div>
        ):(
                <p>Course Details Not Found</p>    
        )}
        </>
    )
}

export  {CourseCard2}
