import { useEffect } from "react";
import { Loading, Success, Error } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardStat, selectAdmin } from "../../redux/slices";

const CardDb = () => {
  const { dashboard, dashboardErr, dashboardStatus } = useSelector(selectAdmin);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardStat());
  }, [dispatch]);

  const dashboardRetry = () => {
    if (dashboardErr) {
      dispatch(getDashboardStat());
    }
  };

  return (
    <>
      <Loading status={dashboardStatus} />
      <Error
        retry={dashboardRetry}
        err={dashboardErr}
        status={dashboardStatus}
      />
      <Success status={dashboardStatus}>
        <div className="grid gap-10 md:gap-0 md:grid-cols-4 grid-cols-2 md:mr-12 md:ml-0">
          <div className="flex items-center border mb-2 bg-white rounded shadow-sm justify-between p-5 w-[70%] md:w-[80%] md:h-[80px]">
            <div>
              <div className="text-2xl font-medium text-gray-600">
                {dashboard.numberOfApprovedCourses}
              </div>
              <div className="text-sm text-gray-400 ">Approved Courses</div>
            </div>
          </div>

          <div className="flex items-center border -ml-8 md:ml-0 mb-2 bg-white rounded shadow-sm justify-between p-5 w-[70%] md:w-[80%] md:h-[80px]">
            <div>
              <div className="text-2xl font-medium text-gray-600">
                {dashboard.numberOfApprovedInstructors}
              </div>
              <div className="text-sm text-gray-400 ">Approved Instructors</div>
            </div>
          </div>
          <div className="flex items-center border mb-2 bg-white rounded shadow-sm justify-between p-5 w-[70%] md:w-[80%] md:h-[80px]">
            <div>
              <div className="text-2xl font-medium text-gray-600">
                {dashboard.totalNumberOfUsers}
              </div>
              <div className="text-sm text-gray-400 ">Total Students</div>
            </div>
          </div>
          <div className="flex items-center border -ml-8 md:ml-0 mb-2 bg-white rounded shadow-sm justify-between p-5 w-[70%] md:w-[80%] md:h-[80px]">
            <div>
              <div className="text-2xl font-medium text-gray-600">
                ₦{dashboard.totalAmountPaid}
              </div>
              <div className="text-sm text-gray-400 ">Total Revenue</div>
            </div>
          </div>
        </div>
      </Success>
    </>
  );
};

export { CardDb };
