import React from 'react'

const SearchFilter = ({filter,setFilter}) => {
  return (
    <div>
      <input
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
              className="w-3/4 h-6 m-6 p-4 outline-none rounded-2xl"
              placeholder="Search by Email..."
      />
    </div>
  );
}

export { SearchFilter }