import { useEffect } from "react";
import { Loading, Success, Error } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getStudentDashboardStat, selectStudent } from "../../redux/slices";

const CardDbStudent = () => {
  const { studentDashboard, studentDashboardErr, studentDashboardStatus } =
    useSelector(selectStudent);

  const dispatch = useDispatch();
  const { studentId } = useParams();

  useEffect(() => {
    dispatch(getStudentDashboardStat({ studentId, force: true }));
  }, [dispatch, studentId]);

  const studentRetry = () => {
    if (studentDashboardErr) {
      dispatch(getStudentDashboardStat(studentId));
    }
  };

  return (
    <div>
      <Loading status={studentDashboardStatus} />
      <Error
        retry={studentRetry}
        err={studentDashboardErr}
        status={studentDashboardStatus}
      />
      <Success status={studentDashboardStatus}>
        <div className="grid gap-0 md:grid-cols-3 grid-cols-2">
          <div className="flex items-center border md:ml-8 mb-6 md:mb-0 bg-white rounded shadow-sm justify-between p-5 w-[80%] md:w-[60%] lg:w-[80%] h-[80px]">
            <div>
              <div className="text-2xl font-medium text-gray-600">
                {studentDashboard.numberOfCoursesTaken}
              </div>
              <div className="text-sm text-gray-400 ">Course Taken</div>
            </div>
          </div>

          <div className="flex items-center border ml-[-15px] md:ml-6 bg-white rounded shadow-sm justify-between p-5 w-[80%] md:w-[60%] lg:w-[80%] h-[80px]">
            <div>
              <div className="text-2xl font-medium text-gray-600">
                ₦{studentDashboard.totalAmountPaid}
              </div>
              <div className="text-sm text-gray-400 ">Total Revenue</div>
            </div>
          </div>
          <div className="flex items-center border border-[#b61046] md:ml-6  bg-white rounded shadow-sm justify-between p-5 w-[90%] md:w-[60%] lg:w-[80%] h-[80px]">
            <div>
              <div className="text-2xl font-medium text-gray-600">
                {studentDashboard.numberOfReportsAgainstUser}
              </div>
              <div className="text-sm text-[#b61046] ">
                Number of Reports issued against user
              </div>
            </div>
          </div>
        </div>
      </Success>
    </div>
  );
};

export { CardDbStudent };
