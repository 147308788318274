import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";

const TransactionChart = ({ barKey1, barKey2, monthKey, data }) => {
  return (
    <ResponsiveContainer width="40%">
      <div className="lg:border md:rounded mt-[30px] ml-[-50px] md:ml-32 lg:mt-0 lg:ml-0 md:mr-10 md:mb-5">
        <div className="flex">
          <p className="font-bold ml-12 md:m-5">Transactions</p>
        </div>
        <BarChart
          width={400}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 50,
            bottom: 15,
          }}
        >
          <Legend layout="horizontal" align="left" />
          <XAxis dataKey={monthKey} />
          <YAxis />
          <Tooltip />
          <Bar dataKey={barKey1} stackId="a" fill="#d6064a" />
          <Bar dataKey={barKey2} stackId="a" fill="#ef4d41" />
        </BarChart>
      </div>
    </ResponsiveContainer>
  );
};

export { TransactionChart };
