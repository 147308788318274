import React from 'react';


const Dashboard = ( { children } ) =>
{
    return (
                    <section className='' >
                          { children }
                    </section>      
    );
};

export { Dashboard };
