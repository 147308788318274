import { useState, useEffect, useCallback } from "react";
import {
  NavBar,
  CardDbInstructor,
  CardInstructor,
  CardInstructor2,
  AppFooter,
  PrivateRoute,
  Loading,
  Pagination,
  Error,
  Success,
  Spinner,
} from "../../components";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRequest } from "../../hooks";
import {
  getSettlementByInstructor,
  getInstructorList,
  getSingleInstructor,
  getInstructorCourses,
  selectTransaction,
  selectInstructor,
} from "../../redux/slices";
import clamp from "lodash/clamp";
import { LeftOutlined } from "@ant-design/icons";
import { axiosApiInstance, API_URL_V1, formatDateTime } from "../../utils";

const InstructorDetailsPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [instructorIsActive, setInstructorIsActive] = useState(true);

  const toggle = () => {
    setShowModal((s) => !s);
  };

  const { status, setPending, setRejected } = useRequest();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instructorId } = useParams();
  const {
    page,
    totalPages,
    settlementByInstructor,
    settlementByInstructorErr,
    settlementByInstructorStatus,
    startDate,
    endDate,
  } = useSelector(selectTransaction);
  const {
    instructorList,
    singleInstructor,
    instructorCourses,
    instructorCoursesErr,
    instructorCoursesStatus,
  } = useSelector(selectInstructor);

  useEffect(() => {
    dispatch(getSingleInstructor(instructorId));
    dispatch(getInstructorList());
    dispatch(getSettlementByInstructor({ instructorId, force: true }));
    dispatch(getInstructorCourses({ instructorId, force: true }));
  }, [dispatch, instructorId]);

  useEffect(() => {
    if (instructorList?.length > 0) {
      const selectedInstructor = instructorList?.find((instructor) => {
        return instructor.id === instructorId;
      });
      if (!selectedInstructor) {
        setInstructorIsActive(false);
      }
    }
  }, [instructorId, instructorList]);

  const getList = useCallback(
    (instructorId, force = true, newPage = page) => {
      dispatch(
        getSettlementByInstructor({
          instructorId,
          force,
          page: newPage,
          start: startDate,
          end: endDate,
        })
      );
    },
    [dispatch]
  );

  const getCourses = useCallback(
    (instructorId, force = true) => {
      dispatch(getInstructorCourses({ instructorId, force }));
    },
    [dispatch]
  );

  const ToggleInstructor = async () => {
    setPending();

    try {
      await axiosApiInstance.patch(
        `${API_URL_V1}/Instructors/toggle-account?instructorId=${instructorId}`
      );
      toggle();
      dispatch(getInstructorList());
      navigate(-1);
    } catch (err) {
      setRejected(err);
    }
  };

  return (
    <PrivateRoute redirectTo="/instructors">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <div className="bg-[#eaeaea] h-[90px] w-full relative top-12 overflow-hidden "></div>
        <div className="w-full  relative left-10 top-[90px] ">
          <div className="grid grid-rows-2 grid-flow-col gap-4">
            <div className="md:row-start-1 md:ml-8">
              <button onClick={() => navigate(-1)}>
                <Link className="text-[#b61046] hover:text-black" to="">
                  <LeftOutlined style={{ fontSize: "12px" }} />
                  <span className="m-[12px]">Back</span>
                </Link>
              </button>
              <h2 className="font-semibold text-lg">
                {singleInstructor?.firstName} {singleInstructor?.lastName}
              </h2>
              <p className="text-sm text-gray-400">
                Approved since{" "}
                {formatDateTime(new Date(singleInstructor?.dateCreated))}{" "}
              </p>
            </div>
            <button
              onClick={toggle}
              className="row-start-1 ml-[-70px] md:ml-[265px] lg:ml-[635px] text-white mt-[20px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
              type="button"
              data-modal-toggle="popup-modal"
            >
              {instructorIsActive
                ? "Deactivate Instructor"
                : "Activate Instructor"}
            </button>
          </div>

          {/* Modal Starts Here */}
          {showModal ? (
            <div
              id="popup-modal"
              tabIndex="-1"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%]  m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  <button
                    onClick={toggle}
                    type="button"
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="popup-modal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="p-6 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-900 dark:text-gray-900">
                      Are you sure you want to deactivate this Instructor?
                    </h3>
                    <button
                      onClick={toggle}
                      data-modal-toggle="popup-modal"
                      type="button"
                      className="text-white md:mt-[-20px] ml-[20px] bg-[#B61046] md:w-[150px] h-[40px] rounded-md hover:bg-white px-2 hover:text-[#5f0c27]"
                    >
                      No, Cancel
                    </button>
                    <button
                      onClick={() => ToggleInstructor(instructorId)}
                      data-modal-toggle="popup-modal"
                      type="button"
                      className="ml-1 md:ml-3 text-[#5f0c27] border border-[#B61046] md:mt-[-20px] mt-[2px] bg-white px-2  md:w-[150px] h-[40px] rounded-md hover:bg-[#B61046] hover:text-white"
                    >
                      Yes, I am sure
                      <Spinner
                        className="!w-3  !h-3 ml-[120px] mt-[-15px] text-white fill-pink-400"
                        status={status}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* Modal Ends Here */}

          <div className="flex justify-between mt-[-60px] ">
            <CardDbInstructor />
          </div>
          <div className="grid grid-rows-1 lg:grid-cols-2 md:mb-20 gap-16">
            <Loading status={settlementByInstructorStatus} />
            <Error
              retry={getList}
              err={settlementByInstructorErr}
              status={settlementByInstructorStatus}
            />

            <Success status={settlementByInstructorStatus}>
              <CardInstructor data={settlementByInstructor} />
            </Success>
            <Success status={settlementByInstructor}>
              <div className="text-center mt-[-10px] md:mb-16">
                <Pagination
                  prev={() => getList(false, clamp(page - 1, 1, totalPages))}
                  next={() => getList(false, clamp(page + 1, 1, totalPages))}
                  goTo={(page) => getList(false, page)}
                  currentPage={page}
                  totalPages={totalPages}
                  isFirst={page <= 1}
                  isLast={page >= totalPages}
                />
              </div>
            </Success>

            <Loading status={instructorCoursesStatus} />
            <Error
              retry={getCourses}
              err={instructorCoursesErr}
              status={instructorCoursesStatus}
            />

            <Success status={instructorCoursesStatus}>
              <CardInstructor2 data={instructorCourses} />
            </Success>
          </div>
        </div>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { InstructorDetailsPage };
