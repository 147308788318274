import format from "date-fns/format";
import formatDuration from "date-fns/formatDuration";

const formatDateTime = (date, dateFormat = "yyyy/LL/dd") => {
  if (!date) {
    return "N/A";
  }

  try {
    return format(new Date(date), dateFormat);

  } catch {
    return "N/A"

  }

};

const formatToISOWithTZ = (date) => {
  if (!date) {
    throw new Error("Please pass in a valid date object");
  }

  const isoDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();

  return isoDateTime;
};

export { formatDateTime, formatDuration, formatToISOWithTZ };
