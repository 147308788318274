import { useState, useEffect } from 'react'
import {
  NavBar,
  CourseCard,
  CourseCardApproval,
  AppFooter,
  PrivateRoute,
  Spinner,
} from '../../components'
import { Link } from 'react-router-dom'
import { EyeOutlined, LeftOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { useRequest } from '../../hooks'
import {
  getSingleCourse,
  getApprovedCourseList,
  selectCourse,
} from '../../redux/slices'
import { axiosApiInstance, API_URL_V1 } from '../../utils'
import defaultImage from '../../assets/images/defaultImage.png'

const CourseDetailPage = () => {
  const [showModal1, setShowModal1] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [showImage, setShowImage] = useState(false)

  const toggleVideo = () => {
    setShowVideo((s) => !s)
  }
  const toggleImage = () => {
    setShowImage((s) => !s)
  }

  const toggle1 = () => {
    setShowModal1((s) => !s)
  }

  const toggle2 = () => {
    setShowModal2((s) => !s)
  }

  const { courseId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { status, setPending, setRejected } = useRequest()

  const { singleCourse } = useSelector(selectCourse)

  useEffect(() => {
    dispatch(getSingleCourse(courseId))
  }, [dispatch, courseId])

  const ApproveCourse = async (courseId) => {
    setPending()

    try {
      await axiosApiInstance.patch(
        `${API_URL_V1}/Courses/approve-course?courseId=${courseId}`
      )
      dispatch(getApprovedCourseList(true))
      navigate('/pendingCourses')
    } catch (err) {
      setRejected(err)
    }
  }

  const DeclineCourse = async (courseId) => {
    setPending()

    try {
      await axiosApiInstance.patch(
        `${API_URL_V1}/Courses/disapprove-course?courseId=${courseId}`
      )
      dispatch(getApprovedCourseList(true))
      navigate('/pendingCourses')
    } catch (err) {
      setRejected(err)
    }
  }

  return (
    <PrivateRoute redirectTo="/pendingCourses">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <div className="bg-[#eaeaea] h-[90px] w-full relative top-12 overflow-hidden ">
          <div className="flex font-semibold ml-5 mt-[40px]">
            <span className="md:ml-10">
              Awaiting Approvals
              <hr className="w-[90px] mt-6 h-[2px] ml-4 bg-[#b61046]" />
            </span>
            <span className="ml-10">
              <Link className="text-black hover:text-[#b61046]" to="/courses">
                Courses
              </Link>
            </span>
            <span className="ml-10">
              <Link className="text-black hover:text-[#b61046]" to="/pricing">
                Course Pricing
              </Link>
            </span>
          </div>
        </div>
        <div className="w-full h-full relative left-10 top-[90px] ">
          <div className="grid grid-rows-2 grid-flow-col gap-4">
            <div className="row-start-1 md:ml-24">
              <p className="md:ml-[-56px]">
                <Link
                  className="text-[#b61046] hover:text-black"
                  to="/pendingCourses"
                >
                  <LeftOutlined style={{ fontSize: '12px' }} />
                  <span className="m-[12px]">Back</span>
                </Link>
              </p>
              <h2 className="font-semibold text-lg w-3/4 md:w-1/4">
                {singleCourse?.name}
              </h2>
              {/* <p className="text-gray-400 text-xs">Created 20th June, 2022</p> */}
            </div>
            <div className="flex mt-[-20px] md:mt-[-120px]">
              <button
                type="button"
                data-modal-toggle="defaultModal"
                onClick={toggle1}
                className="md:ml-[775px] text-white mt-[20px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:border hover:border-[#B61046] hover:bg-white hover:text-[#5f0c27]"
              >
                Approve Course
              </button>
              <button
                type="button"
                data-modal-toggle="defaultModal1"
                onClick={toggle2}
                className="ml-8 md:ml-3 text-[#5f0c27] border border-[#B61046] mt-[20px] bg-white w-[150px] h-[40px] rounded-md hover:bg-[#B61046] hover:text-white"
              >
                Decline
              </button>
            </div>
          </div>

          {/* Approve modal  */}
          {showModal1 ? (
            <div
              id="defaultModal"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%]  m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* Modal header  */}
                  <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <p className="mb-3.5 text-lg font-semibold text-center">
                      Approve Course?
                    </p>
                    <button
                      onClick={toggle1}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body  */}
                  <div className="p-6 space-y-6">
                    <p className="mb-3.5 text-sm ">
                      By approving this Course, this means you have completely
                      vetted and deem it fit to be on the Certifications by
                      Unify platform. Approve?
                    </p>
                  </div>
                  {/* Modal footer */}
                  <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button
                      onClick={toggle1}
                      data-modal-toggle="defaultModal"
                      type="button"
                      className="text-white md:mt-[-20px] ml-14 md:ml-[270px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
                    >
                      No, Cancel
                    </button>
                    <button
                      onClick={() => ApproveCourse(courseId)}
                      data-modal-toggle="defaultModal"
                      type="button"
                      className="ml-1 md:ml-3 text-[#5f0c27] border border-[#B61046] md:mt-[-20px] mt-[2px] bg-white w-[150px] h-[40px] rounded-md hover:bg-[#B61046] hover:text-white"
                    >
                      Yes, Approve
                      <Spinner
                        className="!w-3  !h-3 ml-[120px] mt-[-15px] text-white fill-pink-400"
                        status={status}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Decline modal  */}

          {showModal2 ? (
            <div
              id="defaultModal1"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* <Modal header 2 */}
                  <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <p className="mb-3.5 text-lg font-semibold text-center">
                      Decline Course ?
                    </p>
                    <button
                      onClick={toggle2}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal1"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body 2 */}
                  <div className="p-6 space-y-6">
                    <p className="mb-3.5 text-sm">
                      Are you sure you want to decline this Course ? Declining
                      means this course won't be on the Certifications by Unify
                      platform.
                    </p>
                  </div>
                  {/* Modal footer */}
                  <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button
                      onClick={toggle2}
                      data-modal-toggle="defaultModal1"
                      type="button"
                      className="text-white md:mt-[-20px] ml-14 md:ml-[270px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
                    >
                      No, Cancel
                    </button>
                    <button
                      onClick={() => DeclineCourse(courseId)}
                      data-modal-toggle="defaultModal1"
                      type="button"
                      className="ml-1 md:ml-3 text-[#5f0c27] border border-[#B61046] md:mt-[-20px] mt-[2px] bg-white w-[150px] h-[40px] rounded-md hover:bg-[#B61046] hover:text-white"
                    >
                      Yes, Decline
                      <Spinner
                        className="!w-3  !h-3 ml-[120px] mt-[-15px] text-white fill-pink-400"
                        status={status}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="flex mt-[-20px] md:mt-[-120px] items-center">
            <img
              className=" w-[3rem] h-[3rem] md:ml-8 md:mr-4 mr-2 rounded-lg bg-no-repeat bg-contain bg-center  bg-white "
              src={singleCourse?.imageUrl || defaultImage}
              alt="profile"
            />
            <EyeOutlined style={{ marginTop: '10px' }} />{' '}
            <span className="md:ml-1">
              <button
                type="button"
                data-modal-toggle="defaultModal3"
                onClick={toggleVideo}
                className=" text-[#B61046] mt-[10px]"
              >
                Watch Preview Video
              </button>
            </span>
            <EyeOutlined style={{ marginTop: '10px', marginLeft: '10px' }} />
            <span className="md:ml-1">
              <button
                type="button"
                data-modal-toggle="defaultModal4"
                onClick={toggleImage}
                className="mt-[10px] text-[#B61046]"
              >
                View Course Image
              </button>
            </span>
          </div>

          {/* Video Modal */}
          {showVideo ? (
            <div
              id="defaultModal3"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* Modal header  */}
                  <div className="text-end">
                    <button
                      onClick={toggleVideo}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal3"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body  */}
                  <div>
                    <ReactPlayer
                      width=""
                      controls
                      url={singleCourse.previewVideoUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Image Modal */}
          {showImage ? (
            <div
              id="defaultModal4"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* Modal header  */}
                  <div className="text-end">
                    <button
                      onClick={toggleImage}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal4"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body  */}
                  <div>
                    <img
                      className="w-full h-[500px]"
                      src={singleCourse?.imageUrl || defaultImage}
                      alt="courseImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="block lg:flex ">
            <div className="w-[100%] lg:w-[50%] ">
              <CourseCard />
            </div>
            <div className="w-[100%] lg:w-[50%] ">
              <CourseCardApproval />
            </div>
          </div>
        </div>
        <AppFooter />
      </div>
    </PrivateRoute>
  )
}

export { CourseDetailPage }
