import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_URL_V1,
  axiosApiInstanceGet,
  FETCH_STATUS,
} from "../../utils";


export const getInstructorList = createAsyncThunk(
  "getInstructorList",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Instructors/list-all-instructors-by-status?status=true&page=${page}&perPage=${pageSize}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


export const getDeactivatedInstructorsList = createAsyncThunk(
  "getDeactivatedInstructorsList",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Instructors/list-all-instructors-by-status?status=false&page=${page}&perPage=${pageSize}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getApprovedList = createAsyncThunk(
  "getApprovedList",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Instructors/list-instructors-awaiting-approval?page=${page}&perPage=${pageSize}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSingleInstructor = createAsyncThunk(
  "getSingleInstructor",
  async (userId ,{ fulfillWithValue, rejectWithValue }) => {
    try {
        let force = true;
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Instructors/get-instructor-by-id-restricted?instructorId=${userId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


export const getInstructorDashboardStat = createAsyncThunk(
  "getInstructorDashboardStat",
  async ({force,instructorId},{ fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Analytics/get-instructor-stats?instructorId=${instructorId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getInstructorCourses = createAsyncThunk(
  "getInstructorCourses",
  async ({force,instructorId},{ fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Analytics/get-course-and-subscriber-stats?instructorId=${instructorId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const pageSize = 10;
const initialState = {
  

  page:0,
  totalPages:1,
  instructorList:[],
  instructorListErr:"",
  instructorListStatus:FETCH_STATUS.IDLE,
  approvedList:[],
  approvedListErr:"",
  aprrovedListStatus:FETCH_STATUS.IDLE,
  singleInstructor:{},
  singleInstructorErr:"",
  singleInstructorStatus:FETCH_STATUS.IDLE,
  deactivatedInstructor:[], 
  deactivatedInstructorErr:"", 
  deactivatedInstructorStatus:FETCH_STATUS.IDLE,
  instructorDashboard: {},
  instructorDashboardErr: "",
  instructorDashboardStatus: FETCH_STATUS.IDLE,
  instructorCourses:[],
  instructorCoursesErr:"",
  instructorCoursesStatus:FETCH_STATUS.IDLE,
};

export const instructorSlice = createSlice({
  name: "instructor",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInstructorList.pending, (state) => {
        state.instructorListStatus = FETCH_STATUS.PENDING;
        state.instructorListErr = "";
      })
      .addCase(getInstructorList.fulfilled, (state, action) => {
        state.instructorListStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.instructorList = pagedList;
        state.instructorListErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getInstructorList.rejected, (state, action) => {
        state.instructorListStatus = FETCH_STATUS.REJECTED;
        state.instructorListErr = action.payload;
      })
      .addCase(getApprovedList.pending, (state) => {
        state.approvedListStatus = FETCH_STATUS.PENDING;
        state.approvedListErr = "";
      })
      .addCase(getApprovedList.fulfilled, (state, action) => {
        state.approvedListStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.approvedList = pagedList;
        state.approvedListErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getApprovedList.rejected, (state, action) => {
        state.approvedListStatus = FETCH_STATUS.REJECTED;
        state.approvedListErr = action.payload;
      })
      .addCase(getSingleInstructor.pending, (state) => {
        state.singleInstructorStatus = FETCH_STATUS.PENDING;
        state.singleInstructorErr = "";
      })
      .addCase(getSingleInstructor.fulfilled, (state, action) => {
        state.singleInstructorStatus = FETCH_STATUS.RESOLVED;
        state.singleInstructor = action.payload;
        state.singleInstructorErr = "";
      })
      .addCase(getSingleInstructor.rejected, (state, action) => {
        state.singleInstructorStatus = FETCH_STATUS.REJECTED;
        state.singleInstructorErr = action.payload;
      })
      .addCase(getDeactivatedInstructorsList.pending, (state) => {
        state.deactivatedInstructorStatus = FETCH_STATUS.PENDING;
        state.deactivatedInstructorErr = "";
      })
      .addCase(getDeactivatedInstructorsList.fulfilled, (state, action) => {
        state.deactivatedInstructorStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.deactivatedInstructor = pagedList;
        state.deactivatedInstructorErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getDeactivatedInstructorsList.rejected, (state, action) => {
        state.deactivatedInstructorStatus = FETCH_STATUS.REJECTED;
        state.deactivatedInstructorErr = action.payload;
      })
      .addCase(getInstructorDashboardStat.pending, (state) => {
        state.instructorDashboardStatus = FETCH_STATUS.PENDING;
        state.instructorDashboardErr = "";
      })
      .addCase(getInstructorDashboardStat.fulfilled, (state, action) => {
        state.instructorDashboardStatus = FETCH_STATUS.RESOLVED;
        state.instructorDashboard = action.payload;
        state.instructorDashboardErr = "";
      })
      .addCase(getInstructorDashboardStat.rejected, (state, action) => {
        state.instructorDashboardStatus = FETCH_STATUS.REJECTED;
        state.instructorDashboardErr = action.payload;
      })
      .addCase(getInstructorCourses.pending, (state) => {
        state.instructorCoursesStatus = FETCH_STATUS.PENDING;
        state.instructorCoursesErr = "";
      })
      .addCase(getInstructorCourses.fulfilled, (state, action) => {
        state.instructorCoursesStatus = FETCH_STATUS.RESOLVED;
        state.instructorCourses = action.payload;
        state.instructorCoursesErr = "";
      })
      .addCase(getInstructorCourses.rejected, (state, action) => {
        state.instructorCoursesStatus = FETCH_STATUS.REJECTED;
        state.instructorCoursesErr = action.payload;
      });
  },
});

export const instructorReducer = instructorSlice.reducer;
export const selectInstructor = (state) => state.instructor;
