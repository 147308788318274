import React from "react";
import { useTable, useSortBy } from "react-table";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "../UI/ResponsiveTable.module.css";
import { EyeOutlined } from "@ant-design/icons";
import { formatDateTime } from "../../utils";
import { ToggleOff, ToggleOn } from "../Icons";

const DiscountTable = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "S/N",
        Cell: (cell) => {
          return cell.row.index + 1;
        },
      },
      {
        Header: "Discount Code",
        accessor: "code",
        Cell: (props) => {
          const code = props?.value;
          return <span className="rounded bg-gray-400 px-2 py-1">{code}</span>;
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiryDate",
        Cell: (props) => {
          return (
            formatDateTime(new Date(props?.value), "LLLL do, yyyy") || "N/A"
          );
        },
      },

      {
        Header: "Discount Type",
        Cell: ({ cell }) => {
          const type = cell?.row?.original?.discountType;
          const discount = cell?.row?.original?.value;
          return type === "DiscountByPercentage" ? (
            <span className="px-2 py-1 bg-[#fbe4d6] text-[#f86205]">
              {discount + "% off"}
            </span>
          ) : (
            <span className="px-2 py-1 bg-[#fbcedd] text-[#b61046]">
              {"₦" + discount + " off"}
            </span>
          );
        },
      },
      {
        Header: "Redemption",
        accessor: "numberOfUses",
      },

      {
        Header: "Status",
        accessor: "isActive",
        Cell: (props) => {
          const status = props?.value;
          return status === true ? (
            <div className="flex items-center justify-end lg:inline">
              <ToggleOn />
              <span className="text-[#898989]">Active</span>
            </div>
          ) : (
            <div className="flex items-center justify-end lg:inline">
              <ToggleOff />
              <span className="text-[#898989]">Inactive</span>
            </div>
          );
        },
      },
      {
        Header: "Action",
        Cell: ({ cell }) => {
          const item = cell?.row?.original;
          return (
            <div className="flex items-center justify-end lg:justify-center">
              <Link className=" text-black" to={`/discount-code/${item.id}`}>
                <EyeOutlined />
                <span className="text-[#c43d68]">View</span>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <div className="relative mt-4 mb-12 shadow-md sm:rounded-sm md:overflow-hidden ">
      <div className="bg-[#eaeaea] h-[90px] w-full relative top-4 md:top-12 md:overflow-hidden ">
        <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
          <span className="ml-10 mt-10 md:mt-0">
            <Link
              className="text-black hover:text-[#b61046]"
              to="/transactions"
            >
              Transactions{" "}
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">
            <Link className="text-black hover:text-[#b61046]" to="/settlements">
              Settlements
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">Discounts</span>
          <hr className="w-[70px] mt-16 md:mt-11 h-[2px] ml-[-70px] bg-[#b61046]" />
        </div>
      </div>

      <div className="flex items-center justify-between my-24 lg:my-12 px-12 ">
        <div className="row-start-1">
          <h2 className="font-semibold text-lg"> All Discount Codes</h2>
        </div>
        <Link to="/create-discount-code">
          <button className=" text-white mt-[20px] bg-[#B61046] py-2 px-4 md:h-[40px] h-[55px] rounded-md hover:bg-white hover:text-[#5f0c27]">
            Create New Discount Codes
          </button>
        </Link>
      </div>

      <table
        {...getTableProps()}
        className={classNames(
          "w-[90%] lg:w-[1200px] m-auto mt-[-70px] md:mt-[-30px] text-sm text-left text-gray-500",
          styles.table
        )}
      >
        <thead className="text-xs text-gray-400 capitalize bg-gray-50">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column) => (
                <th {...column.getHeaderProps()} className="px-6 py-3">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="bg-white rounded-2xl shadow-md border p-4"
              >
                {row?.cells?.map((cell) => {
                  return (
                    <td
                      data-label={cell?.column?.Header}
                      {...cell.getCellProps()}
                      className="px-6 py-4 text-gray-900 min-w-min "
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export { DiscountTable };
