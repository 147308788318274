import { clamp } from "lodash";
import React, { useState } from "react";
import { cleanNumberString } from "../../utils";

const Pagination = ({
  next,
  prev,
  isFirst,
  isLast,
  currentPage = 0,
  totalPages = 1,
  goTo,
}) => {
  const [value, setValue] = useState("");

  const go = (e) => {
    e.preventDefault();

    const cleanedAndClampedValue = parseInt(
      clamp(cleanNumberString(value), 0, totalPages)
    );

    goTo(cleanedAndClampedValue);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="bg-[#eaeaea] w-full flex flex-wrap items-center justify-center space-x-2">
      <p className="my-1 font-bold">
        Showing page {currentPage} of {totalPages} Pages
      </p>

      <button
        onClick={prev}
        disabled={isFirst}
        className="inline-block px-4 py-1 my-1 text-sm font-medium rounded-lg bg-white hover:bg-red-800 text-black  disabled:cursor-not-allowed disabled:opacity-70 "
      >
        {"<<"}
      </button>
      <button
        onClick={next}
        className="inline-block px-4 py-1 my-1 text-sm font-medium rounded-lg bg-white hover:bg-red-800 text-black disabled:cursor-not-allowed disabled:opacity-70 "
        disabled={isLast}
      >
        {">>"}
      </button>

      <form
        className="flex flex-wrap items-center justify-center space-x-2"
        onSubmit={go}
      >
        <p className="ml-5 mt-2 font-bold">Jump to page</p>

        <input
          className="px-2 w-20 py-0.5 border rounded bg-white border-red-800 outline-none"
          type="number"
          value={value}
          onChange={onChange}
        />
        <button
          className="p-2 py-0.5 font-medium text-white rounded bg-red-800"
          type="submit"
        >
          Go
        </button>
      </form>
    </div>
  );
};

export { Pagination };
