import React from "react";
import { useTable } from "react-table";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "../UI/ResponsiveTable.module.css";
import defaultImage from "../../assets/images/defaultImage.png";
import { formatNumber, formatDateTime } from "../../utils";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSettlement,
  selectTransaction,
  setEndTransaction,
  setStartTransaction,
} from "../../redux/slices";
import { Spinner } from "../UI";

const SettlementTable = ({ data }) => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(selectTransaction);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(getAllSettlement({ force: true, start: startDate, end: endDate }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Status",
        accessor: "transactionStatus",
      },
      {
        Header: "User's Profile",
        accessor: "userSummary",
        Cell: ({ value }) => {
          return (
            <div className="flex items-center">
              <div
                key={value.id}
                className="ml-[50px] phone-md:ml-[110px] md:ml-[400px] lg:ml-0 w-[2rem] h-[2rem] rounded-2xl bg-no-repeat bg-contain bg-center bg-white "
                style={{
                  backgroundImage: `url("${
                    value?.profilePictureUrl || defaultImage
                  }")`,
                }}
              ></div>
              <p className="ml-2">{value?.name}</p>
            </div>
          );
        },
      },
      {
        Header: "Category",
        accessor: "transactionCategory",
      },
      {
        Header: "Role",
        accessor: "roleName",
      },
      {
        Header: "Amount(₦)",
        accessor: "amount",
        Cell: (props) => {
          return formatNumber(props?.value) || "N/A";
        },
      },
      {
        Header: "Date",
        accessor: "dateCompleted",
        Cell: (props) => {
          return (
            formatDateTime(new Date(props?.value), "LLLL do, yyyy. hh:mm a") ||
            "N/A"
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="relative mt-4 mb-12 shadow-md sm:rounded-sm md:overflow-hidden ">
      <div className="bg-[#eaeaea] h-[90px] w-full relative top-4 md:top-12 md:overflow-hidden ">
        <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
          <span className="ml-10 mt-10 md:mt-0">
            <Link
              className="text-black hover:text-[#b61046]"
              to="/transactions"
            >
              Transactions{" "}
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">Settlements</span>
          <hr className="w-[70px] mt-16 md:mt-11 h-[2px] ml-[-70px] bg-[#b61046]" />

          <span className="ml-10 mt-10 md:mt-0">
            <Link
              className="text-black hover:text-[#b61046]"
              to="/discount-code"
            >
              Discounts
            </Link>
          </span>
        </div>
      </div>

      <div className="md:ml-[400px] lg:ml-[720px] md:mt-[70px] md:w-1/2">
        <form
          onSubmit={onSubmit}
          className="flex flex-wrap items-center md:px-2 pt-6 ml-4 md:-mx-6 md:space-x-2"
        >
          <div className="my-2 border border-red-800 rounded-lg p-2 w-1/4 ">
            <DatePicker
              placeholderText="Start Date"
              selected={new Date(startDate)}
              onChange={(value) =>
                dispatch(setStartTransaction(value.toISOString()))
              }
              className="w-full outline-none"
            />
          </div>

          <div className="my-2 ml-2 border border-red-800 rounded-lg p-2 w-1/4 ">
            <DatePicker
              placeholderText="End Date"
              selected={new Date(endDate)}
              onChange={(value) =>
                dispatch(setEndTransaction(value.toISOString()))
              }
              className="w-full outline-none"
            />
          </div>

          <button
            className="px-4 py-2 ml-4 my-2 rounded-lg text-white bg-[#b61046]"
            type="submit"
          >
            Fetch Settlements
            <Spinner />
          </button>
        </form>
      </div>

      <div className="grid grid-rows-2 grid-flow-col gap-4 mt-14 ml-6">
        <div className="row-start-1">
          <h2 className="font-semibold text-lg  mt-12 md:mt-5 ml-3 md:ml-12">
            Settlements
          </h2>
        </div>
      </div>

      <table
        {...getTableProps()}
        className={classNames(
          "w-[90%] lg:w-[1200px] m-auto mt-[-70px] md:mt-[-30px] text-sm text-left text-gray-500",
          styles.table
        )}
      >
        <thead className="text-xs text-gray-400 capitalize bg-gray-50">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column) => (
                <th {...column.getHeaderProps()} className="px-6 py-3">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="bg-white rounded-2xl shadow-md border p-4"
              >
                {row?.cells?.map((cell) => {
                  return (
                    <td
                      data-label={cell?.column?.Header}
                      {...cell.getCellProps()}
                      className="px-6 py-4 text-gray-900 min-w-min "
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length < 1 && <p className="text-center">No Settlements Found</p>}
    </div>
  );
};

export { SettlementTable };
