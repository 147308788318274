export const employerList = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    email: 'john@example.com',
    person: 'John Doe',
    phone: '+123456789',
    dateCreated: '2023-09-15',
    profilePictureUrl: 'https://example.com/johndoe.jpg',
  },
  {
    id: 2,
    firstName: 'Jane',
    lastName: 'Smith',
    email: 'jane@example.com',
    person: 'Jane Smith',
    phone: '+987654321',
    dateCreated: '2023-09-10',
    profilePictureUrl: '',
  },
]
