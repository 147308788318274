import React, { useRef } from "react";
import styled from "styled-components";
import { Statistic } from "antd";
import { useLogout } from "../../hooks";
import { FadeInOut } from "../FadeInOut";

const { Countdown } = Statistic;

const Timer = styled(Countdown)`
  display: inline;

  .ant-statistic-content {
    display: inline;
  }

  .ant-statistic-content-value {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
  }
`;

const twoMins = 1000 * 60 * 2;

const IdleWarning = ({ show }) => {
  const deadLine = useRef(new Date().getTime() + twoMins);
  const logout = useLogout();

  if (!show) {
    return <></>;
  }

  return (
    <FadeInOut show={show}>
      <div
        id="toast-warning"
        className="fixed flex items-center w-full max-w-screen-phone-xs phone-sm:max-w-screen-phone-xs phone-lg:max-w-screen-phone-md  p-4 text-gray-500 bg-white rounded-lg shadow pointer-events-auto top-2 right-2 "
        role="alert"
      >
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg ">
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="ml-3 text-sm font-normal">
          <div>
            <p className="inline">
              You've been idle for a while, automatic logout in{" "}
            </p>{" "}
            <Timer format=" mm:ss" value={deadLine.current} onFinish={logout} />
          </div>
        </div>
      </div>
    </FadeInOut>
  );
};

export { IdleWarning };
