import React from 'react';
import { useTable } from "react-table";
import classNames from "classnames";
import styles from "../UI/ResponsiveTable.module.css";

const CardInstructor2 = ({data}) => {
      const columns = React.useMemo(
        () => [
          {
            Header: "Course Title",
            accessor: "courseTitle",
          },
          {
            Header: "Students",
            accessor: "numberOfSubscribers",
          },
          {
            Header: "Total",
            accessor: "totalAmountPaid",
          },
        ],
        []
      );
    
    
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data});

        
    return (
        <div className="md:flex relative md:top-[65px] left-[-20px] md:left-[330px]">
        {/* Second Table */}
        <div className="lg:border rounded md:ml-[-270px] mb-[218px] md:w-3/4 w-full">
            <div className="flex ">
		  <p className="font-bold m-5">Courses</p>
		  </div>
          <div >
      <table
        {...getTableProps()}
        className={classNames(
          "w-[85%] lg:w-full text-sm text-left text-gray-500",
          styles.table
        )}
      >
        <thead className="text-xs capitalize text-gray-400 bg-gray-50">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column) => (
                <th {...column.getHeaderProps()} className="px-6 py-3">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="bg-white rounded-2xl shadow-md border p-4">
                {row?.cells?.map((cell) => {
                  return (
                    <td
                      data-label={cell?.column?.Header}
                      {...cell.getCellProps()}
                      className="px-6 py-4 text-xs text-gray-900 min-w-min "
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
        </div>
        </div>
    )
}

export {CardInstructor2}
