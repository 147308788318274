import React, {useEffect} from 'react';
import defaultLogo from "../../assets/images/unifyLogo.png";
import {useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRequest } from "../../hooks/useRequest";
import { Error, Spinner } from "../../components";
import {toast,ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import {useNavigate} from "react-router-dom";
import {
  reqPwdResetInit,
  reqPwdResetSchema,
  axiosApiInstance,
  API_URL_V1,
  refreshTokenKey,
  tokenKey,
} from "../../utils";



function ForgotPassword ()
{

  const { err, status, setPending, setRejected,setResolved } = useRequest();
  const navigate = useNavigate();


const notify =()=>{
  toast.success('A reset link has been sent to your inbox',{
    autoClose:12000,
  })
}

useEffect(() => {
  const token = localStorage.getItem(tokenKey);
  const refreshToken = localStorage.getItem(refreshTokenKey);

  if (token && refreshToken) {
    navigate("/dashboard")
  }
}, [navigate]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: reqPwdResetInit,
    resolver: yupResolver(reqPwdResetSchema),
  });

  const onReset = async ({email}) => {
    setPending();
    try {
      await axiosApiInstance.post(`${API_URL_V1}/Auth/request-password-reset?email=${email}`,);
      reset();
      setResolved();
    } catch (error) {
      setRejected(error);
    }
  };

 
  return (
    <div className='w-full h-screen overflow-hidden bg-black'>
      <img src={defaultLogo} alt="Logo" className="ml-3 md:ml-[50px] mt-4" />
      <div>
        <div className='flex flex-wrap justify-center'>
          <div className='w-full mt-[-40px] max-w-sm'>
          <Error err={err} status={status} />
             <form className="px-8 pt-8 pb-8 mt-40 mb-4 bg-white rounded shadow-md mx-2" onSubmit={handleSubmit(onReset)}>
            <div className="mb-2 text-lg">
                  Forgotten PassWord ?
                </div>
              <div className='mb-6 text-sm font-medium '>Please enter your e-mail address</div>
              <div className='mb-5'>
                <label htmlFor={`email`} className='block mb-2 text-sm font-medium text-gray-400'>
                  Email
                </label>
                <input
                 id={`email`}
                 {...register("email")}
                  type="email"
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  placeholder="admin@mail.com"
                />
                     <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                        {errors?.email?.message}
                      </output>             
                       </div>
                       <div className="mt-5">
                  <button
                    type="submit"
                    onClick={notify}
                    className="relative flex items-center justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md group hover:bg-[#88062f] bg-[#b61046] focus:outline-none focus:ring-2 focus:ring-offset-2 "
                  >
                   Submit
                  <Spinner
                  className="!w-3  !h-3 mx-1 text-white fill-pink-400"
                  status={status}
                />
                  </button>
                  <ToastContainer/>
             </div>
            </form>
          </div>
        </div>
      </div>

    </div>

  );
}

export { ForgotPassword };
