import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  NavBar,
  DeactivatedTable,
  AppFooter,
  Loading,
  Error,
  Success,
  Pagination,
  PrivateRoute,
} from '../../components'
import { selectStudent, getDeactivatedStudentList } from '../../redux/slices'
import clamp from 'lodash/clamp'

const DeactivatedPage = () => {
  const dispatch = useDispatch()
  const {
    page,
    totalPages,
    deactivatedStudent,
    deactivatedStudentErr,
    deactivatedStudentStatus,
  } = useSelector(selectStudent)

  useEffect(() => {
    dispatch(getDeactivatedStudentList())
  }, [dispatch])

  const getList = useCallback(
    (force = true, page) => {
      dispatch(getDeactivatedStudentList({ force, page }))
    },
    [dispatch]
  )

  return (
    <PrivateRoute redirectTo="/deactivatedStudents">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <section>
          <Loading status={deactivatedStudentStatus} />
          <Error
            retry={getList}
            err={deactivatedStudentErr}
            status={deactivatedStudentStatus}
          />
          <Success status={deactivatedStudentStatus}>
            <DeactivatedTable data={deactivatedStudent} />
          </Success>
          <Success status={deactivatedStudentStatus}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() => getList(false, clamp(page - 1, 1, totalPages))}
                next={() => getList(false, clamp(page + 1, 1, totalPages))}
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
        </section>
        <AppFooter />
      </div>
    </PrivateRoute>
  )
}

export { DeactivatedPage }
