import React, { useState, useMemo } from 'react'
import { useTable } from 'react-table'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { PrivateRoute, SearchFilter } from '../../components/UI'
import styles from '../../components/UI/ResponsiveTable.module.css'
import { EyeOutlined } from '@ant-design/icons'
import { formatDateTime } from '../../utils'
import defaultImage from '../../assets/images/defaultImage.png'
import { AppFooter, NavBar } from '../../components'

const Jobs = ({ data = [] }) => {
  //   const [globalFilter, setGlobalFilter] = useState('')

  // Mock data for statistics
  const stats = {
    totalJobs: 150,
    totalApplicants: 1200,
    randomStat: 42,
  }

  // Mock data for job title table
  const jobTitleData = [
    {
      id: 1,
      title: 'Software Engineer',
      company: 'Tech Co',
      dateCreated: '2024-09-15',
      applicants: 25,
      status: 'Active',
    },
    {
      id: 2,
      title: 'Marketing Manager',
      company: 'Ad Agency',
      dateCreated: '2024-09-14',
      applicants: 18,
      status: 'Active',
    },
    {
      id: 3,
      title: 'Data Analyst',
      company: 'Data Corp',
      dateCreated: '2024-09-13',
      applicants: 30,
      status: 'Archived',
    },
    {
      id: 4,
      title: 'UX Designer',
      company: 'Design Studio',
      dateCreated: '2024-09-12',
      applicants: 22,
      status: 'Active',
    },
    {
      id: 5,
      title: 'Sales Representative',
      company: 'Sales Inc',
      dateCreated: '2024-09-11',
      applicants: 15,
      status: 'Archived',
    },
  ]

  const columns = useMemo(
    () => [
      {
        Header: "Company's Name",
        accessor: 'profile',
        Cell: ({ cell }) => {
          const profileImage = cell?.row?.original?.profilePictureUrl
          return (
            <div className="flex items-center">
              <div
                key={profileImage}
                className="ml-[50px] phone-md:ml-[110px] md:ml-96 lg:ml-0 w-[2rem] h-[2rem] rounded-2xl bg-no-repeat bg-contain bg-center bg-white"
                style={{
                  backgroundImage: `url("${profileImage || defaultImage}")`,
                }}
              ></div>
              <p className="ml-2">
                {cell?.row?.original?.firstName +
                  ' ' +
                  cell?.row?.original?.lastName}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Company Email',
        accessor: 'email',
      },
      {
        Header: 'Contact Person',
        accessor: 'person',
      },
      {
        Header: 'Contact Phone',
        accessor: 'phone',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
        Cell: ({ value }) =>
          value
            ? formatDateTime(new Date(value), 'LLLL do, yyyy. hh:mm a')
            : 'N/A',
      },
      {
        Header: 'Action',
        Cell: ({ row }) => {
          const studentId = row.original?.id
          return (
            <div className="lg:flex items-center">
              <Link
                className="m-1 md:ml-96 lg:m-1 text-black"
                to={`/students/${studentId}`}
              >
                <EyeOutlined />
                <span className="phone-xs:ml-[180px] phone-md:ml-[200px] md:ml-[500px] lg:m-3 text-[#c43d68]">
                  View
                </span>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const jobTitleColumns = useMemo(
    () => [
      {
        Header: 'Job Title',
        accessor: 'title',
      },
      {
        Header: 'Company',
        accessor: 'company',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
      },
      {
        Header: 'Total Applicants',
        accessor: 'applicants',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <span
            className={value === 'Active' ? 'text-green-600' : 'text-red-600'}
          >
            {value}
          </span>
        ),
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // setGlobalFilter: setReactTableGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    }
    // useGlobalFilter
  )

  const {
    getTableProps: getJobTitleTableProps,
    getTableBodyProps: getJobTitleTableBodyProps,
    headerGroups: jobTitleHeaderGroups,
    rows: jobTitleRows,
    prepareRow: prepareJobTitleRow,
  } = useTable({ columns: jobTitleColumns, data: jobTitleData })

  //   const handleGlobalFilterChange = (value) => {
  //     setGlobalFilter(value)
  //     setReactTableGlobalFilter(value)
  //   }

  return (
    <PrivateRoute redirectTo="/employer">
      <NavBar />

      <div className="relative mt-4 mb-12 shadow-md sm:rounded-sm lg:overflow-hidden">
        <div className="bg-[#eaeaea] h-[90px] w-full relative top-4 md:top-12 md:overflow-hidden">
          <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
            <span className="ml-10 mt-10 md:mt-0">
              <Link className="text-black hover:text-[#b61046]" to="/employer">
                Employer
              </Link>
            </span>
            <span className="ml-10 mt-10 md:mt-0">
              Jobs
              <hr className="w-[70px] mt-2 ml-[-20px] md:mt-6 h-[2px] bg-[#b61046]" />
            </span>
          </div>
          <div className="bg-[#eaeaea] md:ml-[400px] lg:ml-[720px] md:mt-[-70px] md:w-1/2">
            {/* <SearchFilter
            filter={globalFilter}
            setFilter={handleGlobalFilterChange}
          /> */}
          </div>
        </div>

        {/* Statistics Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-20 mb-8 mx-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Total Jobs Created</h3>
            <p className="text-3xl font-bold text-blue-600">
              {stats?.totalJobs}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Total Applicants</h3>
            <p className="text-3xl font-bold text-green-600">
              {stats.totalApplicants}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Random Stat</h3>
            <p className="text-3xl font-bold text-purple-600">
              {stats.randomStat}
            </p>
          </div>
        </div>

        {/* Job Title Table */}
        <div className="mt-16 mx-6">
          <h2 className="font-semibold text-lg mb-4">Job Listings</h2>
          <table
            {...getJobTitleTableProps()}
            className="w-full text-sm text-left text-gray-500 shadow-md rounded-lg overflow-hidden"
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              {jobTitleHeaderGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className="px-6 py-3">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getJobTitleTableBodyProps()}>
              {jobTitleRows.map((row) => {
                prepareJobTitleRow(row)
                return (
                  <tr {...row.getRowProps()} className="bg-white border-b">
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="px-6 py-4">
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <AppFooter />
    </PrivateRoute>
  )
}

export { Jobs }
