import React from 'react';


const date = new Date();
const currentYear = date.getFullYear();

const AppFooter = () => {
    return (
        <div className="fixed bottom-0 md:bottom-0 w-full h-[45px] p-2 text-white justify-between items-center bg-[#2C2C2C] border">
          <p className="text-center">
          Built by{" "}
        <a
          className="text-[#B61046] hover:text-white"
          href="https://certs-by-unify.vercel.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Certifications By Unify
        </a>{" "}
        ©{currentYear}
          </p>
        </div>
    )
}

export  {AppFooter}
