import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL_V1, axiosApiInstanceGet, FETCH_STATUS } from "../../utils";

export const getCourseList = createAsyncThunk(
  "getCourseList",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Courses/list-approved-courses?page=${page}&perPage=${pageSize}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCourseList2 = createAsyncThunk(
  "getCourseList2",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Courses/list-approved-courses?page=${page}&perPage=${pageSize2}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getApprovedCourseList = createAsyncThunk(
  "getApprovedCourseList",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Courses/list-courses-awaiting-approval?page=${page}&perPage=${pageSize}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSingleCourse = createAsyncThunk(
  "getSingleCourse",
  async (courseId, { fulfillWithValue, rejectWithValue }) => {
    try {
      let force = true;
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Courses/get-course-details?courseId=${courseId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getModuleContent = createAsyncThunk(
  "getModuleContent",
  async ({ courseId, moduleId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      let force = true;
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Courses/${courseId}/Modules/get-module-content-unrestricted?moduleId=${moduleId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAssessment = createAsyncThunk(
  "getAssessment",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page, courseId, moduleId, moduleAssessmentId } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Courses/${courseId}/Modules/${moduleId}/get-assessment-questions?moduleAssessmentId=${moduleAssessmentId}&page=${page}&perPage=${pageSize}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllPricing = createAsyncThunk(
  "getAllPricing",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Pricings/list-all-pricing?page=${page}&perPage=${pageSize}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPricingById = createAsyncThunk(
  "getPricingById",
  async (pricingId, { fulfillWithValue, rejectWithValue }) => {
    try {
      let force = true;
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Pricings/get-pricing?pricingId=${pricingId}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const pageSize = 10;
const pageSize2 = 100000;
const initialState = {
  page: 0,
  totalPages: 1,
  courseList: [],
  courseListErr: "",
  courseListStatus: FETCH_STATUS.IDLE,
  approvedCourse: [],
  approvedCourseErr: "",
  approvedCourseStatus: FETCH_STATUS.IDLE,
  singleCourse: {},
  singleCourseErr: "",
  singleCourseStatus: FETCH_STATUS.IDLE,
  assessmentList: [],
  assessmentListErr: "",
  assessmentListStatus: FETCH_STATUS.IDLE,
  moduleContent: [],
  moduleContentErr: "",
  moduleContentStatus: FETCH_STATUS.IDLE,

  allPricing: [],
  allPricingErr: "",
  allPricingStatus: FETCH_STATUS.IDLE,

  pricingById: {},
  pricingByIdErr: "",
  pricingByIdStatus: FETCH_STATUS.IDLE,
};

export const courseSlice = createSlice({
  name: "course",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCourseList.pending, (state) => {
        state.courseListStatus = FETCH_STATUS.PENDING;
        state.courseListErr = "";
      })
      .addCase(getCourseList.fulfilled, (state, action) => {
        state.courseListStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.courseList = pagedList;
        state.courseListErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getCourseList.rejected, (state, action) => {
        state.courseListStatus = FETCH_STATUS.REJECTED;
        state.courseListErr = action.payload;
      })
      .addCase(getCourseList2.pending, (state) => {
        state.courseListStatus = FETCH_STATUS.PENDING;
        state.courseListErr = "";
      })
      .addCase(getCourseList2.fulfilled, (state, action) => {
        state.courseListStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.courseList = pagedList;
        state.courseListErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getCourseList2.rejected, (state, action) => {
        state.courseListStatus = FETCH_STATUS.REJECTED;
        state.courseListErr = action.payload;
      })
      .addCase(getApprovedCourseList.pending, (state) => {
        state.approvedCourseStatus = FETCH_STATUS.PENDING;
        state.approvedCourseErr = "";
      })
      .addCase(getApprovedCourseList.fulfilled, (state, action) => {
        state.approvedCourseStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.approvedCourse = pagedList;
        state.approvedCourseErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getApprovedCourseList.rejected, (state, action) => {
        state.approvedCourseStatus = FETCH_STATUS.REJECTED;
        state.approvedCourseErr = action.payload;
      })
      .addCase(getSingleCourse.pending, (state) => {
        state.singleCourseStatus = FETCH_STATUS.PENDING;
        state.singleCourseErr = "";
      })
      .addCase(getSingleCourse.fulfilled, (state, action) => {
        state.singleCourseStatus = FETCH_STATUS.RESOLVED;
        state.singleCourse = action.payload;
        state.singleCourseErr = "";
      })
      .addCase(getSingleCourse.rejected, (state, action) => {
        state.singleCourseStatus = FETCH_STATUS.REJECTED;
        state.singleCourseErr = action.payload;
      })
      .addCase(getModuleContent.pending, (state) => {
        state.moduleContentStatus = FETCH_STATUS.PENDING;
        state.moduleContentErr = "";
      })
      .addCase(getModuleContent.fulfilled, (state, action) => {
        state.moduleContentStatus = FETCH_STATUS.RESOLVED;
        state.moduleContent = action.payload;
        state.moduleContentErr = "";
      })
      .addCase(getModuleContent.rejected, (state, action) => {
        state.moduleContentStatus = FETCH_STATUS.REJECTED;
        state.moduleContentErr = action.payload;
      })
      .addCase(getAssessment.pending, (state) => {
        state.assessmentListStatus = FETCH_STATUS.PENDING;
        state.assessmentListErr = "";
      })
      .addCase(getAssessment.fulfilled, (state, action) => {
        state.assessmentListStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.assessmentList = pagedList;
        state.assessmentListErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getAssessment.rejected, (state, action) => {
        state.assessmentListStatus = FETCH_STATUS.REJECTED;
        state.assessmentListErr = action.payload;
      })
      .addCase(getAllPricing.pending, (state) => {
        state.allPricingStatus = FETCH_STATUS.PENDING;
        state.allPricingErr = "";
      })
      .addCase(getAllPricing.fulfilled, (state, action) => {
        state.allPricingStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.allPricing = pagedList;
        state.allPricingErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getAllPricing.rejected, (state, action) => {
        state.allPricingStatus = FETCH_STATUS.REJECTED;
        state.allPricingErr = action.payload;
      })
      .addCase(getPricingById.pending, (state) => {
        state.pricingByIdStatus = FETCH_STATUS.PENDING;
        state.pricingByIdErr = "";
      })
      .addCase(getPricingById.fulfilled, (state, action) => {
        state.pricingByIdStatus = FETCH_STATUS.RESOLVED;
        state.pricingById = action.payload;
        state.pricingByIdErr = "";
      })
      .addCase(getPricingById.rejected, (state, action) => {
        state.pricingByIdStatus = FETCH_STATUS.REJECTED;
        state.pricingByIdErr = action.payload;
      });
  },
});

export const courseReducer = courseSlice.reducer;
export const selectCourse = (state) => state.course;
