import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Pagination,
  AppFooter,
  NavBar,
  PrivateRoute,
  TransactionTable,
  Loading,
  Error,
  Success,
} from "../../components";
import { getAllTransaction, selectTransaction } from "../../redux/slices";
import clamp from "lodash/clamp";
import generateExcelWorkBook from "../../utils/transactions-report";

const TransactionPage = () => {
  const dispatch = useDispatch();
  const {
    page,
    totalPages,
    transactionList,
    transactionListErr,
    transactionListStatus,
    startDate,
    endDate,
  } = useSelector(selectTransaction);

  useEffect(() => {
    dispatch(getAllTransaction());
  }, [dispatch]);

  const getList = useCallback(
    (force = true, newPage = page) => {
      dispatch(
        getAllTransaction({
          force,
          page: newPage,
          start: startDate,
          end: endDate,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    getList(true);
  }, [getList]);

  return (
    <PrivateRoute redirectTo="/transactions">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <section>
          <Loading status={transactionListStatus} />

          <Error
            retry={getList}
            err={transactionListErr}
            status={transactionListStatus}
          />

          <Success status={transactionListStatus}>
            <TransactionTable data={transactionList} />
            <div className="flex items-center justify-center p-4 -mt-8">
              <button onClick={() => generateExcelWorkBook(startDate, endDate)}>
                Generate Excel File
              </button>
            </div>
          </Success>
          <Success status={transactionListStatus}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() => getList(false, clamp(page - 1, 1, totalPages))}
                next={() => getList(false, clamp(page + 1, 1, totalPages))}
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
        </section>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { TransactionPage };
