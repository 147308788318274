import React from 'react';
import { useTable } from "react-table";
import classNames from "classnames";
import {Link} from "react-router-dom";
import styles from "../UI/ResponsiveTable.module.css";
import defaultImage from "../../assets/images/defaultImage.png";


const Card2 = ({data}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        Cell: ({cell}) => {
          const profileImage = cell?.row?.original.imageUrl;
            return(
                <div className="flex items-center">
                    <div
                key={profileImage}
                className="hidden lg:block md:ml-0 w-[2rem] h-[2rem] rounded-lg bg-no-repeat bg-contain bg-center  bg-white "
                style={{
                  backgroundImage: `url("${profileImage || defaultImage}")`,
                }}
              ></div>
              <p className="ml-2 md:ml-12">{cell?.row?.original?.name}</p>
              </div>
            )
        }
        
      },
      {
        Header:"Instructor",
        accessor:"instructors",
        Cell:({value}) => {
         const instructorBio = value?.map((instructor)=>{
           return (
            <div className="flex items-center">
            <div
        key={instructor?.id}
        className="ml-[110px] md:ml-0 w-[2rem] h-[2rem] rounded-lg bg-no-repeat bg-contain bg-center  bg-white "
        style={{
          backgroundImage: `url("${instructor?.profileImageUrl || defaultImage}")`,
        }}
      ></div>
      <p className="ml-2">{instructor?.name}</p>
      </div>
           )
         })
         return <div>{instructorBio}</div>;
        }
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

        
    return (
        <div className="mt-[-50px] ml-[-10px] md:mt-4 lg:mt-[-400px] mb-16 md:mb-0 md:ml-10">
        {/* Second Table */}
        <div className="md:border rounded md:-ml-12 lg:ml-[-270px] mb-[65px] md:w-3/4 lg:w-1/2 w-full">
            <div className="flex px-2">
		  <p className="font-bold m-5">Course Applications</p>
		   <button className="text-[#ef4d41] hover:bg-[#ef4d41] border rounded border-[#ef4d41] w-16 h-8 mt-5 ml-24 md:ml-12"><Link to="/pendingCourses" className="text-[#ef4d41] hover:text-white">See All</Link></button>
		  </div>
          <div className=" sm:rounded-sm">
      <table
        {...getTableProps()}
        className={classNames(
          "w-3/4 md:w-full text-sm text-left text-gray-500",
          styles.table
        )}
      >
        <thead className="text-xs capitalize text-gray-400 bg-gray-50">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers?.map((column) => (
                <th {...column.getHeaderProps()} className="px-6 py-3">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="p-4 bg-white border-b-4">
                {row.cells?.map((cell) => {
                  return (
                    <td
                      data-label={cell?.column?.Header}
                      {...cell.getCellProps()}
                      className="p-4 md:px-6 md:py-4 text-xs text-gray-900 min-w-min "
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
        </div>
        </div>
    )
}

export {Card2}
