import { useState, useEffect } from "react";
import {
  NavBar,
  CourseCard,
  CourseCard2,
  AppFooter,
  PrivateRoute,
  Spinner,
} from "../../components";
import { Link, useParams } from "react-router-dom";
import { EyeOutlined, LeftOutlined, CrownOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleCourse,
  selectCourse,
  getAllPricing,
} from "../../redux/slices";
import defaultImage from "../../assets/images/defaultImage.png";
import {
  axiosApiInstance,
  API_URL_V1,
  setPricingSchema,
  setPricingInit,
} from "../../utils";
import { useRequest } from "../../hooks";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const CourseDetailPage2 = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty, isSubmitted },
  } = useForm({
    defaultValues: {
      setPricingInit,
    },
    resolver: yupResolver(setPricingSchema),
  });

  const { fields } = useFieldArray({
    control,
    name: "coursePrice",
  });

  const subscriptionColor = [
    { type: "Free", colorType: "#f6edf0" },
    { type: "Lite", colorType: "#a1a3a2" },
    { type: "Standard", colorType: "#fab281" },
    { type: "Premium", colorType: "#b61046" },
  ];

  const getSubscriptionColor = (subscriptionType) => {
    const selectedSubscription = subscriptionColor.find((x) => {
      return x.type === subscriptionType;
    });
    if (selectedSubscription) {
      return selectedSubscription.colorType;
    } else {
      return "#000000";
    }
  };

  const [showVideo, setShowVideo] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [showError, setShowError] = useState(false);

  const togglePricing = () => {
    setShowPricing((s) => !s);
  };

  const toggleVideo = () => {
    setShowVideo((s) => !s);
  };

  const toggleImage = () => {
    setShowImage((s) => !s);
  };

  const { courseId } = useParams();
  const dispatch = useDispatch();
  const { status, setPending, setRejected, setResolved } = useRequest();

  const { singleCourse, allPricing } = useSelector(selectCourse);

  useEffect(() => {
    dispatch(getSingleCourse(courseId));
    dispatch(getAllPricing())
      .unwrap()
      .then((data) => {
        reset({ coursePrice: data?.pagedList });
      });
  }, [dispatch, courseId]);

  const UpdatePricing = async (data) => {
    const body = data?.coursePrice.filter((x) => {
      return x?.selected;
    });

    if (body.length < 1) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    setPending();

    try {
      await axiosApiInstance.post(
        `${API_URL_V1}/Courses/${courseId}/set-pricings`,
        body
      );
      setResolved();
      togglePricing();
      dispatch(getSingleCourse(courseId));
    } catch (err) {
      setRejected(err);
    }
  };

  return (
    <PrivateRoute redirectTo="/courses">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <div className="bg-[#eaeaea] h-[90px] w-full relative top-12 overflow-hidden ">
          <div className="flex font-semibold ml-5 mt-[40px]">
            <span className="md:ml-10">
              <Link
                className="text-black hover:text-[#b61046]"
                to="/pendingCourses"
              >
                Awaiting Approvals
              </Link>
            </span>
            <span className="ml-10">Courses</span>
            <hr className="w-[50px] mt-11 h-[2px] ml-[-48px] bg-[#b61046]" />
            <span className="ml-10">
              <Link className="text-black hover:text-[#b61046]" to="/pricing">
                Course Pricing
              </Link>
            </span>
            
          </div>
        </div>
        <div className="w-full h-full relative left-10 top-[90px] ">
          <div className="grid grid-rows-2 grid-flow-col gap-4">
            <div className="row-start-1 md:ml-24">
              <p className="md:ml-[-56px]">
                <Link className="text-[#b61046] hover:text-black" to="/courses">
                  <LeftOutlined style={{ fontSize: "12px" }} />
                  <span className="m-[12px]">Back</span>
                </Link>
              </p>
              <h2 className="font-semibold text-lg w-3/4 md:w-1/4">
                {singleCourse?.name}
              </h2>
            </div>

            {/* {singleCourse?.pricings?.length === 0 && ( */}
            <div className=" ml-[155px] mt-[-100px] md:ml-[865px] md:mt-[-70px]">
              <button
                className="text-white bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
                type="button"
                data-modal-toggle="pricingModal"
                onClick={togglePricing}
              >
                Set Pricing
              </button>
            </div>
            {/* )} */}
          </div>

          <div className="flex mt-[-50px] md:mt-[-120px] items-center">
            <img
              className=" w-[3rem] h-[3rem] -ml-4 md:ml-8 md:mr-4 mr-2 rounded-lg bg-no-repeat bg-contain bg-center  bg-white "
              src={singleCourse?.imageUrl || defaultImage}
              alt="profile"
            />
            <EyeOutlined style={{ marginTop: "10px" }} />{" "}
            <span className="md:ml-1">
              <button
                type="button"
                data-modal-toggle="defaultModal3"
                onClick={toggleVideo}
                className=" text-[#B61046] mt-[10px]"
              >
                Watch Preview Video
              </button>
            </span>
            <EyeOutlined style={{ marginTop: "10px", marginLeft: "10px" }} />
            <span className="md:ml-1">
              <button
                type="button"
                data-modal-toggle="defaultModal4"
                onClick={toggleImage}
                className=" text-[#B61046] mt-[10px]"
              >
                View Course Image
              </button>
            </span>
          </div>

          {/* Pricing Modal */}

          {/* Main modal  */}
          {showPricing ? (
            <div
              id="pricingModal"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full h-full"
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%] m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  <button
                    onClick={togglePricing}
                    type="button"
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="pricingModal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  {/* Modal header */}
                  <div className="py-4 px-6 rounded-t border-b dark:border-gray-600">
                    <h3 className="text-base font-semibold text-gray-900  dark:text-white">
                      Edit Course Pricing
                    </h3>
                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                      Select a package to add to this Course
                    </p>
                  </div>
                  {/* Modal body  */}
                  <form className="p-6" onSubmit={handleSubmit(UpdatePricing)}>
                    <ul className="my-4 space-y-3">
                      {fields?.map((pricing, index) => {
                        const returnedColor = getSubscriptionColor(
                          pricing?.subscriptionType
                        );

                        return (
                          <li key={index}>
                            <div
                              className="flex items-center mt-[-30px] px-6 mb-6 py-2 text-sm font-light text-gray-600 bg-gray-50 rounded-lg  dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                              type="button"
                              data-modal-toggle="premiumModal"
                            >
                              <CrownOutlined
                                style={{
                                  fontSize: "24px",
                                  marginBottom: "10px",
                                  color: returnedColor,
                                }}
                              />
                              <p className="mt-[-20px] ml-4 w-full">
                                {pricing.subscriptionType}
                              </p>

                              <input
                                type="number"
                                {...register(`coursePrice.${index}.price`)}
                                defaultValue={pricing?.price}
                                className="mt-[-20px] ml-[5px] font-semibold border-gray-700 appearance-none outline-none border-[1px] px-6"
                              />

                              <input
                                type="checkbox"
                                {...register(`coursePrice.${index}.selected`)}
                                className="w-12 h-12 -mt-[20px] ml-[130px] md:ml-[182px] accent-[#b61046] ring-[#b61046]  focus:ring-red-600"
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {showError && (
                      <p className="text-red-500 text-xs">
                        Select at least one package
                      </p>
                    )}
                    <div className="mt-">
                      <button
                        type="submit"
                        data-modal-toggle="pricingModal"
                        className="relative flex items-center justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md group hover:bg-[#88062f] bg-[#b61046] focus:outline-none focus:ring-2 focus:ring-offset-2 "
                      >
                        Update
                        <Spinner
                          className="!w-3  !h-3 mx-1 text-white fill-pink-400"
                          status={status}
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : null}

          {/* Video Modal */}
          {showVideo ? (
            <div
              id="defaultModal"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="relative p-4 w-full max-w-2xl m-auto h-full md:h-auto">
                {/* Modal content */}
                <div className="relative  top-10 bg-white shadow dark:bg-gray-700">
                  {/* Modal header  */}
                  <div className="text-end">
                    <button
                      onClick={toggleVideo}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body  */}
                  <div>
                    <ReactPlayer
                      width=""
                      controls
                      url={singleCourse?.previewVideoUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Image Modal */}
          {showImage ? (
            <div
              id="defaultModal4"
              tabIndex="-1"
              aria-hidden="true"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-2xl m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  {/* Modal header  */}
                  <div className="text-end">
                    <button
                      onClick={toggleImage}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal4"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body  */}
                  <div>
                    <img
                      className="w-full h-[500px]"
                      src={singleCourse?.imageUrl || defaultImage}
                      alt="courseImage"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Price Card  */}
          <div className="border rounded-lg border-gray-700 px-6 w-3/4 lg:w-full max-w-md h-fit  lg:absolute lg:ml-[40rem] desktop-med:ml-[42rem] desktop-wide:ml-[44rem] 2xl:ml-[48rem] desktop-des:ml-[54rem] md:ml-[150px] md:top-[-10px] mt-[140px] md:mb-20 lg:mb-40">
            <div className="py-1 px-6 rounded-t border-b dark:border-gray-600">
              <h3 className="text-base font-semibold text-gray-900  dark:text-white">
                Course Pricing
              </h3>
            </div>
            <div>
              <ul>
                {singleCourse?.pricings?.map((pricing) => {
                  return (
                    <li>
                      <div className="text-sm font-light text-gray-600 rounded-lg dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                        <p>{pricing.name} Package</p>
                        <p className="font-semibold ">N{pricing.price}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="block lg:flex ">
            <div className="w-[100%] lg:w-[50%] ">
              <CourseCard />
            </div>
            <div className="w-[100%] lg:w-[50%] ">
              <CourseCard2 />
            </div>
          </div>
        </div>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { CourseDetailPage2 };
