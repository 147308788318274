import * as React from "react";
const ToggleOff = (props) => (
  <svg
    width={41}
    height={22}
    viewBox="0 0 41 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={41} height={21} rx={10.5} fill="#E4E6E8" />
    <g filter="url(#filter0_d_5835_7161)">
      <circle cx={10.5} cy={10.5} r={8.5} fill="#FBFBFB" />
    </g>
    <defs>
      <filter
        id="filter0_d_5835_7161"
        x={0}
        y={1}
        width={21}
        height={21}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5835_7161"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5835_7161"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export { ToggleOff };
