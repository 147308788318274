import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Pagination,
  AppFooter,
  NavBar,
  PrivateRoute,
  SettlementTable,
  Loading,
  Error,
  Success,
} from "../../components";
import { getAllSettlement, selectTransaction } from "../../redux/slices";
import clamp from "lodash/clamp";

const SettlementPage = () => {
  const dispatch = useDispatch();
  const {
    page,
    totalPages,
    settlementList,
    settlementListErr,
    settlementListStatus,
    startDate,
    endDate,
  } = useSelector(selectTransaction);

  useEffect(() => {
    dispatch(getAllSettlement());
  }, [dispatch]);

  const getList = useCallback(
    (force = true, newPage = page) => {
      dispatch(
        getAllSettlement({
          force,
          page: newPage,
          start: startDate,
          end: endDate,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    getList(true);
  }, [getList]);

  return (
    <PrivateRoute redirectTo="/settlements">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <section>
          <Loading status={settlementListStatus} />

          <Error
            retry={getList}
            err={settlementListErr}
            status={settlementListStatus}
          />

          <Success status={settlementListStatus}>
            <SettlementTable data={settlementList} />
          </Success>
          <Success status={settlementListStatus}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() => getList(false, clamp(page - 1, 1, totalPages))}
                next={() => getList(false, clamp(page + 1, 1, totalPages))}
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
        </section>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { SettlementPage };
