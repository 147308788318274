import React from 'react'
import { AppFooter, NavBar, PrivateRoute } from '../../components'
import { EmployerTable } from './EmployerTable'
import { employerList } from '../../data/employerList'

const EmployerPage = () => {
  return (
    <PrivateRoute redirectTo="/activeStudents">
      <div div className="min-h-fit overflow-hidden">
        <NavBar />
        <section>
          <EmployerTable data={employerList} />
        </section>
        <AppFooter />
      </div>
    </PrivateRoute>
  )
}

export default EmployerPage
