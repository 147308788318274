import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Loading, Error, Success, PrivateRoute } from '../../components'
import { useParams } from 'react-router-dom'
import { selectCourse, getAssessment } from '../../redux/slices'

const ReviewPage = () => {
  const { courseId, moduleId, moduleAssessmentId } = useParams()
  const dispatch = useDispatch()
  const {
    page,
    assessmentList,
    assessmentListErr,
    assessmentListStatus,
  } = useSelector(selectCourse)

  useEffect(() => {
    dispatch(
      getAssessment({
        force: true,
        page,
        courseId,
        moduleId,
        moduleAssessmentId,
      }),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const getList = useCallback(
    (force = true, page, courseId, moduleId) => {
      dispatch(
        getAssessment({ force, page, courseId, moduleId, moduleAssessmentId }),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, page, courseId, moduleId, moduleAssessmentId],
  )

  return (
    <PrivateRoute redirectTo="/courses">
      <div className="mb-12">
        <h2 className="text-center text-2xl m-6 font-bold">
          Course Assessment
        </h2>
        <hr className="text-center md:w-1/2 w-3/4 m-auto" />
        <Loading status={assessmentListStatus} />
        <Error
          retry={getList}
          err={assessmentListErr}
          status={assessmentListStatus}
        />
        <Success status={assessmentListStatus}>
          {assessmentList?.map((questions, index) => {
            return (
              <div className="ml-8 mt-8">
                <p className="text-xs">{` Question ${index + 1}`}</p>
                <h4 className="font-semibold">{questions?.question}</h4>
                <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                  <input
                    type="radio"
                    className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    name="test"
                    value=""
                  />
                  <span className="ml-2">{questions?.optionOne}</span>
                </div>
                <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                  <input
                    type="radio"
                    className=" text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    name="test"
                    value=""
                  />
                  <span className="ml-2">{questions?.optionTwo}</span>
                </div>
                <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                  <input
                    type="radio"
                    className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    name="test"
                    value=""
                  />
                  <span className="ml-2">{questions?.optionThree}</span>
                </div>
                <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                  <input
                    type="radio"
                    className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    name="test"
                    value=""
                  />
                  <span className="ml-2">{questions?.optionFour}</span>
                </div>
                <p className="font-bold">
                  Answer :{' '}
                  <span className="text-green-600">
                    {questions?.correctOption}
                  </span>
                </p>
              </div>
            )
          })}
        </Success>
      </div>
    </PrivateRoute>
  )
}

export { ReviewPage }
