import { useState, useEffect } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import {
  getSingleCourse,
  getModuleContent,
  selectCourse,
} from "../../redux/slices";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CourseCard = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [showClickAndMatch, setShowClickAndMatch] = useState(false);
  const [showAllThatApply, setShowAllThatApply] = useState(false);
  const [showFillInTheBlanks, setShowFillInTheBlanks] = useState(false);
  const [showSelectAnswer, setShowSelectAnswer] = useState(false);
  const [showThisOrThat, setShowThisOrThat] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const { courseId } = useParams();

  const toggleModule = (id) => {
    if (showDropdown === id) {
      setShowDropdown(false);
    } else {
      dispatch(getModuleContent({ moduleId: id, courseId: courseId }));
      setShowDropdown(id);
    }
  };

  const toggleVideo = () => {
    setShowVideo((s) => !s);
  };

  const toggleDocument = () => {
    setShowDocument((s) => !s);
  };

  const toggleClickAndMatch = () => {
    setShowClickAndMatch((s) => !s);
  };

  const toggleAllThatApply = () => {
    setShowAllThatApply((s) => !s);
  };

  const toggleFillTheBlanks = () => {
    setShowFillInTheBlanks((s) => !s);
  };

  const toggleSelectAnswer = () => {
    setShowSelectAnswer((s) => !s);
  };

  const toggleThisOrThat = () => {
    setShowThisOrThat((s) => !s);
  };

  const { singleCourse, moduleContent } = useSelector(selectCourse);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleCourse(courseId));
  }, [dispatch, courseId]);

  return (
    <div className="ml-[-35px] md:ml-[35px] lg:ml-[-35px] w-[650px] md:w-[1200px] lg:mb-48">
      {singleCourse?.modules?.length > 0 &&
        singleCourse?.modules?.map((singleModule) => {
          return (
            <div
              id="accordion-collapse"
              data-accordion="collapse"
              className="mb-[-30px]"
            >
              <h2 id="accordion-collapse-heading-1">
                <button
                  onClick={() => {
                    toggleModule(singleModule.moduleId);
                  }}
                  type="button"
                  className="flex items-center justify-between ml-8 mt-10 w-1/2 p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                  data-accordion-target="#accordion-collapse-body-1"
                  aria-expanded="true"
                  aria-controls="accordion-collapse-body-1"
                >
                  <p className="text-gray-900">{singleModule.name}</p>
                  <svg
                    data-accordion-icon
                    className="w-6 h-6 rotate-180 shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
                <p className="relative top-[-28px] left-14 text-xs text-gray-500">
                  {singleModule.description}
                </p>
              </h2>
              {showDropdown === singleModule?.moduleId ? (
                <div
                  id="accordion-collapse-body-1"
                  className="block"
                  aria-labelledby="accordion-collapse-heading-1"
                >
                  <div className="p-5 w-1/2 ml-8 font-light  dark:border-gray-700 dark:bg-gray-900">
                    {moduleContent?.videos?.map((singleVideo) => {
                      return (
                        <div className="mt-[-20px] mb-6 ">
                          <input
                            type="radio"
                            className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            value=""
                          />
                          <span className="ml-2">
                            {singleVideo?.displayName}
                            <button
                              type="button"
                              data-modal-toggle="defaultModal"
                              onClick={toggleVideo}
                              className="ml-12 text-[#B61046]"
                            >
                              Watch Video
                            </button>
                          </span>
                          <p className="text-gray-400 ml-6">
                            {Math.floor(
                              (singleVideo?.totalSeconds % 3600) / 60
                            )}{" "}
                            minutes
                          </p>
                        </div>
                      );
                    })}

                    {moduleContent?.assessments?.map((assessment) => {
                      return (
                        <div className="mt-6 ">
                          <input
                            type="radio"
                            className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            value=""
                          />
                          <span className="ml-2 capitalize">
                            {assessment?.name} -{" "}
                            {assessment?.totalNumberofQuestions} Questions{" "}
                            <EyeOutlined
                              style={{ marginLeft: "140px", color: "#b61046" }}
                            />{" "}
                            <span className="text-[#B61046]">
                              <Link
                                className="text-[#b61046]"
                                to={`/reviewAssessment/${courseId}/${singleModule?.moduleId}/${assessment.id}`}
                              >
                                View
                              </Link>
                            </span>
                          </span>
                        </div>
                      );
                    })}

                    {moduleContent?.documents?.map((document) => {
                      return (
                        <div className="mt-6 ">
                          <input
                            type="radio"
                            className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            value=""
                          />
                          <span className="ml-2 capitalize">
                            {document?.displayName}
                            <button
                              type="button"
                              data-modal-toggle="defaultModal2"
                              onClick={toggleDocument}
                              className="ml-12 text-[#B61046]"
                            >
                              <EyeOutlined />
                              View
                            </button>
                          </span>
                        </div>
                      );
                    })}

                    {moduleContent?.clickAndMatchInteractiveTypes?.map(
                      (click) => {
                        return (
                          <div className="mt-6 ">
                            <input
                              type="radio"
                              className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              value=""
                            />
                            <span className="ml-2 capitalize">
                              {click?.title}
                              <button
                                type="button"
                                data-modal-toggle="defaultModal3"
                                onClick={toggleClickAndMatch}
                                className="ml-12 text-[#B61046]"
                              >
                                <EyeOutlined />
                                View
                              </button>
                            </span>
                          </div>
                        );
                      }
                    )}

                    {moduleContent?.thisOrThatInteractiveTypes?.map(
                      (thisOr) => {
                        return (
                          <div className="mt-6 ">
                            <input
                              type="radio"
                              className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              value=""
                            />
                            <span className="ml-2 capitalize">
                              {thisOr?.title}
                              <button
                                type="button"
                                data-modal-toggle="defaultModal4"
                                onClick={toggleThisOrThat}
                                className="ml-12 text-[#B61046]"
                              >
                                <EyeOutlined />
                                View
                              </button>
                            </span>
                          </div>
                        );
                      }
                    )}

                    {moduleContent?.selectAnswerInteractiveTypes?.map(
                      (selectAnswer) => {
                        return (
                          <div className="mt-6 ">
                            <input
                              type="radio"
                              className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              value=""
                            />
                            <span className="ml-2">
                              Select an Answer
                              <button
                                type="button"
                                data-modal-toggle="defaultModal5"
                                onClick={toggleSelectAnswer}
                                className="ml-12 text-[#B61046]"
                              >
                                <EyeOutlined />
                                View
                              </button>
                            </span>
                          </div>
                        );
                      }
                    )}

                    {moduleContent?.fillInTheBlanksInteractiveTypes?.map(
                      (fill) => {
                        return (
                          <div className="mt-6 ">
                            <input
                              type="radio"
                              className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              value=""
                            />
                            <span className="ml-2 capitalize">
                              {fill?.title}
                              <button
                                type="button"
                                data-modal-toggle="defaultModal6"
                                onClick={toggleFillTheBlanks}
                                className="ml-12 text-[#B61046]"
                              >
                                <EyeOutlined />
                                View
                              </button>
                            </span>
                          </div>
                        );
                      }
                    )}

                    {moduleContent?.allThatApplyInteractiveTypes?.map(
                      (allThatApply) => {
                        return (
                          <div className="mt-6 ">
                            <input
                              type="radio"
                              className="text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              value=""
                            />
                            <span className="ml-2 capitalize">
                              {allThatApply?.title}
                              <button
                                type="button"
                                data-modal-toggle="defaultModal7"
                                onClick={toggleAllThatApply}
                                className="ml-12 text-[#B61046]"
                              >
                                <EyeOutlined />
                                View
                              </button>
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}

      {/* Video Modal */}
      {showVideo ? (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
        >
          <div className="relative p-4 w-full max-w-2xl m-auto h-full md:h-auto">
            {/* Modal content */}
            <div className="relative  top-10 bg-white shadow dark:bg-gray-700">
              {/* Modal header  */}
              <div className="text-end">
                <button
                  onClick={toggleVideo}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body  */}
              {moduleContent?.videos?.map((singleVideo) => {
                return (
                  <div>
                    <ReactPlayer
                      width=""
                      controls
                      url={singleVideo?.videoUrl}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      {/* Document Modal */}
      {showDocument ? (
        <div
          id="defaultModal2"
          tabIndex="-1"
          aria-hidden="true"
          className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
        >
          <div className="relative p-4 max-w-2xl m-auto  h-full md:h-auto">
            {/* Modal content */}
            <div className="relative  top-10 bg-white shadow dark:bg-gray-700">
              {/* Modal header  */}
              <div className="text-end">
                <button
                  onClick={toggleDocument}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal2"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body  */}
              {moduleContent?.documents?.map((docu) => {
                return (
                  <div className="px-6 py-4">
                    <nav className="flex items-center justify-center space-x-5 font-bold text-base text-red-600">
                      <button onClick={goToPrevPage}>Prev</button>
                      <button onClick={goToNextPage}>Next</button>
                    </nav>
                    <p className="text-base font-semibold">
                      Page {pageNumber} of {numPages}
                    </p>
                    <p className="font-semibold text-lg text-center">
                      {docu?.description}
                    </p>
                    <div className="w-fit h-fit">
                      <Document
                        file={docu?.documentUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      {/* Click and Match Modal */}

      {showClickAndMatch ? (
        <div
          id="defaultModal3"
          tabIndex="-1"
          aria-hidden="true"
          className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
        >
          <div className="relative p-4 w-full max-w-2xl m-auto h-full md:h-auto">
            {/* Modal content */}
            <div className="relative  top-10 bg-white shadow dark:bg-gray-700">
              {/* Modal header  */}
              <div className="text-end">
                <button
                  onClick={toggleClickAndMatch}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal3"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body  */}
              <h4 className="font-semibold text-lg text-center">
                Click and Match Interactive Types
              </h4>
              {moduleContent?.clickAndMatchInteractiveTypes?.map(
                (clickMatch) => {
                  return (
                    <div className="p-6">
                      {clickMatch?.statements?.map((eachStatement) => {
                        return (
                          <div className="flex items-center justify-between">
                            <p className="font-bold text-green-600">
                              Statement :{" "}
                              <span className="text-gray-900">
                                {" "}
                                {eachStatement?.statement}{" "}
                              </span>
                            </p>
                            <p className="font-bold text-green-600">
                              Match :{" "}
                              <span className="text-gray-900">
                                {" "}
                                {eachStatement?.statementMatch}{" "}
                              </span>
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      ) : null}

      {/* This or That Modal */}
      {showThisOrThat ? (
        <div
          id="defaultModal4"
          tabIndex="-1"
          aria-hidden="true"
          className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
        >
          <div className="relative p-4 w-full max-w-2xl m-auto h-full md:h-auto">
            {/* Modal content */}
            <div className="relative  top-10 bg-white shadow dark:bg-gray-700">
              {/* Modal header  */}
              <div className="text-end">
                <button
                  onClick={toggleThisOrThat}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal4"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body  */}
              <h4 className="font-semibold text-lg text-center">
                This or That Interactive Types
              </h4>
              {moduleContent?.thisOrThatInteractiveTypes?.map(
                (thisOrThat, index) => {
                  return (
                    <div>
                      <div className="px-6 py-4">
                        <h4 className="font-semibold text-center">
                          {thisOrThat?.title}
                        </h4>
                        <p className="text-sm">{` Question ${index + 1}`}</p>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">{thisOrThat?.optionOne}</span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className=" text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">{thisOrThat?.optionTwo}</span>
                        </div>
                        <p className="font-bold">
                          Answer :{" "}
                          <span className="text-green-600">
                            {thisOrThat?.correctOption}
                          </span>
                        </p>
                        <div>
                          <p className="font-bold">
                            Type:{" "}
                            <span className="text-gray-800 font-light">
                              {thisOrThat?.cardDetails?.cardType}
                            </span>
                          </p>
                          <p className="font-bold">
                            Content:{" "}
                            <span className="text-gray-800 font-light">
                              {thisOrThat?.cardDetails?.content}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      ) : null}

      {/* Select Answer Modal*/}
      {showSelectAnswer ? (
        <div
          id="defaultModal5"
          tabIndex="-1"
          aria-hidden="true"
          className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
        >
          <div className="relative p-4 w-full max-w-2xl m-auto h-full md:h-auto">
            {/* Modal content */}
            <div className="relative  top-10 bg-white shadow dark:bg-gray-700">
              {/* Modal header  */}
              <div className="text-end">
                <button
                  onClick={toggleSelectAnswer}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal5"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body  */}
              <h4 className="font-semibold text-center text-lg">
                Select Answer Interactive Types
              </h4>
              {moduleContent?.selectAnswerInteractiveTypes?.map(
                (selectAnswer, index) => {
                  return (
                    <div>
                      <div className="px-6 py-4">
                        <p className="text-sm">{` Question ${index + 1}`}</p>
                        <h4 className="font-semibold">
                          {selectAnswer?.question}
                        </h4>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">
                            {selectAnswer?.optionOne}
                          </span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className=" text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">
                            {selectAnswer?.optionTwo}
                          </span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">
                            {selectAnswer?.optionThree}
                          </span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">
                            {selectAnswer?.optionFour}
                          </span>
                        </div>
                        <p className="font-bold">
                          Answer :{" "}
                          <span className="text-green-600">
                            {selectAnswer?.correctOption}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      ) : null}

      {/* Fill in the Blanks Modal*/}
      {showFillInTheBlanks ? (
        <div
          id="defaultModal6"
          tabIndex="-1"
          aria-hidden="true"
          className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
        >
          <div className="relative p-4 w-full max-w-2xl m-auto h-full md:h-auto">
            {/* Modal content */}
            <div className="relative  top-10 bg-white shadow dark:bg-gray-700">
              {/* Modal header  */}
              <div className="text-end">
                <button
                  onClick={toggleFillTheBlanks}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal6"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body  */}
              <h4 className="text-center text-lg font-semibold">
                Fill in the Blank Interactive Types
              </h4>
              {moduleContent?.fillInTheBlanksInteractiveTypes?.map(
                (fillBlanks, index) => {
                  return (
                    <div>
                      <div className="py-4 px-6">
                        <h2 className="font-semibold capitalize">
                          {fillBlanks?.title}
                        </h2>
                        <p className="text-sm">{` Question ${index + 1}`}</p>
                        <h4 className="font-semibold">
                          {fillBlanks?.question}
                        </h4>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">{fillBlanks?.optionOne}</span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className=" text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">{fillBlanks?.optionTwo}</span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">
                            {fillBlanks?.optionThree}
                          </span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="radio"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">{fillBlanks?.optionFour}</span>
                        </div>
                        {fillBlanks?.answers?.map((answer) => {
                          return (
                            <>
                              <p className="font-bold">
                                Blank Section :{" "}
                                <span className="text-green-600">
                                  {" "}
                                  {answer?.blankSection}{" "}
                                </span>
                              </p>
                              <p className="font-bold">
                                Answer :{" "}
                                <span className="text-green-600">
                                  {" "}
                                  {answer?.correctOption}{" "}
                                </span>
                              </p>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      ) : null}

      {/* All That Apply Modal*/}
      {showAllThatApply ? (
        <div
          id="defaultModal7"
          tabIndex="-1"
          aria-hidden="true"
          className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
        >
          <div className="relative p-4 w-full max-w-2xl m-auto h-full md:h-auto">
            {/* Modal content */}
            <div className="relative  top-10 bg-white shadow dark:bg-gray-700">
              {/* Modal header  */}
              <div className="text-end">
                <button
                  onClick={toggleAllThatApply}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal7"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body  */}
              <h4 className="text-center text-lg font-semibold">
                All That Apply Interactive Types
              </h4>
              {moduleContent?.allThatApplyInteractiveTypes?.map(
                (apply, index) => {
                  return (
                    <div>
                      <div className="py-4 px-6">
                        <h2 className="font-semibold capitalize">
                          {apply?.title}
                        </h2>
                        <p className="text-sm">{` Question ${index + 1}`}</p>
                        <h4 className="font-semibold">{apply?.question}</h4>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="checkbox"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">{apply?.optionOne}</span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="checkbox"
                            className=" text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">{apply?.optionTwo}</span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="checkbox"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">{apply?.optionThree}</span>
                        </div>
                        <div className="border rounded p-2 mb-1 hover:bg-green-200 md:w-1/2 w-3/4">
                          <input
                            type="checkbox"
                            className="text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="test"
                            value=""
                          />
                          <span className="ml-2">{apply?.optionFour}</span>
                        </div>
                        {apply?.answers?.map((answer) => (
                          <p className="font-bold">
                            Answer :{" "}
                            <span className="text-green-600"> {answer} </span>
                          </p>
                        ))}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export { CourseCard };
