import { Link } from "react-router-dom";
import errimg from '../../assets/images/error.png';
import defaultLogo from "../../assets/images/unifyLogo.png";


const NotFound = () =>
{
    return (
<div className="w-full h-screen overflow-hidden bg-black">
      <img src={defaultLogo} alt="Logo" className="ml-3 md:ml-[50px] mt-4" />            
      <div className=''>
                <div className='flex flex-wrap items-center justify-center min-h-screen'>
                    <div className='flex-wrap items-center mt-[-40px] justify-center w-full max-w-sm'>
                        <div className="px-8 pt-8 pb-8 mb-4 text-center bg-white rounded not-found mx-2">
                            
                            <div className="flex flex-wrap justify-center "><img className="max-w-sm" src={ errimg } width={ 120 } height={ 120 } alt='' /></div>
                            <div>
                                <h2 className="text-lg">OOOps</h2>
                                <p>This page cannot be found</p>
                            </div>
                            <div className="mt-5">


                                <Link to="/dashboard">
                                    <button type="submit"
                                        className="relative flex justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md group hover:bg-[#88062f] bg-[#b61046] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Back to the homepage...
                                    </button>
                                </Link>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
};

export { NotFound };
