import React, { useState,useEffect } from 'react';
import defaultLogo from "../../assets/images/unifyLogo.png";
import {EyeInvisibleOutlined, EyeOutlined,LikeOutlined } from "@ant-design/icons";
import {useForm} from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import {useLocation,Link,useNavigate} from "react-router-dom";
import { useRequest } from "../../hooks/useRequest";
import { Error, Spinner } from "../../components";
import {
  conPwdResetInit,
  conPwdResetSchema,
  axiosApiInstanceGet,
  API_URL_V1,
  refreshTokenKey,
  tokenKey,
} from "../../utils";

function ResetPassword ()
{
  const [ open, setOpen ] = useState( false );
  const [resetSuccessFul, setResetSuccessful] =useState(false);


  //handle toggle
  const toggle = () =>
  {
    setOpen( !open );
  };

  const {search} = useLocation();

  const queryData = new URLSearchParams(search);
  const queryEmail = queryData.get("emailAddress");
  const queryToken = queryData.get("confirmationToken");


  const { err, status, setPending, setRejected } = useRequest();
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem(tokenKey);
    const refreshToken = localStorage.getItem(refreshTokenKey);

    if (token && refreshToken) {
      navigate("/dashboard")
    }
  }, [navigate]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: conPwdResetInit,
    resolver: yupResolver(conPwdResetSchema),
  });

  const onComplete = async ({newPassword}) => {
    setPending();
    try {
      await  axiosApiInstanceGet(`${API_URL_V1}/Auth/complete-password-reset?email=${queryEmail}&resetToken=${encodeURIComponent(queryToken)}&newPassword=${newPassword}`);
      reset();
      setResetSuccessful(true);
    } catch (error) {
      setRejected(error);
    }
  };

  return (
    <div className="w-full h-screen overflow-hidden bg-black">
      <img src={defaultLogo} alt="Logo" className="ml-3 md:ml-[50px] mt-4" />
      <div className='ResetPassword'>
        <div className='flex flex-wrap items-center justify-center min-h-screen'>
          <div className='flex-wrap items-center mt-[-60px] justify-center w-full max-w-sm'>
          <Error err={err} status={status} />
          { !resetSuccessFul &&  <form onSubmit={handleSubmit(onComplete)} className="px-8 pt-8 pb-8 mb-4 bg-white mx-2 rounded shadow-md">
              
              <div className='mb-6 text-lg font-medium '>Reset Password</div>
              <div className='mb-6'>
                <label htmlFor={`newPassword`} className='block mb-2 text-sm font-medium text-gray-400'>
                  Enter Password
                </label>
                <div className='relative'>
                  <div>
                    <input
                      id={`newPassword`}
                      {...register("newPassword")}
                      type={ ( open === false ) ? 'password' : 'text' }
                      className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      placeholder="Password"
                    />
<output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                        {errors?.newPassword?.message}
                      </output>                   </div>
                  <div className='absolute top-1 right-5'>
                    {
                      ( open === false ) ? <EyeOutlined onClick={ toggle } /> :
                        <EyeInvisibleOutlined onClick={ toggle } />

                    }
                  </div>
                </div>

              </div>
              <div className='mb-6'>
                <label htmlFor={`conNewPassword`} className='block mb-2 text-sm font-medium text-gray-400'>
                  Confirm Password
                </label>
                <div className='relative'>
                  <div>
                    <input
                       id={`conNewPassword`}
                       {...register("conNewPassword")}
                      type={ ( open === false ) ? 'password' : 'text' }
                      required
                      className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      placeholder="Password"
                    />
                    <output className="absolute bottom-0 left-0 text-xs italic text-red-500">
                        {errors?.conNewPassword?.message}
                      </output>    
                  </div>
                  <div className='absolute top-1 right-5'>
                    {
                      ( open === false ) ? <EyeOutlined onClick={ toggle } /> :
                        <EyeInvisibleOutlined onClick={ toggle } />

                    }
                  </div>
                </div>
              </div>

              <div className="mt-5">
                  <button
                    type="submit"
                    className="relative flex items-center justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md group hover:bg-[#88062f] bg-[#b61046] focus:outline-none focus:ring-2 focus:ring-offset-2 "
                  >
                   Submit
                  <Spinner
                  className="!w-3  !h-3 mx-1 text-white fill-pink-400"
                  status={status}
                />
                  </button>
             </div>
            </form>}
            {resetSuccessFul && 
                <div className="px-8 pt-8 pb-8 mb-4 text-center bg-white rounded not-found mx-2">
                            
                <div>
                    <h2 className="text-lg"><LikeOutlined style={{fontSize:"120px"}}/></h2>
                    <p>Reset Password Successful</p>
                </div>
                <div className="mt-5">


                    <Link to="/login">
                        <button type="submit"
                            className="relative flex justify-center w-full px-4 py-3 text-sm font-medium text-white border border-transparent rounded-md group hover:bg-[#88062f] bg-[#b61046] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                           Please Login to your Account Here
                        </button>
                    </Link>

                </div>

            </div>
              }
          </div>
        </div>
      </div>

    </div>

  );
}

export { ResetPassword };
