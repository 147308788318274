import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL_V1, axiosApiInstanceGet, FETCH_STATUS } from "../../utils";
import addDays from "date-fns/addDays";

const pageSize = 10;
const initialState = {
  discountList: [],
  discountListErr: "",
  discountListStatus: FETCH_STATUS.IDLE,
  singleDiscount: {},
  singleDiscountErr: "",
  singleDiscountStatus: FETCH_STATUS.IDLE,
  transactionList: [],
  transactionListErr: "",
  transactionListStatus: FETCH_STATUS.IDLE,
  settlementList: [],
  settlementListErr: "",
  settlementListStatus: FETCH_STATUS.IDLE,
  transactionByStudent: [],
  transactionByStudentErr: "",
  transactionByStudentStatus: FETCH_STATUS.IDLE,
  settlementByInstructor: [],
  settlementByInstructorErr: "",
  settlementByInstructorStatus: FETCH_STATUS.IDLE,
  startDate: addDays(new Date(), -2).toISOString(),
  endDate: new Date().toISOString(),
};

export const getDiscountList = createAsyncThunk(
  "getDiscountList",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { force, page } = {
        force: false,
        page: 1,
        ...config,
      };
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/DiscountCodes/list-discount-codes?page=${page}&perPage=${pageSize}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const getSingleDiscount = createAsyncThunk(
  "getSingleDiscount",
  async (discountId, { fulfillWithValue, rejectWithValue }) => {
    try {
      let force = true;
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/DiscountCodes/get-discount-code-by-id?id=${discountId}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllTransaction = createAsyncThunk(
  "getAllTransaction",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    const { force, page, start, end } = {
      force: config?.force || false,
      page: config?.page || 1,
      start: config?.start || initialState.startDate,
      end: config?.end || initialState.endDate,
    };
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Transactions/list-all-transactions?page=${page}&perPage=${pageSize}&dateFrom=${start}&dateTo=${end}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllSettlement = createAsyncThunk(
  "getAllSettlement",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    const { force, page, start, end } = {
      force: config?.force || false,
      page: config?.page || 1,
      start: config?.start || initialState.startDate,
      end: config?.end || initialState.endDate,
    };
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Transactions/list-all-settlements?page=${page}&perPage=${pageSize}&dateFrom=${start}&dateTo=${end}`,
        force
      );
      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getTransactionByStudent = createAsyncThunk(
  "getTransactionByStudent",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    const { studentId, force, page, start, end } = {
      force: config?.force || false,
      page: config?.page || 1,
      start: config?.start || initialState.startDate,
      end: config?.end || initialState.endDate,
      ...config,
    };

    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Transactions/list-all-transactions-by-student?userId=${studentId}&page=${page}&perPage=${pageSize}&dateFrom=${start}&dateTo=${end}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSettlementByInstructor = createAsyncThunk(
  "getSettlementByInstructor",
  async (config, { fulfillWithValue, rejectWithValue }) => {
    const { instructorId, force, page, start, end } = {
      force: config?.force || false,
      page: config?.page || 1,
      start: config?.start || initialState.startDate,
      end: config?.end || initialState.endDate,
      ...config,
    };

    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Transactions/list-all-settlements-by-instructor?instructorId=${instructorId}&page=${page}&perPage=${pageSize}&dateFrom=${start}&dateTo=${end}`,
        force
      );

      return fulfillWithValue(response?.data?.data);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setStartTransaction: (state, action) => {
      state.startDate = new Date(action.payload).toISOString();
    },
    setEndTransaction: (state, action) => {
      state.endDate = new Date(action.payload).toISOString();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDiscountList.pending, (state) => {
        state.discountListStatus = FETCH_STATUS.PENDING;
        state.discountListErr = "";
      })
      .addCase(getDiscountList.fulfilled, (state, action) => {
        state.discountListStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.discountList = pagedList;
        state.discountListErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getDiscountList.rejected, (state, action) => {
        state.discountListStatus = FETCH_STATUS.REJECTED;
        state.discountListErr = action.payload;
      })
      .addCase(getSingleDiscount.pending, (state) => {
        state.singleDiscountStatus = FETCH_STATUS.PENDING;
        state.singleDiscountErr = "";
      })
      .addCase(getSingleDiscount.fulfilled, (state, action) => {
        state.singleDiscountStatus = FETCH_STATUS.RESOLVED;
        state.singleDiscount = action.payload;
        state.singleDiscountErr = "";
      })
      .addCase(getSingleDiscount.rejected, (state, action) => {
        state.singleDiscountStatus = FETCH_STATUS.REJECTED;
        state.singleDiscountErr = action.payload;
      })
      .addCase(getAllTransaction.pending, (state) => {
        state.transactionListStatus = FETCH_STATUS.PENDING;
        state.transactionListErr = "";
      })
      .addCase(getAllTransaction.fulfilled, (state, action) => {
        state.transactionListStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.transactionList = pagedList;
        state.transactionListErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getAllTransaction.rejected, (state, action) => {
        state.transactionListStatus = FETCH_STATUS.REJECTED;
        state.transactionListErr = action.payload;
      })
      .addCase(getAllSettlement.pending, (state) => {
        state.settlementListStatus = FETCH_STATUS.PENDING;
        state.settlementListErr = "";
      })
      .addCase(getAllSettlement.fulfilled, (state, action) => {
        state.settlementListStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.settlementList = pagedList;
        state.settlementListErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getAllSettlement.rejected, (state, action) => {
        state.settlementListStatus = FETCH_STATUS.REJECTED;
        state.settlementListErr = action.payload;
      })
      .addCase(getTransactionByStudent.pending, (state) => {
        state.transactionByStudentStatus = FETCH_STATUS.PENDING;
        state.transactionByStudentErr = "";
      })
      .addCase(getTransactionByStudent.fulfilled, (state, action) => {
        state.transactionByStudentStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.transactionByStudent = pagedList;
        state.transactionByStudentErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getTransactionByStudent.rejected, (state, action) => {
        state.transactionByStudentStatus = FETCH_STATUS.REJECTED;
        state.transactionByStudentErr = action.payload;
      })
      .addCase(getSettlementByInstructor.pending, (state) => {
        state.settlementByInstructorStatus = FETCH_STATUS.PENDING;
        state.settlementByInstructorErr = "";
      })
      .addCase(getSettlementByInstructor.fulfilled, (state, action) => {
        state.settlementByInstructorStatus = FETCH_STATUS.RESOLVED;
        const { metaData, pagedList } = action.payload;
        state.settlementByInstructor = pagedList;
        state.settlementByInstructorErr = "";
        state.page = metaData?.page;
        state.totalPages = metaData?.totalPages;
      })
      .addCase(getSettlementByInstructor.rejected, (state, action) => {
        state.settlementByInstructorStatus = FETCH_STATUS.REJECTED;
        state.settlementByInstructorErr = action.payload;
      });
  },
});

export const transactionReducer = transactionSlice.reducer;
export const selectTransaction = (state) => state.transaction;
export const { setStartTransaction, setEndTransaction } =
  transactionSlice.actions;
