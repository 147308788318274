import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Table, Button, Modal, Form, Input, Spin, message } from 'antd'
import {
  API_URL_V1,
  axiosApiInstance,
  axiosApiInstanceGet,
  formatDateTime,
} from '../../utils'
import { AppFooter, NavBar, PrivateRoute } from '../../components'
import { Link } from 'react-router-dom'

const AddEntryForm = ({ visible, onClose, onSubmit, loading }) => {
  const [form] = Form.useForm()

  const handleFinish = async (values) => {
    await onSubmit(values)
    form.resetFields()
    onClose()
  }

  return (
    <Modal
      title="Add New Entry"
      visible={visible}
      onCancel={onClose}
      footer={null}
      style={{ top: 20 }}
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input a name!' }]}
        >
          <Input />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          style={{
            backgroundColor: '#b61046',
            border: 'none',
            borderRadius: '4px',
            color: 'white',
          }}
        >
          Submit
        </Button>
      </Form>
    </Modal>
  )
}

const EditEntryForm = ({ visible, onClose, record, onSubmit, loading }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ name: record.name })
    }
  }, [record, form])

  const handleFinish = async (values) => {
    await onSubmit(values)
    onClose()
  }

  return (
    <Modal
      title="Edit Entry"
      visible={visible}
      onCancel={onClose}
      footer={null}
      style={{ top: 20 }}
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input a name!' }]}
        >
          <Input />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
          style={{
            backgroundColor: '#b61046',
            border: 'none',
            borderRadius: '4px',
            color: 'white',
          }}
        >
          Submit
        </Button>
      </Form>
    </Modal>
  )
}

const ViewEntryForm = ({ visible, onClose, record }) => {
  return (
    <Modal
      title="View Entry"
      visible={visible}
      onCancel={onClose}
      footer={null}
      style={{ top: 20 }}
    >
      <p>
        <strong>Name:</strong> {record?.name}
      </p>
    </Modal>
  )
}

const ReferralListPage = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [addModalVisible, setAddModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [viewModalVisible, setViewModalVisible] = useState(false)
  const [currentRecord, setCurrentRecord] = useState(null)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteRecordId, setDeleteRecordId] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const fetchMailingList = useCallback(async (page = 1) => {
    setLoading(true)
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/HowDidYouHearAboutUs/get-entries?page=${page}`,
        true
      )
      setData(response.data)
      setTotalPages(response.data.totalPages)
    } catch (error) {
      setError(error.message)
      console.error('Fetch mailing list error:', error) // Log error details
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchMailingList(page)
  }, [fetchMailingList, page])

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Date Created',
        dataIndex: 'dateCreated',
        key: 'dateCreated',
        render: (record) => formatDateTime(record),
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <>
            <Button
              onClick={() => openViewModal(record)}
              style={{ marginRight: 10 }}
            >
              View
            </Button>
            <Button onClick={() => openEditModal(record)}>Edit</Button>
            <Button
              danger
              onClick={() => confirmDelete(record.id)}
              style={{ marginLeft: 10 }}
            >
              Delete
            </Button>
          </>
        ),
      },
    ],
    []
  )

  const openAddModal = () => {
    setAddModalVisible(true)
  }

  const openEditModal = (record) => {
    setCurrentRecord(record)
    setEditModalVisible(true)
  }

  const openViewModal = (record) => {
    setCurrentRecord(record)
    setViewModalVisible(true)
  }

  const handleAddSubmit = async (values) => {
    setSubmitting(true)
    try {
      await axiosApiInstance.post(
        `${API_URL_V1}/HowDidYouHearAboutUs/add-entry?name=${values.name}`
      )
      message.success('Entry added successfully')
      fetchMailingList(page)
    } catch (error) {
      message.error('Operation failed: ' + error.message)
      console.error('Add entry error:', error) // Log error details
    } finally {
      setSubmitting(false)
    }
  }

  const handleEditSubmit = async (values) => {
    setSubmitting(true)
    try {
      await axiosApiInstance.put(
        `${API_URL_V1}/HowDidYouHearAboutUs/update-entry?id=${currentRecord.id}&name=${values.name}`
      )
      message.success('Entry updated successfully')
      fetchMailingList(page)
    } catch (error) {
      message.error('Operation failed: ' + error.message)
      console.error('Edit entry error:', error) // Log error details
    } finally {
      setSubmitting(false)
    }
  }

  const confirmDelete = (id) => {
    setDeleteRecordId(id)
    setDeleteModalVisible(true)
  }

  const handleDelete = async () => {
    try {
      await axiosApiInstance.delete(
        `${API_URL_V1}/HowDidYouHearAboutUs/${deleteRecordId}`
      )
      message.success('Entry deleted successfully')
      fetchMailingList(page)
    } catch (error) {
      message.error('Delete operation failed: ' + error.message)
      console.error('Delete entry error:', error) // Log error details
    } finally {
      setDeleteModalVisible(false)
      setDeleteRecordId(null)
    }
  }

  const renderContent = () => {
    if (loading) {
      return <Spin />
    }

    if (error) {
      return <div>Error: {error}</div>
    }

    return (
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{
          current: page,
          total: totalPages * 10,
          onChange: (page) => setPage(page),
        }}
      />
    )
  }

  return (
    <PrivateRoute redirectTo="/mailing-list">
      <div className="min-h-fit">
        <NavBar />
        <div className="bg-[#eaeaea] h-[90px] w-full relative top-4 md:top-12 md:overflow-hidden">
          <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
            {['Active Students', 'Deactivated Students', 'Mailing List'].map(
              (text, idx) => (
                <span key={idx} className="ml-10 mt-10 md:mt-0">
                  <Link
                    className="text-black hover:text-[#b61046]"
                    to={`/${text.replace(/\s/g, '').toLowerCase()}`}
                  >
                    {text}
                  </Link>
                </span>
              )
            )}
            <span className="ml-10 mt-10 md:mt-0">Referral List</span>
            <hr className="w-[90px] mt-16 md:mt-11 h-[2px] ml-[-80px] bg-[#b61046]" />
          </div>
        </div>
        <div className="p-6">
          <div className="mb-4 mt-20 flex justify-between items-center">
            <h1 className="text-2xl font-bold">Referral List</h1>
            <Button
              type="primary"
              onClick={openAddModal}
              loading={submitting}
              disabled={submitting}
              style={{
                backgroundColor: '#b61046',
                border: 'none',
                borderRadius: '4px',
                color: 'white',
              }}
            >
              Add New Entry
            </Button>
          </div>
          {renderContent()}

          {/* Modals for Adding, Editing, and Viewing Entries */}
          <AddEntryForm
            visible={addModalVisible}
            onClose={() => setAddModalVisible(false)}
            onSubmit={handleAddSubmit}
            loading={submitting}
          />
          <EditEntryForm
            visible={editModalVisible}
            onClose={() => setEditModalVisible(false)}
            record={currentRecord}
            onSubmit={handleEditSubmit}
            loading={submitting}
          />
          <ViewEntryForm
            visible={viewModalVisible}
            onClose={() => setViewModalVisible(false)}
            record={currentRecord}
          />
          <Modal
            title="Confirm Deletion"
            visible={deleteModalVisible}
            onOk={handleDelete}
            onCancel={() => setDeleteModalVisible(false)}
            okButtonProps={{ danger: true }}
          >
            <p>Are you sure you want to delete this entry?</p>
          </Modal>
        </div>
        <AppFooter />
      </div>
    </PrivateRoute>
  )
}

export { ReferralListPage }
