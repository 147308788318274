import React, { useState, useEffect } from 'react'
import { Button, Table, Modal, Form, Input, Select } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { API_URL_V1, axiosApiInstance } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { getCourseList, selectCourse } from '../../redux/slices'

const { Option } = Select

const CourseEmailManager = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false)
  const [emailData, setEmailData] = useState([])
  const [emailContent, setEmailContent] = useState('')
  const [status, setStatus] = useState('draft')
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [previewEmail, setPreviewEmail] = useState('')
  const [previewSubject, setPreviewSubject] = useState('')
  const [loadingPreview, setLoadingPreview] = useState(false)

  const dispatch = useDispatch()
  const { courseList } = useSelector(selectCourse)

  useEffect(() => {
    dispatch(getCourseList())
    fetchEmailTemplates()
  }, [dispatch])

  const fetchEmailTemplates = async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL_V1}/CourseEmail/get-all-course-email-template?perPage=100&page=1`
      )
      const templates = response.data.data.pagedList.map((template) => ({
        courseTitle: template.courseName,
        paymentStatus: template.courseEmailType,
        mailStatus: template.status,
        content: template.content,
        id: template.id,
      }))
      setEmailData(templates)
    } catch (error) {
      console.error('Error fetching email templates:', error)
    }
  }

  const handleModalVisibility = (visible) => {
    setIsModalVisible(visible)
    if (!visible) {
      resetModalState()
    }
  }

  const handlePreviewModalVisibility = (visible) => {
    setIsPreviewModalVisible(visible)
    if (!visible) {
      resetPreviewState()
    }
  }

  const resetModalState = () => {
    setSelectedEmail(null)
    setEmailContent('')
  }

  const resetPreviewState = () => {
    setPreviewEmail('')
    setPreviewSubject('')
  }

  const handleSubmit = async (values) => {
    const courseEmailType =
      values.paymentStatus === 'course_completed' ? 'Completed' : 'Started'

    const newEmail = {
      ...values,
      content: emailContent,
      mailStatus: status === 'draft' ? 'draft' : 'published',
      dateCreated: new Date().toISOString(),
    }

    if (selectedEmail) {
      await handleUpdate(values, newEmail)
    } else {
      await handleCreate(values, newEmail, courseEmailType)
    }
  }

  const handleCreate = async (values, newEmail, courseEmailType) => {
    setEmailData((prev) => [...prev, newEmail])
    handleModalVisibility(false)

    const apiPayload = {
      courseId: values.courseTitle,
      status: newEmail.mailStatus,
      content: emailContent,
      courseEmailType,
    }

    try {
      const response = await axiosApiInstance.post(
        `${API_URL_V1}/CourseEmail/add-course-email-template`,
        apiPayload
      )
      console.log('API Response:', response.data)
    } catch (error) {
      console.error('Error while sending the email template:', error)
    }
  }

  const handleUpdate = async (values, updatedEmail) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL_V1}/CourseEmail/update-course-email-template`,
        { ...updatedEmail, id: selectedEmail.id }
      )

      setEmailData((prevData) =>
        prevData.map((email) =>
          email.id === updatedEmail.id ? response.data : email
        )
      )

      handleModalVisibility(false)
    } catch (error) {
      console.error('Error while updating the email template:', error)
    }
  }

  const handleDelete = async (emailId) => {
    try {
      await axiosApiInstance.delete(`${API_URL_V1}/CourseEmail/${emailId}`)
      setEmailData((prevData) =>
        prevData.filter((email) => email.id !== emailId)
      )
    } catch (error) {
      console.error('Error while deleting the email template:', error)
    }
  }

  const handleSendPreview = async () => {
    setLoadingPreview(true)

    let contentToSend = emailContent

    // Check if email content is empty and selectedEmail exists
    if (!contentToSend && selectedEmail) {
      try {
        // Fetch the email content based on the selectedEmail ID
        const response = await axiosApiInstance.get(
          `${API_URL_V1}/CourseEmail/${selectedEmail.id}` // Fetch the email template by ID
        )
        contentToSend = response?.data.content // Assuming 'content' is the field in the response
      } catch (error) {
        console.error('Error fetching email content:', error)
        setLoadingPreview(false)
        return
      }
    }

    const apiPayload = {
      email: previewEmail,
      template: contentToSend,
      subject: previewSubject,
    }

    try {
      const response = await axiosApiInstance.post(
        `${API_URL_V1}/CourseEmail/preview`,
        apiPayload
      )
      console.log('Preview email sent successfully:', response.data)
    } catch (error) {
      console.error('Error sending preview email:', error)
    } finally {
      setLoadingPreview(false)
    }
  }

  const columns = [
    {
      title: 'Course',
      dataIndex: 'courseTitle',
      key: 'courseTitle',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
    {
      title: 'Mail Status',
      dataIndex: 'mailStatus',
      key: 'mailStatus',
    },
    {
      title: 'Edit / View',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button
            onClick={() => {
              setSelectedEmail(record)
              handleModalVisibility(true)
            }}
          >
            Edit / View
          </Button>
          <Button
            danger
            onClick={() => {
              Modal.confirm({
                title: 'Are you sure you want to delete this email template?',
                onOk: () => handleDelete(record.id),
              })
            }}
            style={{ marginLeft: 8 }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ]

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  return (
    <div className="px-10">
      <div className="flex items-center justify-end w-full mt-[100px] mb-[50px]">
        <Button
          onClick={() => handleModalVisibility(true)}
          style={{
            backgroundColor: '#b61046',
            border: 'none',
            borderRadius: '4px',
            color: 'white',
          }}
        >
          Create template
        </Button>
      </div>

      <Table columns={columns} dataSource={emailData} />

      <Modal
        title={
          selectedEmail ? 'Edit Email Template' : 'Create New Email Template'
        }
        visible={isModalVisible}
        onCancel={() => handleModalVisibility(false)}
        footer={null}
      >
        <Form
          initialValues={
            selectedEmail
              ? {
                  courseTitle: selectedEmail.courseTitle,
                  paymentStatus: selectedEmail.paymentStatus,
                  emailContent: selectedEmail.content,
                }
              : {}
          }
          onFinish={handleSubmit}
        >
          <Form.Item
            name="paymentStatus"
            label="Payment Status"
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="successful_payment">Successful Payment</Option>
              <Option value="course_completed">Course Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="courseTitle"
            label="Course Title"
            rules={[{ required: true }]}
          >
            <Select>
              {courseList.map((course) => (
                <Option key={course.courseId} value={course.courseId}>
                  {course.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="emailContent" label="Email Content">
            <ReactQuill
              theme="snow"
              value={emailContent}
              onChange={setEmailContent}
              modules={modules}
              formats={formats}
              style={{ height: '200px', marginBottom: '50px' }}
            />
          </Form.Item>
          <Form.Item>
            <div className="flex mt-4 justify-end items-center w-full space-x-4">
              {!selectedEmail && (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: '#b61046',
                    border: 'none',
                    borderRadius: '4px',
                    color: 'white',
                  }}
                  onClick={() => setStatus('draft')}
                >
                  Save to Draft
                </Button>
              )}
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#b61046',
                  border: 'none',
                  borderRadius: '4px',
                  color: 'white',
                }}
                onClick={() => setStatus('published')}
              >
                {selectedEmail ? 'Update' : 'Create'}
              </Button>
              <Button
                onClick={() => handlePreviewModalVisibility(true)}
                type="default"
              >
                Preview
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Preview Email"
        visible={isPreviewModalVisible}
        onCancel={() => handlePreviewModalVisibility(false)}
        footer={null}
      >
        <Form onFinish={handleSendPreview}>
          <Form.Item
            name="subject"
            label="Subject"
            rules={[{ required: true }]}
          >
            <Input
              value={previewSubject}
              onChange={(e) => setPreviewSubject(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: 'email' }]}
          >
            <Input
              value={previewEmail}
              onChange={(e) => setPreviewEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: '#b61046',
                border: 'none',
                borderRadius: '4px',
                color: 'white',
              }}
              loading={loadingPreview}
              disabled={loadingPreview}
            >
              Send Preview
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default CourseEmailManager
