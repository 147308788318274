import React from 'react'
import { useTable, useGlobalFilter, useSortBy } from 'react-table'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { SearchFilter2 } from '../UI'
import styles from '../UI/ResponsiveTable.module.css'
import { EyeOutlined } from '@ant-design/icons'
// import {formatDateTime} from "../../utils";
import defaultImage from '../../assets/images/defaultImage.png'

const AwaitingApprovalTable = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Profile',
        Cell: ({ cell }) => {
          const profileImage = cell?.row?.original.imageUrl
          return (
            <div className="flex items-center">
              <div
                key={profileImage}
                className="ml-[50px] phone-md:ml-[110px] md:ml-96 lg:ml-0 w-[2rem] h-[2rem] rounded-2xl bg-no-repeat bg-contain bg-center bg-white "
                style={{
                  backgroundImage: `url("${profileImage || defaultImage}")`,
                }}
              ></div>
              <p className="ml-2">{cell?.row?.original?.name}</p>
            </div>
          )
        },
      },

      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Duration',
        accessor: 'totalNumberOfSeconds',
      },

      // {
      //   Header:"Instructor",
      //   accessor:"instructors",
      //   Cell:({value}) => {
      //    const instructorBio = value.map((instructor)=>{
      //      return (
      //       <div className="flex items-center">
      //       <div
      //   key={instructor?.id}
      //   className="ml-[110px] md:ml-0 w-[2rem] h-[2rem] rounded-lg bg-no-repeat bg-contain bg-center  bg-white "
      //   style={{
      //     backgroundImage: `url("${instructor?.profileImageUrl || defaultImage}")`,
      //   }}
      // ></div>
      // <p className="ml-2">{instructor?.name}</p>
      // </div>
      //      )
      //    })
      //    return <div>{instructorBio}</div>;
      //   }
      // },
      // {
      //   Header: "Date",
      //   accessor: "dateCreated",
      //   Cell: (props) => {
      //     return (
      //       formatDateTime(new Date(props?.value), "LLLL do, yyyy. hh:mm a") ||
      //       "N/A"
      //     );
      //   },
      // },
      {
        Header: 'Action',
        Cell: ({ cell }) => {
          const item = cell?.row?.original
          return (
            <div className="lg:flex items-center">
              <Link
                className="m-1 md:ml-96 lg:ml-1 text-black"
                to={`/pendingCourseDetail/${item.courseId}`}
              >
                <EyeOutlined />
                <span className=" phone-xs:ml-[180px] phone-md:ml-[200px] md:ml-[500px] lg:-m-1 text-[#c43d68]">
                  View
                </span>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy)

  const { globalFilter } = state

  return (
    <div className="relative mt-4 mb-12 shadow-md sm:rounded-sm md:overflow-hidden ">
      <div className="bg-[#eaeaea] h-[90px] w-full relative top-4 md:top-12 md:overflow-hidden ">
        <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
          <span className="ml-6 md:ml-10 mt-10 md:mt-0">
            Awaiting Approvals
            <hr className="w-[70px] mt-2 md:mt-6 h-[2px] ml-4 bg-[#b61046]" />
          </span>
          <span className="ml-10 mt-10 md:mt-0">
            <Link className="text-black hover:text-[#b61046]" to="/courses">
              Courses
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">
            <Link className="text-black hover:text-[#b61046]" to="/pricing">
              Course Pricing
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">
            <Link
              className="text-black hover:text-[#b61046]"
              to="/course-email"
            >
              Course Email
            </Link>
          </span>
        </div>
        <div className="bg-[#eaeaea] md:ml-[400px] lg:ml-[720px] md:mt-[-70px] md:w-1/2">
          <SearchFilter2 filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>

      <div className="grid grid-rows-2 grid-flow-col gap-4 mt-14 ml-6">
        <div className="row-start-1">
          <h2 className="font-semibold text-lg mt-12 md:mt-5 ml-3 md:ml-12">
            Awaiting Approvals
          </h2>
        </div>
      </div>

      <table
        {...getTableProps()}
        className={classNames(
          'w-[90%] lg:w-[1200px] m-auto mt-[-70px] md:mt-[-30px] text-sm text-left text-gray-500',
          styles.table
        )}
      >
        <thead className="text-xs text-gray-400 capitalize bg-gray-50">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers?.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-6 py-3"
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className="bg-white rounded-2xl shadow-md border p-4"
              >
                {row.cells?.map((cell) => {
                  return (
                    <td
                      data-label={cell?.column?.Header}
                      {...cell.getCellProps()}
                      className="px-6 py-4 text-gray-900 text-xs min-w-min"
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export { AwaitingApprovalTable }
