import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import {
  NavBar,
  AppFooter,
  Loading,
  Error,
  Success,
  Pagination,
  PrivateRoute,
} from '../../components'
import { MailingListTable } from '../../components/Students/MailingListTable'
import { API_URL_V1, axiosApiInstanceGet, FETCH_STATUS } from '../../utils'

const MailingListPage = () => {
  const [state, setState] = useState({
    mailingList: [],
    error: null,
    status: FETCH_STATUS.IDLE,
    page: 1,
    totalPages: 1,
  })

  const fetchMailingList = useCallback(async (page = 1) => {
    setState((prev) => ({ ...prev, status: FETCH_STATUS.PENDING }))
    try {
      const response = await axiosApiInstanceGet(
        `${API_URL_V1}/Mailing/get-mailing-list?page=${page}`,
        true
      )
      setState({
        mailingList: response.data,
        status: FETCH_STATUS.RESOLVED,
        error: null,
        page: response.data.currentPage,
        totalPages: response.data.totalPages,
      })
    } catch (error) {
      setState({
        mailingList: [],
        status: FETCH_STATUS.REJECTED,
        error: error.message,
        page: 1,
        totalPages: 1,
      })
    }
  }, [])

  useEffect(() => {
    fetchMailingList()
  }, [fetchMailingList])

  return (
    <PrivateRoute redirectTo="/mailing-list">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <section>
          <Loading status={state.status} />
          <Error
            retry={() => fetchMailingList(state.page)}
            err={state.error}
            status={state.status}
          />
          <Success status={state.status}>
            <MailingListTable data={state.mailingList} />
          </Success>
          <Success status={state.status}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() => fetchMailingList(Math.max(state.page - 1, 1))}
                next={() =>
                  fetchMailingList(Math.min(state.page + 1, state.totalPages))
                }
                goTo={(page) => fetchMailingList(page)}
                currentPage={state.page}
                totalPages={state.totalPages}
                isFirst={state.page <= 1}
                isLast={state.page >= state.totalPages}
              />
            </div>
          </Success>
        </section>
        <AppFooter />
      </div>
    </PrivateRoute>
  )
}

export { MailingListPage }
