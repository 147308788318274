import { useEffect } from "react";
import {Loading,Success,Error} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getInstructorDashboardStat,selectInstructor } from "../../redux/slices";


const CardDbInstructor = () => {

  const {  instructorDashboard,instructorDashboardErr,instructorDashboardStatus } = useSelector(selectInstructor);

  const dispatch = useDispatch();
  const {instructorId} = useParams();

  useEffect(() => {
    dispatch(getInstructorDashboardStat({instructorId,force:true}));
  }, [dispatch,instructorId]);

  const instructorRetry = () => {
    if (instructorDashboardErr) {
      dispatch(getInstructorDashboardStat(instructorId));
    }
  };


  return (
    <div >
      <Loading status={instructorDashboardStatus} />
              <Error
                retry={instructorRetry}
                err={instructorDashboardErr}
                status={instructorDashboardStatus}
              />
              <Success status={instructorDashboardStatus}></Success>
          <div className="grid  gap-10 md:gap-0 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 ml-[-10px] mr-12 md:ml-0">
            <div className="flex items-center border mb-2 md:ml-8  bg-white rounded shadow-sm justify-between p-5 w-[85%] lg:w-full h-[80px]">
              <div>
                <div className="text-2xl font-medium text-gray-600">{instructorDashboard.numberOfCoursesCreated}</div>
                <div className="text-sm text-gray-400 ">Courses Created</div>
              </div>
            </div>

            <div className="flex items-center border mb-2 ml-[-14px] md:ml-12 bg-white rounded shadow-sm justify-between p-5 w-[90%] lg:w-full h-[80px]">
              <div>
                <div className="text-2xl font-medium text-gray-600">{instructorDashboard.numberOfStudents}</div>
                <div className="text-sm text-gray-400 ">Students</div>
              </div>
            </div>
            <div className="flex items-center border md:ml-16 bg-white rounded shadow-sm justify-between p-5 w-[85%] lg:w-full h-[80px]">
              <div>
                <div className="text-2xl font-medium text-gray-600">{instructorDashboard.averageRating}</div>
                <div className="text-sm text-gray-400 ">
                  Average Ratings
                </div>
              </div>
              
            </div>
            <div className="flex items-center border ml-[-12px] md:ml-8 lg:ml-20 bg-white rounded shadow-sm justify-between p-5 w-full h-[80px]">
              <div>
                <div className="text-2xl font-medium text-gray-600">₦{instructorDashboard.totalRevenueGenerated}</div>
                <div className="text-sm text-gray-400 ">
                  Total Revenue Generated
                </div>
              </div>
              
            </div>
          </div>
          <Success/>
    </div>
  );
};

export { CardDbInstructor };
