import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Pagination,
  AppFooter,
  NavBar,
  PrivateRoute,
  DiscountTable,
  Loading,
  Error,
  Success,
} from "../../components";
import { getDiscountList, selectTransaction } from "../../redux/slices";
import clamp from "lodash/clamp";

const DiscountTablePage = () => {
  const dispatch = useDispatch();
  const {
    page,
    totalPages,
    discountList,
    discountListErr,
    discountListStatus,
  } = useSelector(selectTransaction);

  useEffect(() => {
    dispatch(getDiscountList());
  }, [dispatch]);

  const getList = useCallback(
    (force = true, page) => {
      dispatch(getDiscountList({ force, page }));
    },
    [dispatch]
  );

  return (
    <PrivateRoute redirectTo="/discount-code">
      <div className="min-h-fit overflow-hidden ">
        <NavBar />
        <section>
          <Loading status={discountListStatus} />
          <Error
            retry={getList}
            err={discountListErr}
            status={discountListStatus}
          />
          <Success status={discountListStatus}>
            <DiscountTable data={discountList} />
          </Success>
          <Success status={discountListStatus}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() => getList(false, clamp(page - 1, 1, totalPages))}
                next={() => getList(false, clamp(page + 1, 1, totalPages))}
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
        </section>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { DiscountTablePage };
