import React from "react";
import { Spinner } from "./Spinner";
import { FETCH_STATUS } from "../../utils";

const Loading = ({
  status,
  foreFill = "fill-gray-200",
  bgFill = "text-red-600",
  spinnerClass = "w-10 h-10",
  wrapperClass,
  children,
}) => {
  if (status !== FETCH_STATUS.PENDING) {
    return null;
  }
  return children ? (
    children
  ) : (
    <div role="alert">
      <div className={`flex items-center mt-[120px] justify-center ${wrapperClass}`}>
        <Spinner
          status={status}
          className={spinnerClass}
          bgFill={bgFill}
          foreFill={foreFill}
        />
      </div>
    </div>
  );
};

const Error = ({ status, err = "Failure to perform action", retry }) => {
  if (status !== FETCH_STATUS.REJECTED) {
    return null;
  }
  return (
    <div role="alert">
      <div className="flex items-center px-4 mt-[120px] py-2 font-bold text-white bg-red-500 rounded-t ">
        <span> Error</span>
        {retry ? (
          <button
            type="button"
            onClick={retry}
            className="inline-block px-3 py-1 ml-1 text-xs font-bold text-red-500 rounded-md bg-yellow-50"
          >
            Retry
          </button>
        ) : null}
      </div>
      <div className="px-4 py-3 overflow-y-auto text-red-700 bg-red-100 border border-t-0 border-red-400 rounded-b max-h-52 ">
        <code>{JSON.stringify(err, null, 2)}</code>
      </div>
    </div>
  );
};

const Success = ({ status, children }) => {
  if (status !== FETCH_STATUS.RESOLVED) {
    return null;
  }
  return children;
};

export { Loading, Error, Success };
