import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { logout, revokeToken, selectAuth } from "../redux/slices";
import { clearTokenService } from "../utils/tokenService";

const useLogout = () => {
  const { userId } = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appLogout = useCallback(
    (path) => {
      const route = typeof path === "string" ? path : "/login";

      navigate(route, {
        replace: true,
      });
      dispatch(logout());
      clearTokenService();

      dispatch(revokeToken(userId));
    },
    [navigate, dispatch, userId]
  );

  return appLogout;
};

export { useLogout };
