import differenceInSeconds from "date-fns/differenceInSeconds";

class Cache {
  constructor(ttl = 300) {
    this.cache = {};
    this.config = {
      ttl,
    };
  }

  getItem(key) {
    let value = undefined;

    if (this.cache[key]) {
      const now = new Date();
      const createdAt = new Date(this.cache[key].createdAt);
      const ttl = this.cache[key].ttl;

      const diff = differenceInSeconds(now, createdAt);

      if (diff < ttl) {
        value = this.cache[key].value;
      }
    }

    return value;
  }

  addItem(key, val, ttl) {
    this.cache[key] = {
      value: val,
      ttl: ttl || this.config.ttl,
      createdAt: new Date().toISOString(),
    };
  }

  removeItem(key) {
    delete this.cache[key];
  }

  clear() {
    this.cache = {};
    this.config = {};
  }
}

const appCache = new Cache();

export { appCache };
