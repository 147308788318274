import React from 'react'
import { Link } from 'react-router-dom'
import { NavBar } from '../../components'
import CourseEmailManager from '../../components/Courses/CourseEmailManager'

const CourseEmailPage = () => {
  return (
    <div>
      <NavBar />
      <div className="bg-[#eaeaea] h-[90px] w-full relative top-4 md:top-12 md:overflow-hidden ">
        <div className="flex font-semibold ml-[-10px] md:ml-5 md:mt-[40px]">
          <span className="ml-6 md:ml-10 mt-10 md:mt-0">
            <Link
              className="text-black hover:text-[#b61046]"
              to="/pendingCourses"
            >
              Awaiting Approvals{' '}
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">
            {' '}
            <Link className="text-black hover:text-[#b61046]" to="/courses">
              Courses
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">
            <Link className="text-black hover:text-[#b61046]" to="/pricing">
              Course Pricing
            </Link>
          </span>
          <span className="ml-10 mt-10 md:mt-0">Course Email</span>
          <hr className="w-[80px] mt-16 md:mt-11 h-[2px] ml-[-80px] bg-[#b61046]" />
        </div>
      </div>

      {/* body */}
      <div className="mt-8 mx-4">
        <CourseEmailManager />
      </div>
    </div>
  )
}

export default CourseEmailPage
