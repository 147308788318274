import { useCallback, useState } from "react";
import { FETCH_STATUS } from "../utils";

const useRequest = (initData) => {
  const [status, setStatus] = useState(FETCH_STATUS.IDLE);
  const [err, setErr] = useState(null);
  const [data, setData] = useState(initData || null);

  const reset = useCallback(() => {
    setData(null);
    setStatus(FETCH_STATUS.IDLE);
    setErr(null);
  }, []);

  const setPending = useCallback(() => {
    setStatus(FETCH_STATUS.PENDING);
    setErr(null);
  }, []);

  const setRejected = useCallback((err) => {
    setStatus(FETCH_STATUS.REJECTED);
    setErr(err);
  }, []);

  const setResolved = useCallback((data) => {
    setStatus(FETCH_STATUS.RESOLVED);
    setData(data);
  }, []);

  return {
    setPending,
    setResolved,
    setRejected,
    setData,
    reset,
    err,
    status,
    data,
  };
};

export { useRequest };
