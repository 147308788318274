import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  NavBar,
  CardDbStudent,
  CardStudent,
  Loading,
  CardStudent2,
  AppFooter,
  PrivateRoute,
  Pagination,
  Error,
  Success,
  Spinner,
  StudyPercentageTable,
} from "../../components";
import { LeftOutlined } from "@ant-design/icons";
import { useRequest } from "../../hooks";
import {
  getTransactionByStudent,
  getStudentList,
  getStudent,
  getReportList,
  getStudentPercent,
  selectTransaction,
  selectStudent,
} from "../../redux/slices";
import clamp from "lodash/clamp";
import { axiosApiInstance, API_URL_V1, formatDateTime } from "../../utils";

const StudentDetailPage = () => {
  const [showModal, setShowModal] = useState(false);

  const [studentIsActive, setStudentIsActive] = useState(true);

  const toggle = () => {
    setShowModal((s) => !s);
  };

  const { status, setPending, setRejected } = useRequest();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { studentId } = useParams();
  const {
    page,
    totalPages,
    transactionByStudent,
    transactionByStudentErr,
    transactionByStudentStatus,
    startDate,
    endDate,
  } = useSelector(selectTransaction);
  const {
    student,
    studentList,
    reportList,
    reportListErr,
    reportListStatus,
    studentPercentList,
    studentPercentListStatus,
  } = useSelector(selectStudent);

  useEffect(() => {
    dispatch(getStudent(studentId));
    dispatch(getStudentList());
    dispatch(getStudentPercent(studentId));
    dispatch(getTransactionByStudent({ studentId, force: true }));
    dispatch(getReportList({ studentId, force: true }));
  }, [dispatch, studentId]);

  useEffect(() => {
    if (studentList?.length > 0) {
      const selectedStudent = studentList?.find((student) => {
        return student.id === studentId;
      });
      if (!selectedStudent) {
        setStudentIsActive(false);
      }
    }
  }, [studentId, studentList]);

  const getList = useCallback(
    (studentId, force = true, newPage = page) => {
      dispatch(
        getTransactionByStudent({
          studentId,
          force,
          page: newPage,
          start: startDate,
          end: endDate,
        })
      );
    },
    [dispatch]
  );

  const getReport = useCallback(
    (studentId, force = true) => {
      dispatch(getReportList({ studentId, force }));
    },
    [dispatch]
  );

  const ToggleStudent = async () => {
    setPending();

    try {
      await axiosApiInstance.patch(
        `${API_URL_V1}/Students/toggle-account?studentId=${studentId}`
      );
      toggle();
      dispatch(getStudentList());
      navigate(-1);
    } catch (err) {
      setRejected(err);
    }
  };

  return (
    <PrivateRoute redirectTo="/activeStudents">
      <div className="min-h-fit overflow-hidden">
        <NavBar />
        <div className="bg-[#eaeaea] h-[90px] w-full relative top-12 overflow-hidden "></div>
        <div className="w-full h-full relative left-10 top-[90px] ">
          <div className="grid grid-rows-2 grid-flow-col gap-4">
            <div className="row-start-1 md:ml-8">
              <button onClick={() => navigate(-1)}>
                <Link className="text-[#b61046] hover:text-black" to="">
                  <LeftOutlined style={{ fontSize: "12px" }} />
                  <span className="m-[12px]">Back</span>
                </Link>
              </button>

              <h2 className="font-semibold text-lg">
                {student?.firstName} {student?.lastName}
              </h2>
              <p className="text-sm text-gray-400">
                Joined {formatDateTime(new Date(student?.dateCreated))}
              </p>
            </div>

            <button
              onClick={toggle}
              className="row-start-1 ml-[-55px] md:ml-[345px] lg:ml-[635px] text-white mt-[20px] bg-[#B61046] w-[150px] h-[40px] rounded-md hover:bg-white hover:text-[#5f0c27]"
              type="button"
              data-modal-toggle="popup-modal"
            >
              {studentIsActive ? "Deactivate User" : "Activate User"}
            </button>
          </div>

          {/* Modal Starts Here */}
          {showModal ? (
            <div
              id="popup-modal"
              tabIndex="-1"
              className="backdrop-blur-none bg-gray-400/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0  z-50 w-full md:inset-0 h-modal md:h-full h-full "
            >
              <div className="flex items-center justify-center mt-12 w-full max-w-sm md:max-w-[50%]  m-auto">
                {/* Modal content */}
                <div className="relative  bg-white rounded-lg shadow dark:bg-gray-700">
                  <button
                    onClick={toggle}
                    type="button"
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="popup-modal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="p-6 text-center">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-900 dark:text-gray-900">{`Are you sure you want to ${
                      studentIsActive ? "deactivate" : "activate"
                    } this Student?`}</h3>
                    <button
                      onClick={toggle}
                      data-modal-toggle="defaultModal1"
                      type="button"
                      className="text-white md:mt-[-20px] ml-[20px] bg-[#B61046] md:w-[150px] h-[40px] rounded-md hover:bg-white px-2 hover:text-[#5f0c27]"
                    >
                      No, Cancel
                    </button>
                    <button
                      onClick={() => ToggleStudent(studentId)}
                      data-modal-toggle="defaultModal1"
                      type="button"
                      className="ml-1 md:ml-3 text-[#5f0c27] border border-[#B61046] md:mt-[-20px] mt-[2px] bg-white px-2  md:w-[150px] h-[40px] rounded-md hover:bg-[#B61046] hover:text-white"
                    >
                      Yes, I am sure{" "}
                      <Spinner
                        className="!w-3  !h-3 ml-[120px] mt-[-15px] text-white fill-pink-400"
                        status={status}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {/* Modal Ends Here */}

          <div className="flex justify-between mt-[-60px]">
            <CardDbStudent />
          </div>
          <div className="grid grid-rows-1 lg:grid-cols-2 gap-24">
            <Loading status={transactionByStudentStatus} />
            <Error
              retry={getList}
              err={transactionByStudentErr}
              status={transactionByStudentStatus}
            />

            <Success status={transactionByStudentStatus}>
              <CardStudent data={transactionByStudent} />
            </Success>
            <Success status={transactionByStudent}>
              <div className="text-center mt-[-10px] md:mb-16">
                <Pagination
                  prev={() => getList(false, clamp(page - 1, 1, totalPages))}
                  next={() => getList(false, clamp(page + 1, 1, totalPages))}
                  goTo={(page) => getList(false, page)}
                  currentPage={page}
                  totalPages={totalPages}
                  isFirst={page <= 1}
                  isLast={page >= totalPages}
                />
              </div>
            </Success>

            <Loading status={reportListStatus} />
            <Error
              retry={getReport}
              err={reportListErr}
              status={reportListStatus}
            />

            <div className="">
              <Success status={studentPercentListStatus}>
                <StudyPercentageTable data={studentPercentList} />
              </Success>
              <Success status={reportListStatus}>
                <CardStudent2 data={reportList} />
              </Success>
              <Success status={reportList}>
                <div className="text-center mt-[-10px] md:mb-16">
                  <Pagination
                    prev={() => getList(false, clamp(page - 1, 1, totalPages))}
                    next={() => getList(false, clamp(page + 1, 1, totalPages))}
                    goTo={(page) => getList(false, page)}
                    currentPage={page}
                    totalPages={totalPages}
                    isFirst={page <= 1}
                    isLast={page >= totalPages}
                  />
                </div>
              </Success>
            </div>
          </div>
        </div>
        <AppFooter />
      </div>
    </PrivateRoute>
  );
};

export { StudentDetailPage };
