import React, { useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {NavBar,FlaggedTable, AppFooter,Loading,Error,Success,Pagination, PrivateRoute} from "../../components";
import {selectStudent,getFlaggedList} from "../../redux/slices";
import clamp from "lodash/clamp";

const FlaggedPage = () => {

    const dispatch = useDispatch();
    const { page,totalPages,flaggedList, flaggedListErr, flaggedListStatus } = useSelector(selectStudent);
  
    useEffect(() => {
        dispatch(getFlaggedList());
    }, [dispatch]);
  
    const getList = useCallback(
        (force = true, page) => {
          dispatch(getFlaggedList({ force, page }));
        },
        [dispatch]
    );

    return (
        <PrivateRoute redirectTo="/flagged">
        <div className="min-h-fit overflow-hidden">
            <NavBar/>
            <section >
                <Loading status={flaggedListStatus}/>
                <Error retry={getList} err={flaggedListErr} status={flaggedListStatus} />
                <Success status={flaggedListStatus}>
                <FlaggedTable data={flaggedList}/> 
                </Success>
                <Success status={flaggedListStatus}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() =>
                  getList(
                    false,
                    clamp(page - 1, 1, totalPages)
                  )
                }
                next={() =>
                  getList(
                    false,
                    clamp(page + 1, 1, totalPages)
                  )
                }
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
            </section>
            <AppFooter/>
        </div>
        </PrivateRoute>
    )
}

export {FlaggedPage}
