import React, { useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {NavBar,AwaitingApprovalTable, AppFooter,Loading,Error,Success,Pagination,PrivateRoute} from "../../components";
import {selectCourse,getApprovedCourseList} from "../../redux/slices";
import clamp from "lodash/clamp";

const AwaitingApprovalPage = () => {

    
    const dispatch = useDispatch();
    const { page,totalPages,approvedCourse, approvedCourseErr, approvedCourseStatus } = useSelector(selectCourse);
  
    useEffect(() => {
        dispatch(getApprovedCourseList());
    }, [dispatch]);
  
    const getList = useCallback(
        (force = true, page) => {
          dispatch(getApprovedCourseList({ force, page }));
        },
        [dispatch]
    );
    return (
      <PrivateRoute redirectTo="/pendingCourses">
        <div className="min-h-fit overflow-hidden">
            <NavBar/>
            <section >
                <Loading status={approvedCourseStatus}/>
                <Error retry={getList} err={approvedCourseErr} status={approvedCourseStatus} />
                <Success status={approvedCourseStatus}>
                <AwaitingApprovalTable data={approvedCourse}/> 
                </Success>
                <Success status={approvedCourseStatus}>
            <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() =>
                  getList(
                    false,
                    clamp(page - 1, 1, totalPages)
                  )
                }
                next={() =>
                  getList(
                    false,
                    clamp(page + 1, 1, totalPages)
                  )
                }
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
            </section>
            <AppFooter/> 
        </div>
        </PrivateRoute>
    )
}

export {AwaitingApprovalPage}
