import React, { useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {NavBar,AwaitingInstructorsTable, AppFooter,Loading,Error,Success,Pagination,PrivateRoute} from "../../components";
import {getApprovedList, selectInstructor} from "../../redux/slices";
import clamp from "lodash/clamp";

const AwaitingInstructorsPage = () => {

  const dispatch = useDispatch();
  const { page,totalPages,approvedList, approvedListErr, approvedListStatus } = useSelector(selectInstructor);

  useEffect(() => {
      dispatch(getApprovedList());
  }, [dispatch]);

  const getList = useCallback(
      (force = true, page) => {
        dispatch(getApprovedList({ force, page }));
      },
      [dispatch]
  );
  
    return (
      <PrivateRoute redirectTo="/pendingInstructors">
        <div className="min-h-fit overflow-hidden ">
            <NavBar/>
            <section >
            <Loading status={approvedListStatus}/>
                <Error retry={getList} err={approvedListErr} status={approvedListStatus} />
                <Success status={approvedListStatus}>
                <AwaitingInstructorsTable data={approvedList}/> 
                </Success>
                <Success status={approvedListStatus}>           
                <div className="text-center mt-[-10px] md:mb-16">
              <Pagination
                prev={() =>
                  getList(
                    false,
                    clamp(page - 1, 1, totalPages)
                  )
                }
                next={() =>
                  getList(
                    false,
                    clamp(page + 1, 1, totalPages)
                  )
                }
                goTo={(page) => getList(false, page)}
                currentPage={page}
                totalPages={totalPages}
                isFirst={page <= 1}
                isLast={page >= totalPages}
              />
            </div>
          </Success>
            </section>
            <AppFooter/>
        </div>
        </PrivateRoute>
    )
}

export {AwaitingInstructorsPage}
